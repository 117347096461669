import React, { Component } from "react";
import Select from 'react-select';
import DatePicker from 'react-date-picker';
import { Card } from 'components/Card/Card.jsx';
import { returnLabelWithValue, hasRole } from 'helper/helperFunctions';
import PhoneNumber from "components/ThemeComponents/phoneNumber";
var moment = require("moment");

class AdvanceSearchForm extends Component {
	dateElement = (name, value, rules, type, dateType) => {
		var selectedDate = this.props.advanceSearch[name].value !== '' ? new Date(this.props.advanceSearch[name].value) : '';
		if (!dateType)
			dateType = "date";
		var propTemp = {};

		if (dateType === 'year')
			propTemp = { maxDetail: "decade", yearPlaceholder: "Y" };
		else if (dateType === 'monthYear')
			propTemp = { maxDetail: "year", monthPlaceholder: "M", yearPlaceholder: "Y" };
		else
			propTemp = { dayPlaceholder: "D", monthPlaceholder: "M", yearPlaceholder: "Y" };

		if (!type)
			type = "text";

		return (

			<DatePicker
				className="form-control"
				dateFormat={hasRole(['gm admin']) ? 'dd/MM/yyyy' : 'MM/dd/yyyy'}
				value={dateType === this.props.advanceSearch[name]['type'] ? selectedDate : ''}
				name={name}
				onChange={(event) => this.props.onChangsAdvanceSearchDate(event, name, dateType)}
				clearIcon={null}
				calendarIcon={null}
				{...propTemp}

			/>
		);
	}

	enterKey = (event) => {
		if (event.keyCode === 13) {
			this.props.performSearch();
		}
	}

	render() {
		const { isOpen, advanceSearch, options } = this.props;
		return (
			<Card bsClass={["innerCard relativeCard"]} collapsHandler={() => this.props.collapseHandle('isOpenAdvanceSearch')} collapseTrigger={isOpen} collapsable={true} title="Additional Search" content={
				<React.Fragment>
					<div className="clearfix mt-md fullCalendar">
						<div className="col-md-3">
							<div className='form-group'>
								<label htmlFor="gname">Barcode</label>
								<input onKeyDown={(e) => this.enterKey(e)} type="text" autoFocus name="barcode" className="form-control" onChange={this.props.handleAdvanceSearch} value={advanceSearch.barcode} />
							</div>
							<div className="form-group">
								<label htmlFor="glemail">Select Agency</label>
								<Select
									value={returnLabelWithValue(advanceSearch.agency_id, options.agencies)}
									onChange={this.props.handleChangeAdvanceSearchDropDown}
									options={options.agencies}
									isSearchable={true}
									isMulti={false}
									name='agency_id'
									placeholder={'Select Agency'}
									isClearable={true}
								/>
							</div>
							<div className='form-group'>
								<label htmlFor="gname">Destination</label>
								<input onKeyDown={(e) => this.enterKey(e)} type="email" name="destination" className="form-control" onChange={this.props.handleAdvanceSearch} value={advanceSearch.destination} />
							</div>
							<div className='form-group'>
								<label htmlFor="gname">Username/Password</label>
								<input onKeyDown={(e) => this.enterKey(e)} type="text" name="username_password" className="form-control" onChange={this.props.handleAdvanceSearch} value={advanceSearch.username_password} />
							</div>
                            <div className="form-group">
								<label htmlFor="glemail">Select Brand</label>
								<Select
									value={advanceSearch.account_id.length > 0 ? advanceSearch.account_id.map(item => item) : ''}
									onChange={this.props.handleChangeBrand}
									options={options.brands}
									isSearchable={true}
									isMulti={true}
									name='account_id'
									placeholder={'Select Brand'}
									isClearable={true}
								/>
							</div>
						</div>
						<div className="col-md-3">
							<div className='form-group'>
								<label htmlFor="gname">Group or Contact Name</label>
								<input onKeyDown={(e) => this.enterKey(e)} type="text" name="contact_name" className="form-control" onChange={this.props.handleAdvanceSearch} value={advanceSearch.contact_name} />
							</div>
							<div className='form-group'>
								<label htmlFor="gname">Group Contact Email</label>
								<input onKeyDown={(e) => this.enterKey(e)} type="email" name="contact_email" className="form-control" onChange={this.props.handleAdvanceSearch} value={advanceSearch.contact_email} />
							</div>
							<div className='form-group'>
								<label htmlFor="gname">Group Contact Phone</label>
								<PhoneNumber
									value={advanceSearch.contact_phone}
									handleParent={this.props.handleAdvanceSearch}
									// validationName={'contact_phone'}
									// validator= {this.validatorAgencyUser}
									required={false}
									name={'contact_phone'}
								/>
							</div>
							<div className='form-group'>
								<label htmlFor="gname">Group Contact Address</label>
								<input onKeyDown={(e) => this.enterKey(e)} type="email" name="address" className="form-control" onChange={this.props.handleAdvanceSearch} value={advanceSearch.address} />
							</div>
						</div>
						<div className="col-md-3">
							<div id="departure_date" className="form-group">
								<label>Departure Date</label>

								<div className="flexElem datesContainer">
									<div className='col-md-3'>
										{
											this.dateElement('departure_date', advanceSearch.departure_date.value !== '' && moment(advanceSearch.departure_date.value, 'YYYY'), [], 'date', 'year')
										}
									</div>
									<div className='col-md-4'>
										{
											this.dateElement('departure_date', advanceSearch.departure_date.value !== '' && moment(advanceSearch.departure_date.value, 'YYYY-MM'), [], 'date', 'monthYear')
										}
									</div>

									<div className='col-md-5'>
										{
											this.dateElement('departure_date', advanceSearch.departure_date.value !== '' && moment(advanceSearch.departure_date.value, 'YYYY-MM-DD'), [], 'date', 'date')
										}
									</div>
								</div>
							</div>

							<div className="form-group" id="return_date">
								<label>Return Date</label>
								<div className="flexElem datesContainer">
									<div className='col-md-3'>
										{
											this.dateElement('return_date', advanceSearch.return_date.value !== '' && moment(advanceSearch.return_date.value, 'YYYY'), [], 'date', 'year')
										}
									</div>
									<div className='col-md-4'>
										{
											this.dateElement('return_date', advanceSearch.return_date.value !== '' && moment(advanceSearch.return_date.value, 'YYYY-MM'), [], 'date', 'monthYear')
										}
									</div>

									<div className='col-md-5'>
										{
											this.dateElement('return_date', advanceSearch.return_date.value !== '' && moment(advanceSearch.return_date.value, 'YYYY-MM-DD'), [], 'date', 'date')
										}
									</div>
								</div>
							</div>

							<div className="form-group">
								<label htmlFor="glemail">Program Type</label>
								<Select
									value={returnLabelWithValue(advanceSearch.program_type, options.mainProgramType)}
									onChange={this.props.handleChangeAdvanceSearchDropDown}
									options={options.mainProgramType}
									isSearchable={true}
									isMulti={false}
									name='program_type'
									placeholder={'Select Program Type'}
									isClearable={true}
								/>
							</div>

							<div className="form-group">
								<label htmlFor="glemail">Country</label>
								<Select
									value={returnLabelWithValue(advanceSearch.country, options.countries)}
									onChange={this.props.handleChangeAdvanceSearchDropDown}
									options={options.countries}
									isSearchable={true}
									isMulti={false}
									name='country'
									placeholder={'Select Country'}
									isClearable={true}
								/>
							</div>

							<div className="form-group">
								<label htmlFor="glemail">State</label>
								<Select
									value={returnLabelWithValue(advanceSearch.state, options.states)}
									onChange={this.props.handleChangeAdvanceSearchDropDown}
									options={options.states}
									isSearchable={true}
									isMulti={false}
									name='state'
									placeholder={'Select State'}
									isClearable={true}
								/>
							</div>
						</div>

						<div className="col-md-3">
							<div className="form-group" id="pre_trip_package_need_date">
								<label>Pre Trip Package Need Date</label>
								<div className="flexElem datesContainer">
									<div className='col-md-3'>
										{
											this.dateElement('pre_trip_package_need_date', advanceSearch.pre_trip_package_need_date.value !== '' && moment(advanceSearch.pre_trip_package_need_date.value, 'YYYY'), [], 'date', 'year')
										}
									</div>
									<div className='col-md-4'>
										{
											this.dateElement('pre_trip_package_need_date', advanceSearch.pre_trip_package_need_date.value !== '' && moment(advanceSearch.pre_trip_package_need_date.value, 'YYYY-MM'), [], 'date', 'monthYear')
										}
									</div>

									<div className='col-md-5'>
										{
											this.dateElement('pre_trip_package_need_date', advanceSearch.pre_trip_package_need_date.value !== '' && moment(advanceSearch.pre_trip_package_need_date.value, 'YYYY-MM-DD'), [], 'date', 'date')
										}
									</div>
								</div>
							</div>

							<div className="form-group" id="video_special_need_date">
								<label>Video Special Need Date</label>
								<div className="flexElem datesContainer">
									<div className='col-md-3'>
										{
											this.dateElement('video_special_need_date', advanceSearch.video_special_need_date.value !== '' && moment(advanceSearch.video_special_need_date.value, 'YYYY'), [], 'date', 'year')
										}
									</div>
									<div className='col-md-4'>
										{
											this.dateElement('video_special_need_date', advanceSearch.video_special_need_date.value !== '' && moment(advanceSearch.video_special_need_date.value, 'YYYY-MM'), [], 'date', 'monthYear')
										}
									</div>

									<div className='col-md-5'>
										{
											this.dateElement('video_special_need_date', advanceSearch.video_special_need_date.value !== '' && moment(advanceSearch.video_special_need_date.value, 'YYYY-MM-DD'), [], 'date', 'date')
										}
									</div>
								</div>
							</div>
							<div className="form-group" id="after_or_equal_departure_date">
								<label>After Or Equal Departure Date</label>
								<div className="flexElem datesContainer">
									<div className='col-md-3'>
										{
											this.dateElement('after_or_equal_departure_date', advanceSearch.after_or_equal_departure_date.value !== '' && moment(advanceSearch.after_or_equal_departure_date.value, 'YYYY'), [], 'date', 'year')
										}
									</div>
									<div className='col-md-4'>
										{
											this.dateElement('after_or_equal_departure_date', advanceSearch.after_or_equal_departure_date.value !== '' && moment(advanceSearch.after_or_equal_departure_date.value, 'YYYY-MM'), [], 'date', 'monthYear')
										}
									</div>

									<div className='col-md-5'>
										{
											this.dateElement('after_or_equal_departure_date', advanceSearch.after_or_equal_departure_date.value !== '' && moment(advanceSearch.after_or_equal_departure_date.value, 'YYYY-MM-DD'), [], 'date', 'date')
										}
									</div>
								</div>
							</div>
							<div className="form-group" id="before_or_equal_departure_date">
								<label>Before Or Equal Departure Date</label>
								<div className="flexElem datesContainer">
									<div className='form-group col-md-3'>
										{
											this.dateElement('before_or_equal_departure_date', advanceSearch.before_or_equal_departure_date.value !== '' && moment(advanceSearch.before_or_equal_departure_date.value, 'YYYY'), [], 'date', 'year')
										}
									</div>
									<div className='form-group col-md-4'>
										{
											this.dateElement('before_or_equal_departure_date', advanceSearch.before_or_equal_departure_date.value !== '' && moment(advanceSearch.before_or_equal_departure_date.value, 'YYYY-MM'), [], 'date', 'monthYear')
										}
									</div>

									<div className='form-group col-md-5'>
										{
											this.dateElement('before_or_equal_departure_date', advanceSearch.before_or_equal_departure_date.value !== '' && moment(advanceSearch.before_or_equal_departure_date.value, 'YYYY-MM-DD'), [], 'date', 'date')
										}
									</div>
								</div>
							</div>
						</div>
						<div className="col-md-12 text-right">
							<button onClick={this.props.clearAdvanceSearchForm} className="backButton pt-sm no_radius pb-sm success btn btn-info ml-sm mt-sm"> Clear All </button>

							<button onClick={() => this.props.performSearch()} className="backButton pt-sm no_radius pb-sm primary btn btn-md btn-info ml-sm mt-sm">Perform Search</button>
						</div>
					</div>
				</React.Fragment>
			}
			/>

		);
	};
}

export default AdvanceSearchForm;

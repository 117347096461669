import React, { Component } from "react";
import { connect } from 'react-redux';
import { getOrdersWithStatus } from "redux/actions/order-actions";
import ReactValidator from "simple-react-validator";
import DatePicker from "react-datepicker";
import { Row, Col, Grid } from "react-bootstrap";
import { Card } from "components/Card/Card.jsx";
import 'react-table/react-table.css';
import ReactPlayer from 'react-player';
import { dateFormatMy, returnBoleanWithColumnMatch, BytesToMb, hasRole } from 'helper/helperFunctions';
import OrderProducedSummary from './OrderProducedSummary';
import Pagination from "../../ThemeComponents/Pagination";
const moment = window.moment;

class OrderFinishedPage extends Component {
	constructor(props) {
		super(props);
		this.dateValidator = new ReactValidator();
		this.state = {
			fromDate: '',
			to: '',
			pageSize: 10,
			page: 0,
			sorted: '',
		}
	}
	DateChangeHandler = (value, field) => {
		this.setState({ [field]: value === null ? '' : moment(value).format("YYYY-MM-DD") }, () => {
			if (this.dateValidator.allValid()) {
				const { fromDate, to, page, pageSize, sorted } = this.state;
				this.props.getOrdersWithStatusCall('finalize_by_qa', 1, fromDate, to, page, pageSize, sorted);
			} else {
				this.dateValidator.showMessages();
				this.forceUpdate();
			}
		})
	}
	dateElement(label, name, value, rules) {
		var selectedDate = this.state[name] !== '' ? new Date(this.state[name]) : '';
		const { fromDate, to } = this.state;
		return (
			<div className='form-group fullCalendar' id={name}>
				<label>{label}</label>
				<DatePicker className="form-control calender_icon" dateFormat={hasRole(['gm admin']) ? 'dd/MM/yyyy' : 'MM/dd/yyyy'} selected={selectedDate} name={name} onChange={(event) => this.DateChangeHandler(event, name)} />
				{
					(fromDate !== '' && to !== '') &&
					this.dateValidator.message(name, value, rules)
				}
			</div>
		);
	}
	orderWithStatusPaginationData = (page = this.state.page, sorted = this.state.sorted, pageSize = this.state.pageSize) => {
		const { fromDate, to } = this.state;
		this.props.getOrdersWithStatusCall('finalize_by_qa', 1, fromDate, to, page, pageSize, sorted);
	}

	paginationCall = (data) => {
		const { fromDate, to } = this.state;
		return this.props.getOrdersWithStatusCall('finalize_by_qa', 1, fromDate, to, data.page, data.pageSize, data.sort).then(
			res => {
				this.props.success(res);
				return res;
			}
		);
	}

	render() {
		const { to, fromDate } = this.state;
		// const { orderWithStatusData, OWSpages, loadingOrder } = this.props;
		const columns = [
			{
				Header: "Completed Orders Listing",
				headerClassName: 'headerPagination',
				columns: [
					{
						Header: "Barcode",
						accessor: "barcode",
                        width: 200,
						className: "action-center"
					},
					{
						Header: "Date Finished",
						accessor: "updated_at",
						Cell: ({ value }) => (
							<div>
								{dateFormatMy(value)}
							</div>
						),
						className: "action-center",
						sortable: false,
					},
					{
						Header: "Editor",
						accessor: "editor.name",
						className: "action-center",
						sortable: false,
					},
					{
						Header: "Produced By",
						accessor: "producer.name",
						className: "action-center",
						sortable: false,
					},
					{
						Header: "Quality Assurance",
						accessor: "qa.name",
						className: "action-center",
						sortable: false,
					},
					{
						Header: "Full Video",
						accessor: "order_resources",
						className: "action-center",
						Cell: ({ value }) => (
							<React.Fragment>
								{
									returnBoleanWithColumnMatch(value, 'resourceTypeSlug', 'full_video')
										?
										value.map(
											(prop, key) => {
												if (prop.resourceTypeSlug === 'full_video') {
													return (
														<React.Fragment key={key}>
															<ReactPlayer className='react-player' url={prop.file_path} controls={true} width={'200px'} height={'100px'} />
															<div>{BytesToMb(prop.file_size)} MB </div>
														</React.Fragment>
													);
												} else {
													return null;
												}
											}
										)
										:
										<span>Full video is not available</span>
								}
							</React.Fragment>
						),
						sortable: false,
					}
				]
			}
		];
		return (
			<div className="content">
				<Grid fluid>
					<Row>
						<Col md={12}>
							<Card
								ctTableResponsive
								content={
									<div>
										<Grid fluid>
											<div className="row">
												<Col md={4} >
													<div className='row flexElem flexResponsive align-items-center' >
														<div className="col-md-6" >
															{
																this.dateElement('Date From', 'fromDate', fromDate !== '' && moment(fromDate, 'YYYY-MM-DD'), [{ before_or_equal: moment(this.state.to) }])
															}
														</div>
													</div>
												</Col>
												<Col md={4} >
													<div className='row flexElem flexResponsive align-items-center' >
														<div className="col-md-6" >
															{
																this.dateElement('Date To', 'to', to !== '' && moment(to, 'YYYY-MM-DD'), [{ after_or_equal: moment(this.state.fromDate) }])
															}
														</div>

													</div>
												</Col>
												{/* <Col md={12} className="mt-md">
													<ReactTable
														noDataText='No orders report found'
														data={orderWithStatusData}
														pages={OWSpages}
														columns={columns}
														defaultPageSize={10}
														loading={loadingOrder}
														className="-striped listing"
														loadingText={'Loading...'}
														pageData={this.orderWithStatusPaginationData}
														manual
														onFetchData={(state, instance) => {

															var sort = state.sorted.length === 0 ? '' : state.sorted[0].id + ',desc:' + state.sorted[0].desc;
															state.pageData(state.page + 1, sort, state.pageSize);
														}}
													/>
												</Col> */}
												<Pagination
													ref={(p) => this.pagination = p}
													showPagination={false}
													columns={columns}
													pageSize={10}
													getDataCall={this.paginationCall}
													filterView={false}
													noDataText='No orders report found'
													// downloadData={true}
													// downloadFileName={'Orders'}
													// lowerContent = {null}
													// filterPlaceHolder = {'Orders'}
													getRowProps={this.getRowProps}
												// showAllToggle={true}
												/>
												<Col md={12} >
													<OrderProducedSummary />
												</Col>
											</div>
										</Grid>
									</div>
								} />
						</Col>
					</Row>
				</Grid>
			</div>
		);
	}
}

const mapDispatchToProps = dispatch => {
	return ({
		getOrdersWithStatusCall: (status, value, fromDate, toDate, page, pageSize, sorted) => { return dispatch(getOrdersWithStatus(status, value, fromDate, toDate, page, pageSize, sorted)) },//example function
		success: (response) => { return dispatch({ type: 'GET_ALL_ORDERS_WITH_STATUS_SUCCESS', payload: response }) }
	});
};

function mapStateToProps(state) {
	const { orderWithStatusData, OWSpages, OWScurrent_page, OWStotal_orders, loadingOrder, ordersCountWithModerator } = state.ordersReducer;
	return {
		orderWithStatusData, OWSpages, OWScurrent_page, OWStotal_orders, loadingOrder, ordersCountWithModerator
	};
}


const OrderFinished = connect(mapStateToProps, mapDispatchToProps)(OrderFinishedPage);
export default OrderFinished;

import React, { Component } from "react";
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { MdClose, MdAdd } from 'react-icons/md';
import Confirmalertfordelete from 'components/ThemeComponents/confirmAlertForDelete';
import { confirmAlert } from 'react-confirm-alert';
import $ from 'jquery'
import InputMask from 'react-input-mask';
import { toggleModal, openModal, returnKeyWithColumnMatch, returnLabelWithValue, getSum, closeModalAfterApi, hasRole } from 'helper/helperFunctions';
import { getAllAgencies, getAllAgenciesPublishable } from 'redux/actions/agency-actions';
import { getAgencyType, getSalesRep, getAgencySalesRep, getDestinations } from 'redux/actions/settings-actions';
import { connect } from 'react-redux';
import ThemeModal from 'components/ThemeComponents/ThemeModal';
import DestinationForm from "components/Admin/Destination/DestinationForm";
import { objectsConstants as OC } from 'redux/constant/objects-constant';
import PhoneNumber from "components/ThemeComponents/phoneNumber";
import AsyncCreatableSelect from 'react-select/async-creatable';
import { settingsService } from 'services/settings';
import { storeDestination } from 'redux/actions/destination-actions';
import { getMoreButtonsPagination } from '../../../redux/actions/more-buttons-action';
import CustomPhoneNumber from '../../ThemeComponents/CustomPhoneNumber';
import CustomCellPhoneNumber from '../../ThemeComponents/CustomCellPhoneNumber';
var cloneDeep = require('lodash.clonedeep');
const moment = window.moment;

const promiseOptions = (inputValue) =>
	new Promise((resolve) => {
		console.log('inputValue', inputValue);
		setTimeout(() => {
			resolve(settingsService.getDestinationsAsync({ inputValue: inputValue }));
		}, 1000);
	}
	);

class OrderDetailPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			order_details: this.props.order_details,
			newDestination: OC.DESTINATION,/*Modal for destination code*/
			addModalDestination: false,/*Modal for destination code*/
			onCloseAfterApi: false,/*Modal for destination code*/
			send_agency_sales_email: 0,
			agencyId: '',
            departure_date_is_greater: false,
		};
		this.handleChangeOrderDetail = this.handleChangeOrderDetail.bind(this);
		this.handleChangeGroupLeader = this.handleChangeGroupLeader.bind(this);
		this.handleChangeOrderDetailDropDown = this.handleChangeOrderDetailDropDown.bind(this);
		this.onChangeOrderDate = this.onChangeOrderDate.bind(this);
		// this.onChangeOrderDateTest = this.onChangeOrderDateTest.bind(this);
		this.returnOrderDetailData = this.returnOrderDetailData.bind(this);
		this.returnState = this.returnState.bind(this);
		this.props.getAgenciesCall();
		this.props.getAllAgenciesPublishableCall();
		this.props.getGroupTypeCall();
		this.props.getPVSalesRepCall();
		// this.props.getMoreButtonsPaginationCall();
		this.props.getDestinationsCall({ inputValue: null, defaultOptions: [] }).then(result => {
            console.log("response: " , result);
        });

		if (this.state.order_details.agency_id)
			this.props.getAgencySalesRepCall(this.state.order_details.agency_id);
		// this.props.getMoreButtonsPaginationCall(this.state.order_details.agency_id);

		/*Modal for destination code*/
		this.toggleModal = toggleModal.bind(this);
		this.openModal = openModal.bind(this);
		/*Modal for destination code*/
	}
	returnOrderDetailData() {
		return { ...this.state.order_details, send_agency_sales_email: this.state.send_agency_sales_email, departure_date_is_greater: this.state.departure_date_is_greater };
	}
	handleChangeOrderDetail(e) {
		const { name, value } = e.target;
		const orderDetailState = { ...this.state.order_details };
		orderDetailState[name] = value;
		this.setState({ order_details: orderDetailState });
	}
	handleChangeGroupLeader(e, key) {


		const { name, value } = e.target;

		const order_detailsTemp = cloneDeep(this.state.order_details);


		// const order_details = this.state.order_details;
		// const group_leader = { ...order_details.group_leader_contact }
		order_detailsTemp['group_leader_contacts'][key][name] = value;
		this.setState(
			{
				order_details: order_detailsTemp
			}
		);
	}
	handleChangeOrderDetailDropDown(selectedOptionType, info) {
        console.log("selectedOptionType: " , selectedOptionType);
		if (info['action'] === 'select-option') {
			const order_details = { ...this.state.order_details };
			// const agency_id = this.state.order_details.agency_id;
			if (info['name'] === 'agency_id') {
				this.setState({
					agencyId: selectedOptionType['value']
				})
				this.props.getAgencyCall(selectedOptionType['value']);
				this.props.getAgencySalesRepCall(selectedOptionType['value']);
				// this.props.getMoreButtonsPaginationCall('1', '', '', '', 'asc', selectedOptionType['value']);

			}
			const field = info['name'];
			this.setState(
				{
					order_details: {
						...order_details, [field]: selectedOptionType['value']
					}
				},
				() => {
					if (info['name'] === 'agency_id' && this.state.order_details.customer_slates_for_videos_id === null) {
						this.setState(
							{
								order_details: {
									...order_details, customer_slates_for_videos_id: selectedOptionType['value']
								}
							}
						);
					}
					if (field === 'agency_sales_rep_id') {
						this.props.returnOrderDetails(this.state.order_details);
					}
				}
			);
		}
	}

    showPopUp = () => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <Confirmalertfordelete typeName="Date" description="Return date listed can not be before departure date" onClosePro={onClose} />
                )
            }
        })
    }

	getPlainDateObject = (value) => {
		return moment.isMoment(value) ? value.toDate() : value
	}

	onChangeOrderDate(value, field) {
        console.log("value", value, field);
        let order_dates_clone = cloneDeep(this.state.order_details)
		var valueDate = moment(value).startOf('day');
		var tempDate = cloneDeep(this.state.order_details);
        // let departure_date = moment(new Date(order_dates_clone.order_dates.departure_date.value)).format('YYYY-MM-DD')
        // let return_date = moment(new Date(order_dates_clone.order_dates.return_date.value)).format('YYYY-MM-DD')
        // console.log("valueDate", valueDate);
        // console.log("return date", return_date);
        // console.log("departure_date", departure_date);
		tempDate.order_dates[field].value = value === null ? '' : valueDate;
		// // if (field === 'departure_date' && departure_date >= return_date) {
        //     // console.log("ssssssssssssssss",new Date(tempDate.order_dates['departure_date'].value));
		// if (field === 'departure_date' && (moment(new Date(valueDate)).format('YYYY-MM-DD') > return_date)) {
        //     console.log("truuuuuuuuu");
        //     this.showPopUp();
        //     this.setState({
        //         departure_date_is_greater: true
        //     })
		// 	// tempDate.order_dates['return_date'].value = value === null ? '' : valueDate;
		// } else if (field === 'return_date' && (departure_date < moment(new Date(valueDate)).format('YYYY-MM-DD'))) {
        //     console.log("falseeeeeee");
        //     this.setState({
        //         departure_date_is_greater: false
        //     })
        // } else if (departure_date < return_date){
        //     console.log("falseeeeeee");
        //     this.setState({
        //         departure_date_is_greater: false
        //     })
        // }
		this.setState(
			{
				order_details: tempDate
			}, () => {
				let order_dates_clone = cloneDeep(this.state.order_details)



                let check_departure_date = moment(new Date(order_dates_clone.order_dates.departure_date.value)).format('YYYY-MM-DD')
                let check_return_date = moment(new Date(order_dates_clone.order_dates.return_date.value)).format('YYYY-MM-DD')
                if (field === 'departure_date' && (check_departure_date > check_return_date)) {
                    console.log("truuuuuuuuu");
                    this.showPopUp();
                    this.setState({
                        departure_date_is_greater: true
                    })
                } else if (field === 'return_date' && (check_departure_date <= check_return_date)) {
                    console.log("falseeeeeee");
                    this.setState({
                        departure_date_is_greater: false
                    })
                } else if (check_departure_date <= check_return_date){
                    console.log("falseeeeeee");
                    this.setState({
                        departure_date_is_greater: false
                    })
                }


				let video_special_need_date = this.getPlainDateObject(order_dates_clone.order_dates.video_special_need_date.value)
				let return_date = this.getPlainDateObject(order_dates_clone.order_dates.return_date.value)
				if (field === 'return_date') {
					if (video_special_need_date && return_date >= video_special_need_date) {
						order_dates_clone.order_dates['video_special_need_date'].value = value === null ? '' : valueDate.add(1, 'days');
						this.setState(
							{
								order_details: order_dates_clone
							})
					}
				}
			}
		);
	}

	returnState() {
		const state = { ...this.state.order_details}
		this.props.returnState(state);
	}
	componentDidUpdate(prevProps, prevState) {
		if ((prevProps.order_details.agency_id !== this.props.order_details.agency_id) && this.props.order_details.agency_id) {
			this.props.getAgencySalesRepCall(this.props.order_details.agency_id);

		}
		if (prevProps.order_details !== this.props.order_details) {
			this.setState({
				order_details: this.props.order_details
			})
		}

		/*Modal for destination code*/
		if (!prevState.addModalDestination && this.state.addModalDestination) {
			this.setState({ onCloseAfterApi: false })
		}
		// if (prevState.addModalDestination && !this.state.addModalDestination && this.state.onCloseAfterApi) {
		// 	// promiseOptions(this.state.newDestination.title);
		// 	// this.props.getDestinationsCall();
		// }
		if (prevProps.storedDestination !== this.props.storedDestination) {
			this.setState(prevState => ({
				order_details: {
					...prevState.order_details,
					destinations: [...prevState.order_details.destinations, this.props.storedDestination.id]
				},
				newDestination: OC.newDestination
			}));
		}
        if (prevProps.is_france !== this.props.is_france) {
            this.phoneFormatChangeHandler();
        }
		/*Modal for destination code*/

		//default group type
		//   if (prevProps.agency_type_id !== this.props.agency_type_id) {
		//   	const { defaultGroupTypeByName, agency_type_id } = this.props;
		// if (defaultGroupTypeByName) {
		// 	this.setState({
		// 		order_details: {
		// 			...this.state.order_details,
		// 			agency_type_id: defaultGroupTypeByName?returnColumnValueWithColumnMatch(agency_type_id, 'value', [ OC.DEFAULTGROUPTYPE ], 'label'):null,

		// 		}
		// 	});
		// }
		//   }

	}

    phoneFormatChangeHandler = () => {
        var Val1 = $( "body" ).find("#france_1");
        setTimeout(() => {
            Val1.trigger("click");
        }, 500);
        var Val2 = $( "body" ).find("#france_2");
        setTimeout(() => {
            Val2.trigger("click");
        }, 500);
    }

	componentDidMount() {
		this.props.provideCtrlOPF({
			returnOrderDetailData: () => this.returnOrderDetailData()
		});
        this.phoneFormatChangeHandler();
	}
	componentWillUnmount() {
		this.props.provideCtrlOPF(null);
	}
	handleChangeDestination = (selectedOption, name) => {
        console.log("selectedOption", selectedOption);
        console.log("name", name);
        /*Modal for destination code*/
		if (name.action === 'create-option') {
            var lastSelectedOption = selectedOption.slice(-1).pop()
            // console.log("lastSelectedOption", lastSelectedOption);
			// var key = returnKeyWithColumnMatch('__isNew__', selectedOption, true)
            console.log("keyyy",lastSelectedOption['label']);
			this.props.storeDestinationCall({ title: lastSelectedOption['label'] });
		}
		/*Modal for destination code*/
        if (selectedOption && name.action !== "create-option" && name.action !== "remove-value") {
            this.setState(prevState => ({
				order_details: {
					...prevState.order_details,
					// destinations: selectedOption.map(item => item.value)
					destinations: [...prevState.order_details.destinations, name.option.value],
			}
        }));
        }
        if (name.action === 'remove-value') {
            	const destinationsTemp = this.state.order_details.destinations;
            	destinationsTemp.splice(destinationsTemp.indexOf(name.removedValue.value), 1);
                console.log("after remove-value",destinationsTemp.length);
            	this.setState(prevState => ({
            		order_details: {
            			...prevState.order_details,
            			destinations: destinationsTemp
            		}
            	}))
        }
        if (name.action === 'clear') {
            this.setState(prevState => ({
				order_details: {
					...prevState.order_details,
					destinations: []
				}
			}));
        }
		// if (name.action === 'select-option') {
		// 	this.setState(prevState => ({
		// 		order_details: {
		// 			...prevState.order_details,
		// 			destinations: [...prevState.order_details.destinations, name.option.value]
		// 		}
		// 	}),() => {

        //         console.log("after select-option and set state value of destination",this.state.order_details.destinations);
        //         console.log("after select-option",this.state.order_details.destinations.length);
        //     });
		// }
		// if (name.action === 'remove-value') {
		// 	const destinationsTemp = this.state.order_details.destinations;
		// 	destinationsTemp.splice(destinationsTemp.indexOf(name.removedValue.value), 1);
        //     console.log("after remove-value",destinationsTemp.length);
		// 	this.setState(prevState => ({
		// 		order_details: {
		// 			...prevState.order_details,
		// 			destinations: destinationsTemp
		// 		}
		// 	}),() => {

        //         console.log("after remove-value and set state",this.state.order_details.destinations.length);
        //         console.log("after remove-value and set state value of destination",this.state.order_details.destinations);
        //     })
		// }
		/*Modal for destination code*/
		// if (name.action === 'create-option') {
		// 	var key = returnKeyWithColumnMatch('__isNew__', selectedOption, true)
		// 	this.props.storeDestinationCall({ title: selectedOption[key]['label'] });

			// this.setState(prevState => ({
			// 	newDestination: {
			// 		...prevState.newDestination,
			// 		title: selectedOption[key]['label']
			// 	}
			// }), () => {
			// 	this.openModal('addModalDestination');
			// })
		// }
		/*Modal for destination code*/
	}

	getMinDate = (field) => {
		let departure_date = this.state.order_details.order_dates.departure_date.value
		let return_date = cloneDeep(this.state.order_details.order_dates.return_date.value)
		if (departure_date && return_date) {
			switch (field) {
				case 'return_date':
					let new_return_date = new Date(departure_date);
					return this.getPlainDateObject(new_return_date)
					break;
				case 'video_special_need_date':
					let new_date = new Date(return_date);
					return moment.isMoment(new_date) ? moment(new_date).add(1, 'days').toDate() : new_date.setDate(new_date.getDate() + 1)
					break;
				default:
					return null;
			}
		} else if (departure_date){
            switch (field) {
                case 'return_date':
                    let new_return_date = new Date(departure_date);
					return this.getPlainDateObject(new_return_date)
					break;
                default:
					return null;
            }
        }
	}

	dateElement(label, name, value, rules, type = 'text') {

		//console.log('selectedDate before', this.state.order_details.order_dates[name]['value']);
		var selectedDate = this.state.order_details.order_dates[name]['value'] !== '' ? new Date(this.state.order_details.order_dates[name]['value']) : '';
		//console.log('selectedDate', selectedDate);
		return (
			<div className='form-group fullCalendar' id={name}>
				<label>{label}</label>
				<DatePicker
					className="form-control calender_icon"
					selected={selectedDate}
					dateFormat={hasRole(['gm admin']) ? 'dd/MM/yyyy' : 'MM/dd/yyyy'}
					name={name}
					onChange={(event) => this.onChangeOrderDate(event, name)}
					minDate={this.getMinDate(name)}
					maxDate={name === 'pre_trip_package_need_date' ? this.getPlainDateObject(this.state.order_details.order_dates['departure_date'].value) : null}
					autoComplete="off"
				/>
				{
					value &&
					this.props.validatorInstance.message(name, value, rules)
				}
			</div>
		)
	}
	addNewGL = () => {
		const order_detailsTemp = cloneDeep(this.state.order_details);
		order_detailsTemp.group_leader_contacts.push(
			{
				name: '',
				phone1: '',
				phone1_ext: '',
				phone2: '',
				phone2_ext: '',
				email: '',
				type: 'Secondary'
			}
		);
		this.setState(
			{
				order_details: order_detailsTemp
			}
		);
	}

	removeLastGL = () => {
		const order_detailsTemp = cloneDeep(this.state.order_details);
		order_detailsTemp.group_leader_contacts.pop();
		this.setState(
			{
				order_details: order_detailsTemp
			}
		);
	}



	render() {
		const { order_details, newDestination, send_agency_sales_email } = this.state;
		const { validatorInstance, agenciesOption, publishableAgenciesOption, salesRep, agencySalesRep, formType, destinations, columns, agency,
            vsa_initial_country_France,
            ptma_initial_country_France,
            ptma_is_france,
			// agency_type_id,
            vsa_is_france, is_ttk } = this.props;

		validatorInstance.purgeFields();
		let agencyLabel = '';
		agenciesOption.forEach(item => {
			if (item.value === order_details.agency_id) {
				agencyLabel = item.label;
			}
		});
		const labelsToCheck = [
			"Combination (Student & Senior)",
			"Student Travel",
			"Senior Adult"
		];
		const agency_type_id = this.props.agency_type_id.filter(item => {
			if (is_ttk) {
				return labelsToCheck.includes(item.label);
			} else {
				return item;
			}
        });
		return (
			<div>
				<div className="clearfix">
					<span></span>
					{
						'frontEnd' in this.props
							?
							<div className="col-md-3" id="agency">
								<label>Select Agency<span className="requiredClass">*</span></label>
								<input className="form-control" type='text' disabled readOnly value={agencyLabel} />
							</div>
							:
							formType !== 'edit'
								?
								<div className="col-md-3" id="agency">
									<label htmlFor="glemail">Select Agency<span className="requiredClass">*</span></label>
									<Select
										value={returnLabelWithValue(order_details.agency_id, agenciesOption) || null}
										onChange={this.handleChangeOrderDetailDropDown}
										options={agenciesOption}
										isSearchable={true}
										isMulti={false}
										name='agency_id'
										placeholder={'Select Agency'}
									/>
									{
										validatorInstance.message('agency', order_details.agency_id, 'required')
									}
								</div>
								:
								null
					}
					<div className="col-md-3" id="agencySalesRep">
						<label htmlFor="glemail">Select Agency Sales Rep<span className="requiredClass">*</span></label>
						<Select
							value={returnLabelWithValue(order_details.agency_sales_rep_id, agencySalesRep) || null}
							onChange={this.handleChangeOrderDetailDropDown}
							options={agencySalesRep}
							isSearchable={true}
							isMulti={false}
							name='agency_sales_rep_id'
							placeholder={'Select Agency Sales Rep'}
						/>
						{
							validatorInstance.message('agencySalesRep', order_details.agency_sales_rep_id, 'required|integer')
						}
					</div>
					<div className="col-md-3" id="gtvSalesRep">
						<label htmlFor="glemail">Select GTV Sales Rep<span className="requiredClass">*</span></label>
						<Select
							value={returnLabelWithValue(order_details.gtv_sales_rep_id, salesRep) || null}
							onChange={this.handleChangeOrderDetailDropDown}
							options={salesRep}
							isSearchable={true}
							isMulti={false}
							name='gtv_sales_rep_id'
							placeholder={'Select GTV Sales Rep'}
						/>
						{
							validatorInstance.message('gtvSalesRep', order_details.gtv_sales_rep_id, 'required|integer')
						}
					</div>
					<div className="col-md-3" id="ClientTourCode">
						<div className='form-group'>
							<label htmlFor="clienttourcode">Client Tour Code</label>
							<input type="text" name="client_tour_code" className="form-control" onChange={this.handleChangeOrderDetail} value={order_details.client_tour_code || 0} />
							{/* {
								validatorInstance.message('ClientTourCode', order_details.client_tour_code, 'required')
							} */}
						</div>
					</div>
					<div className="col-md-3" id="agency">
						<label htmlFor="glemail">Select Secondary Agency for Slates<span className="requiredClass">*</span></label>
						<Select
							value={returnLabelWithValue(order_details.customer_slates_for_videos_id, agenciesOption) || null}
							onChange={this.handleChangeOrderDetailDropDown}
							options={agenciesOption}
							isSearchable={true}
							isMulti={false}
							name='customer_slates_for_videos_id'
							placeholder={'Select Secondary Agency for Slates'}
						/>
						{
							/*validatorInstance.message('customerSlatesForVideos', order_details.customer_slates_for_videos_id, 'required')*/
						}
					</div>
					{
						formType === 'add' &&
						<div className="col-md-4">
							<label className="container-check mt-xlg">
								<span style={{ marginTop: '3px' }} className='display_ib'>Send Agency Sales Rep Confirmation Email</span>
								<input
									type="checkbox"
									name="send_agency_sales_email"
									className="checkbox"
									onChange={(e) => {
										this.setState({
											send_agency_sales_email: e.target.checked
										})
									}}
									value={send_agency_sales_email}
									checked={send_agency_sales_email} />
								<span className="checkmark"></span>
							</label>
						</div>
					}

					{
						/*
						<div className="col-md-3" id="ClientTourNumber">
							<div className='form-group'>
								<label htmlFor="glemail">Client Tour Number</label>
								<input type="text" name="client_tour_number" className="form-control" onChange={this.handleChangeOrderDetail} value={order_details.client_tour_number || 0} />
								{
									validatorInstance.message('ClientTourNumber', order_details.client_tour_number, 'required')
								}
							</div>
						</div>

						*/
					}


					<span></span>
				</div>
				<div className="clearfix">
					{
						// this.state.agencyId ?
						// <div className="row">
						// 	<OrderMoreButtonsList
						// 		columns={columns}
						// 		agency_id={this.state.agencyId}
						// 	/>
						// </div>
						// : ''
					}
				</div>
				<hr style={{ 'borderTop': '1px solid #ccc' }} />
				<div className="clearfix">
					<span></span>
					<div className="col-md-3" id="AgencyType">
						<label htmlFor="glemail">Select Group Type<span className="requiredClass">*</span></label>
						<Select
							value={returnLabelWithValue(order_details.agency_type_id, agency_type_id) || null}
							onChange={this.handleChangeOrderDetailDropDown}
							options={agency_type_id}
							isSearchable={true}
							isMulti={false}
							name='agency_type_id'
							placeholder={'Select Group'}
                            // isDisabled={is_ttk ? true : false}

						/>
						{
							validatorInstance.message('AgencyType', order_details.agency_type_id, 'required')
						}
					</div>
					<div className="col-md-3" id="desitnation">
						<label htmlFor="glemail">Select Destination<span className="requiredClass">*</span></label>
						{
							/*
						<CreatableSelect
							isMulti={true}
							value={returnLabelsWithValues(order_details.destinations, destinations)}
							options={destinations}
							onChange={this.handleChangeDestination}
							className="selectUser"
							placeholder="Select Destination"

						/>

						*/
						}



						<AsyncCreatableSelect
							isMulti={true}
							// cacheOptions
							defaultOptions
							defaultValue={order_details.destinationDefault}
							// defaultInputValue
							loadOptions={promiseOptions}
							onChange={this.handleChangeDestination}
							className="selectUser"
							placeholder="Select Destination"
						/>




						{
							validatorInstance.message('desitnation', order_details.destinations, 'required|min:1,array')
						}
					</div>
					<div className="col-md-3" id="GroupName">
						<div className='form-group'>
							<label htmlFor="gname">Group Name<span className="requiredClass">*</span></label>
							<input type="text" name="group_name" className="form-control" onChange={this.handleChangeOrderDetail} value={order_details.group_name || ''} />
							{
								validatorInstance.message('GroupName', order_details.group_name, 'required')
							}
						</div>
					</div>



					<span></span>
				</div>
				{
					Object.keys(order_details.group_leader_contacts).map((value, key) => {
						return (
							<div className="clearfix" key={key}>
								<span></span>
								<div className="col-md-3" id={"GroupLeaderName_" + getSum(key, 1)}>
									<div className='form-group'>
										<label htmlFor="gname">
											Group Leader Name
											<span className="requiredClass">*</span>
										</label>
										<input type="text" name="name" className="form-control" onChange={(e) => this.handleChangeGroupLeader(e, key)} value={order_details.group_leader_contacts[key].name || ''} />
										{
											validatorInstance.message('GroupLeaderName_' + getSum(key, 1), order_details.group_leader_contacts[key].name, 'required')
										}
									</div>
								</div>

								<div className="col-md-3" id={"GroupLeaderEmail_" + getSum(key, 1)}>
									<div className='form-group'>
										<label htmlFor="glemail">Group Leader Email<span className="requiredClass">*</span></label>
										<input type="text" name="email" className="form-control email_icon" onChange={(e) => this.handleChangeGroupLeader(e, key)} value={order_details.group_leader_contacts[key].email || ''} />
										{
											validatorInstance.message('GroupLeaderEmail_' + getSum(key, 1), order_details.group_leader_contacts[key].email, 'required|email')
										}
									</div>
								</div>

								<div className="col-md-3" id={"GroupLeaderPhone_" + getSum(key, 1)}>
									<div className='form-group'>
										<label htmlFor="glphone1">Group Leader Phone<span className="requiredClass">*</span></label>

                                        {

                                            <CustomPhoneNumber
                                                value={order_details.group_leader_contacts[key].phone1}
                                                handleParent={(e) => this.handleChangeGroupLeader(e, key)}
                                                validationName={'GroupLeaderPhone_' + getSum(key, 1)}
                                                validator={validatorInstance}
                                                required={true}
                                                elemId="france_1"
                                                // id="france_1"
                                                name={'phone1'}
                                                vsa_initial_country_France={vsa_initial_country_France}
                                                ptma_initial_country_France={ptma_initial_country_France}
                                                ptma_is_france={ptma_is_france}
                                                vsa_is_france={vsa_is_france}
                                            />
                                            // agency && agency.primary_address && agency.primary_address.country && agency.primary_address.country.code === 'FR' ?
                                            // is_france ?

                                            // <FrancePhoneNumber
                                            //     value={order_details.group_leader_contacts[key].phone1}
                                            //     handleParent={(e) => this.handleChangeGroupLeader(e, key)}
                                            //     validationName={'GroupLeaderPhone_' + getSum(key, 1)}
                                            //     validator={validatorInstance}
                                            //     required={true}
                                            //     name={'phone1'}
                                            // />

                                            // :

                                            // <PhoneNumber
                                            //     value={order_details.group_leader_contacts[key].phone1}
                                            //     handleParent={(e) => this.handleChangeGroupLeader(e, key)}
                                            //     validationName={'GroupLeaderPhone_' + getSum(key, 1)}
                                            //     validator={validatorInstance}
                                            //     required={true}
                                            //     name={'phone1'}
                                            // />
                                        }

									</div>
								</div>

								<div className="col-md-3" id={"GroupLeaderCell_" + getSum(key, 1)}>
									<div className='form-group'>
										<label htmlFor="glphone2">Group Leader Cell</label>
                                    {

                                        <CustomCellPhoneNumber
                                            value={order_details.group_leader_contacts[key].phone2 || ''}
                                            handleParent={(e) => this.handleChangeGroupLeader(e, key)}
                                            validationName={'GroupLeaderCell_' + getSum(key, 1)}
                                            validator={validatorInstance}
                                            required={false}
                                            elemId="france_2"
                                            // id="france_1"
                                            name={'phone2'}
                                            vsa_initial_country_France={vsa_initial_country_France}
                                            ptma_initial_country_France={ptma_initial_country_France}
                                            ptma_is_france={ptma_is_france}
                                            vsa_is_france={vsa_is_france}
                                        />
                                        //  agency && agency.primary_address && agency.primary_address.country && agency.primary_address.country.code === 'FR' ?
                                        // is_france ?
                                        //     <>
                                        // 	    <InputMask mask="99-99-99-99-99" maskChar={null} type="text" name="phone2" className="form-control phone_icon" placeholder="12-34-56-78-90" value={order_details.group_leader_contacts[key].phone2 || ''} onChange={(e) => this.handleChangeGroupLeader(e, key)} />
                                        //             {
                                        //                 validatorInstance.message('GroupLeaderCell_' + getSum(key, 1), order_details.group_leader_contacts[key].phone2, ['regex:^(([0-9]{2})|[0-9]{2})[-]?[\0-9]{2}[-]?[0-9]{2}[-]?[0-9]{2}$'])
                                        //             }
                                        //     </>
                                        // :
                                        // <>

                                        //     <InputMask mask="999-999-9999" maskChar={null} type="text" name="phone2" className="form-control phone_icon" placeholder="123-456-7890" value={order_details.group_leader_contacts[key].phone2 || ''} onChange={(e) => this.handleChangeGroupLeader(e, key)} />
                                        //         {
                                        //             validatorInstance.message('GroupLeaderCell_' + getSum(key, 1), order_details.group_leader_contacts[key].phone2, ['regex:^(([0-9]{3})|[0-9]{3})[-]?[\0-9]{3}[-]?[0-9]{4}$'])
                                        //         }
                                        //     </>
                                    }



									</div>
								</div>
								<span></span>
							</div>
						);
					})
				}

				<div className="clearfix">
					<span></span>
					<div className="col-md-4 text-center col-md-offset-4 mb-lg">
						{
							<React.Fragment>
								<button type="button" className="actionBtn m-sm primaryBtn" onClick={this.addNewGL}>
									<MdAdd />
									<span>Add New</span>
								</button>
								{
									Object.keys(order_details.group_leader_contacts).length > 1 &&
									<button type="button" className="actionBtn m-sm infoBtn" onClick={this.removeLastGL}>
										<MdClose />
										<span>Delete Last</span>
									</button>
								}
							</React.Fragment>
						}
					</div>
					<span></span>
				</div>

				<div className="clearfix">
					<span></span>
					<div className="col-md-6" >
						{
							this.dateElement('Departure Date', 'departure_date', order_details.order_dates.departure_date['value'] !== '' && moment(order_details.order_dates.departure_date['value'], 'YYYY-MM-DD'), ['required', 'date'], 'date')
						}
						{
							validatorInstance.message('departure_date', order_details.order_dates.departure_date.value, 'required')
						}
					</div>
					{
                        !is_ttk &&
                        <div className="col-md-6"  >
                            {
                                this.dateElement(
                                    'Pre Trip Materials Need Date',
                                    'pre_trip_package_need_date',
                                    order_details.order_dates.pre_trip_package_need_date['value'] !== '' && moment(order_details.order_dates.pre_trip_package_need_date['value'], 'YYYY-MM-DD'),
                                    [
                                        'date',
                                        {
                                            before_or_equal: moment(order_details.order_dates.departure_date['value'], 'YYYY-MM-DD')
                                        }
                                    ]
                                    , 'date')
                            }
                        </div>
                    }
					<div className="col-md-6" >
						{
							this.dateElement('Return Date', 'return_date', order_details.order_dates.return_date['value'] !== '' && moment(order_details.order_dates.return_date['value'], 'YYYY-MM-DD'), ['required', 'date', { after_or_equal: moment(order_details.order_dates.departure_date['value'], 'YYYY-MM-DD') }], 'date')
						}
						{
							validatorInstance.message('return_date', order_details.order_dates.return_date.value, 'required')
						}
					</div>

					{
                        !is_ttk &&
                        <div className="col-md-6" >
                            {
                                this.dateElement('Last Day Of School', 'last_day_of_school', order_details.order_dates.last_day_of_school['value'] !== '' && moment(order_details.order_dates.last_day_of_school['value'], 'YYYY-MM-DD'), ['date'], 'date')
                            }
                        </div>
                    }

					<div className="col-md-6"></div>
					{
                        !is_ttk &&
                        <div className="col-md-6" >
                            {
                                this.dateElement('Video Special Need Date', 'video_special_need_date', order_details.order_dates.video_special_need_date['value'] !== '' && moment(order_details.order_dates.video_special_need_date['value'], 'YYYY-MM-DD'), ['date', { after: moment(order_details.order_dates.return_date['value'], 'YYYY-MM-DD') }], 'date')
                            }
                        </div>
                    }


					<span></span>
				</div>

				{/*Add Destination Modal Start*/}
				<ThemeModal modalClass="" title='Add Destination' show={this.state.addModalDestination} hide={() => { this.toggleModal('addModalDestination') }} >
					<DestinationForm
						formTypePro="add"
						destinationPro={newDestination}
						closeModel={closeModalAfterApi.bind(this, "addModalDestination", "onCloseAfterApi")}
					/>
				</ThemeModal>
				{/*Add Destination Modal End*/}
			</div>
		);
	};
}


const mapDispatchToProps = dispatch => {
	return ({
		getAgenciesCall: () => { dispatch(getAllAgencies()) },
        getAllAgenciesPublishableCall: () => {
            dispatch(getAllAgenciesPublishable())
        },
		getGroupTypeCall: () => { dispatch(getAgencyType()) },
		getPVSalesRepCall: () => { dispatch(getSalesRep()) },
		getAgencySalesRepCall: (agency_id) => { dispatch(getAgencySalesRep(agency_id)) },
		getMoreButtonsPaginationCall: (page, filter, sort, pageSize, orderType, agency_id) => { return dispatch(getMoreButtonsPagination(page, filter, sort, pageSize, orderType, agency_id)) },
		getDestinationsCall: (filter) => { return dispatch(getDestinations(filter)) },
		storeDestinationCall: (destinationData) => { return dispatch(storeDestination(destinationData)) },

	});
};
function mapStateToProps(state) {
	const { loadingAgency, agenciesOption, publishableAgenciesOption } = state.agenciesReducer;
	const { loadingSettings, agency_type_id, salesRep, agencySalesRep, destinations } = state.settingsReducer;
	const { loadingDestination, storedDestination } = state.destinationsReducer;
	return {
		loadingAgency, agenciesOption, publishableAgenciesOption,
		loadingSettings, agency_type_id, salesRep, agencySalesRep, destinations,
		loadingDestination, storedDestination,
	};
}

const OrderDetail = connect(mapStateToProps, mapDispatchToProps)(OrderDetailPage);

export default OrderDetail;

import React, { Component } from 'react'
import { connect } from 'react-redux';
import $ from 'jquery';
import { Roles } from "constants/Roles";
import User from "helper/User";
import { Dashboard } from 'layouts/Frontend/Dashboard/Dashboard';
import { s3StaticPath } from 'helper/helperFunctions';
import { Link } from "react-router-dom";
import { Row, Col } from 'react-bootstrap';

// import MyImage from 'components/Upload/MyImage';

class UserHome extends Component {
  componentDidMount() {
    $(".faqList > .quest").click(function () {
      $(this).toggleClass("open");
      $(this).next(".ans").stop(true, true).slideToggle("fast");
    });

    $(window).resize(function () {
      setTimeout(function () {
        $("#how_it_works .banner").height($("#how_it_works .banner img").height());
      }, 300);
    });

    // var count = 10;
    $(window).scroll(function () {
      let a = $(this).scrollTop();
      a = a - (a / 5);
      $("#how_it_works .banner img").css({ position: 'relative', top: a + 'px' });
    });
  }
  render() {
	return (
		<main id="how_it_works">
			<section className="banner">
				<img alt="Banner header" src={s3StaticPath('img/works/wtk_photo_header.jpg')} />
			</section>

			<section >
				<div className="container mt-md">
					<div className="row">
						<div className="col-md-9">
							{
								(
								User.hasRole([Roles.PHOTO_ADMIN]) && !User.getProperty('cameraShipped') && User.getProperty('upload_approved') && Number(User.orderSettingValue('finalize_by_qa')) !== 1
								) &&
								<h5 className="home_heading userLogin_heading twenty22">Your gallery will be available after your Pre-Trip Materials are sent.</h5>
							}
							<h2 className="home_heading">Features &amp; Benefits</h2>
						</div>
						{
							User.hasRole([Roles.AGENCY_USER, Roles.AGENCY_USER_SC, Roles.AGENCY_USER_U]) &&
							<div className="col-md-3 text-right">
								<Link className="home_new_order_btn pt-sm pb-sm  hover_dark" to={'/new-order'} ><i style={{ verticalAlign: 'bottom' }} className="noLmargin flaticon-shopping-cart"></i>&nbsp; New Order</Link>
							</div>
						}
					</div>
				</div>
				<div className="container mt-md">
					<Row className="mb25 tabsContentUpdated">
						<Col xs={12} sm={4} className="mt10">
							<div className="border1 whiteBG" style={{ minHeight: '440px' }}>
								<img
									src={s3StaticPath('img/home/slicing/wtk/1.png')}
									alt="App Features 1"
									className="mw100Force"
								/>
								<div className="roundedOverlay_home">
									<img src={s3StaticPath('img/home/slicing/New-Free-Mobile-App.png')} alt="New-Free-Mobile-App" />
								</div>
								<div className="px1-25 py25 text-center" >
									<h6 className="secondary font-weight-bold lineH wtk_heading">
										Free Mobile App
									</h6>
									<span className="secondary mt25">
										Allows connection amongst the wedding couple, guests and the family &amp; friends back home with real time photo uploads.
									</span>
								</div>
							</div>
						</Col>
						<Col xs={12} sm={4} className="mt10">
							<div className="border1 whiteBG" style={{ minHeight: '440px' }}>
								<img
									src={s3StaticPath('img/gallery/wtk/photo_gallery.png')}
									alt="App Features 1"
									className="mw100Force"
								/>
								<div className="roundedOverlay_home">
									<img src={s3StaticPath('img/home/slicing/Video-Clips.png')} alt="Video-Clips" height={60} />
								</div>
								<div className="px1-25 py25 text-center">
									<h6 className="secondary font-weight-bold lineH wtk_heading">
										Private Photo Gallery
									</h6>
									<span className="secondary mt25">
										Your groups will take pictures and add them into their private shared gallery with the rest of your groups’ photos.
									</span>
								</div>
							</div>
						</Col>
						<Col xs={12} sm={4} className="mt10">
							<div className="border1 whiteBG" style={{ minHeight: '440px' }}>
								<img
									src={s3StaticPath('img/home/slicing/wtk/2.png')}
									alt="App Features 1"
									className="mw100Force"
								/>
								<div className="roundedOverlay_home">
									<img src={s3StaticPath('img/home/slicing/Video-Downloads.png')} alt="Video-Downloads" height={60} />
								</div>
								<div className="px1-25 py25 text-center">
									<h6 className="secondary font-weight-bold lineH wtk_heading">
										Keepsake Downloads
									</h6>
									<span className="secondary mt25">
										No matter what Keepsake you are receiving, the photos and/or video can be downloaded from the app or WeddingTripKit.com gallery for offline viewing.
									</span>
								</div>
							</div>
						</Col>
					</Row>
					<Row className="mb25 tabsContentUpdated">
						<Col xs={12} sm={4} className="mt10">
							<div className="border1 whiteBG" style={{ minHeight: '440px' }}>
								<img
									src={s3StaticPath('img/home/slicing/wtk/3.png')}
									alt="App Features 1"
									className="mw100Force"
								/>
								<div className="roundedOverlay_home">
									<img src={s3StaticPath('img/home/slicing/Video-Clips.png')} alt="Video-Clips" height={60} />
								</div>
								<div className="px1-25 py25 text-center">
									<h6 className="secondary font-weight-bold lineH wtk_heading">
										Video Clips
									</h6>
									<span className="secondary mt25">
										Your wedding couple and guests can include their video clips in their keepsake video for Free.
									</span>
								</div>
							</div>
						</Col>
						<Col xs={12} sm={4} className="mt10">
							<div className="border1 whiteBG" style={{ minHeight: '440px' }}>
								<img
									src={s3StaticPath('img/home/slicing/wtk/4.png')}
									alt="App Features 1"
									className="mw100Force"
								/>
								<div className="roundedOverlay_home">
									<img src={s3StaticPath('img/home/slicing/USB-Flash.png')} alt="USB-Flash" />
								</div>
								<div className="px1-25 py25 text-center" >
									<h6 className="secondary font-weight-bold lineH wtk_heading">
										USB Flash Drive for Wedding Couple
									</h6>
									<span className="secondary mt25">
										Your wedding couples will have the photos and/or videos at their fingertips on a USB drive (Shipping within the US) to preserve for a lifetime!(Outside US will be able to download)
									</span>
								</div>
							</div>
						</Col>
						<Col xs={12} sm={4} className="mt10">
							<div className="border1 whiteBG" style={{ minHeight: '455px' }}>
								<img
									src={s3StaticPath('img/home/slicing/wtk/5.png')}
									alt="App Features 1"
									className="mw100Force"
								/>
								<div className="roundedOverlay_home">
									<img src={s3StaticPath('img/home/slicing/Facebook-Sharing.png')} alt="Facebook-Sharing" height={60} />
								</div>
								<div className="px1-25 py25 text-center">
									<h6 className="secondary font-weight-bold lineH wtk_heading">
										Facebook Sharing
									</h6>
									<span className="secondary mt25">
										Your wedding couple and guests can easily share photos on Facebook from the app or WeddingTripkit.com photo gallery.
									</span>
								</div>
							</div>
						</Col>
					</Row>
					<Row className="mb25 tabsContentUpdated mb-md">
						<Col xs={12} sm={4} className="mt10">
							<div className="border1 whiteBG" style={{ minHeight: '455px' }}>
								<img
									src={s3StaticPath('img/home/slicing/wtk/6.png')}
									alt="App Features 1"
									className="mw100Force"
								/>
								<div className="roundedOverlay_home">
									<img src={s3StaticPath('img/home/slicing/Photo-Editing.png')} alt="Photo-Editing" height={60} />
								</div>
								<div className="px1-25 py25 text-center">
									<h6 className="secondary font-weight-bold lineH wtk_heading">
										Photo Editing
									</h6>
									<span className="secondary mt25">
										Our editors go through all photos selected to remove not needed or duplicate photos, do color correction & cropping for the keepsakes. If the Wedding Couple does not specify what photos they would like in the keepsake, our editors pick out a select amount for the keepsake.
									</span>
								</div>
							</div>
						</Col>
						<Col xs={12} sm={4} className="mt10">
							<div className="border1 whiteBG" style={{ minHeight: '440px' }}>
								<img
									src={s3StaticPath('img/home/slicing/wtk/7.png')}
									alt="App Features 1"
									className="mw100Force"
								/>
								<div className="roundedOverlay_home">
									<img src={s3StaticPath('img/home/slicing/Advertising-&-Marketing.png')} alt="Advertising-&-Marketing" />
								</div>
								<div className="px1-25 py25 text-center" >
									<h6 className="secondary font-weight-bold lineH wtk_heading">
										Advertising &amp; Marketing
									</h6>
									<span className="secondary mt25">
										It's a commercial for your destination/venue funded by your wedding couples. Each time a guest logs in to the app or website they will see your company's logo as well as at the beginning &amp; end of each video. It serves as a great recruiting tool for future brides and grooms. They enjoy it for a lifetime.
									</span>
								</div>
							</div>
						</Col>
					</Row>
				</div>
			</section>
		</main>
	)
  }
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {

}
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard(UserHome));

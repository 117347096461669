import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { getAgencyDocumentsPagination } from 'redux/actions/agency-resource-actions';
import { dateFormatMyDocuments } from 'helper/helperFunctions';
import { SC } from 'helper/ServerCall';
import Folder from '../../Admin/Folder';
import AgencyFolder from '../../Admin/AgencyFolder';
// const moment = window.moment;
class GlobalDocuments extends Component {
    constructor(props) {
        super(props)
        this.state = {
            agencyDocuments: [],
            length:false,
            agency_folder_id: null,
            callData: [],
            addFolderModal: false,
            folderName: '',
            selectedFolder: 'No Folder Selected',
            expanded: [],
            is_folder: true,
            folders:[]
        }
        this.getAgencyFolderResources(this.props.agency_id).then(response =>{
            console.log("GlobalDocuments..........................", response);
            // console.log("response", response);
            var folder = response.data.folder_array;
               var ids = [];
               Object.keys(folder).map((item) => {
                    if (!(folder[item].value == "-1")) {
                        ids.push(folder[item].value.toString());
                    }
                })
            this.setState({
                expanded: ids,
                callData: response.data.data,
                folders: response.data.folder_array
            });
        })
    }
    getAgencyFolderResources = (id) => {
        return SC.getCall(`getAgencyFolderResources/${id}` );
    }
    componentDidMount() {
        this.props.getAgencyDocumentsPaginationCall(this.props.agency_id).then(response => {
            console.log(response.length,'document response');
            if(response && response.length > 0) {
                this.setState({ agencyDocuments: response,
                                length: response.length
                }, () => {
                    if(this.props.parentCallback){
                        // console.log(response.data.pagination.data.length,'paginationdata');
                    this.props.parentCallback(this.state.length)
                    }
                });
            }
        });
    }
    onFolderClick = (e, data, nodes, nodeId) => {
        const removeId = e.target.getAttribute('Mui-expanded');
        // const expandingNodes = nodes.filter(x => !this.state.expanded.includes(x));
        const expandingNodes = nodes;
        this.setState({
            expanded:expandingNodes,
            selectedFolder: data.folder_name,
            order_folder_id: data.folder_id,
            is_folder: true
        })
    }

    render() {
        // const { header } = this.props;
        const { agencyDocuments, callData, expanded } = this.state;
        return (
            <div>
                    {
                        callData ?
                        <>
                            <div className="flexElem respFlex spaceBetween form_tile_row mb-md">
                                <div className="col-md-12">
                                    <div className="flexElem mb-md">
                                        <span className="form_tile_title">Agency Documents</span>
                                        <p className="smallText">Will appear on all orders.</p>
                                    </div>
                                </div>
                            </div>
                            {
                                callData.map((item, key) => {
                                // console.log("index: " , key);
                                // console.log("item: " , item);
                                if (item.folder_id){
                                    return (
                                        <AgencyFolder key = {key} item = {item} ex = {expanded} onFolderClick = {this.onFolderClick} noDelete = {false} dragable = {false} />
                                    )
                                }
                                })
                            }

                            <div className="flexElem respFlex spaceBetween form_tile_row">
                                <div className="col-md-12">
                                    <div className="table-responsive">

                                        <table className="table_tile mb-md">
                                            <thead>
                                                <tr className="border-bottom">
                                                    <th width="35%">File Name</th>
                                                    <th width="25%">Uploaded Date</th>
                                                    <th width="20%">Status</th>
                                                    <th width="20%">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                callData.map((item, index) => {
                                                    if (item.file_path) {
                                                        return(
                                                            <tr key={item.id}>
                                                                <td><a style={{ wordBreak: 'break-all' }} className='blue_link' target="_blank" href={item.file_path} rel="noopener noreferrer">{item.original_file_name}</a></td>
                                                                <td>{dateFormatMyDocuments(item.created_at)}</td>
                                                                <td>{item.is_scheduled === 0 ? 'Sent' : 'Scheduled'}</td>
                                                                <td>
                                                                    <a className='ml-md' href={item.file_path}><i className='fa fa-download'></i></a>
                                                                </td>
                                                            </tr>
                                                        )
                                                    }else{
                                                        return null;
                                                    }
                                                    })
                                                }
                                            </tbody>
                                        </table>


                                        </div>
                                    </div>
                                </div>
                        </>
                        : ''
                    }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getAgencyDocumentsPaginationCall: (agency_id) => { return dispatch(getAgencyDocumentsPagination(agency_id)) },
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GlobalDocuments))

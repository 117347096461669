import React, { Component } from 'react';
import {
    Col,
    OverlayTrigger,
    ButtonToolbar,
    Popover
} from "react-bootstrap";
import ToggleButton from 'react-toggle-button';
import ThemeModal from '../../../ThemeComponents/ThemeModal';
import OrderMoreButtonForm from "./OrderMoreButtonForm";
import OrderMoreButtonsWeTravel from "./OrderMoreButtonsWeTravel";
import { objectsConstants as OC } from 'redux/constant/objects-constant';
import { Collapse } from 'react-bootstrap';
import { connect } from 'react-redux'
import { getMoreButtonsPagination, deleteMoreButton, updateMoreButtonStatus, getMoreButtonsForOrder, updateMoreButtonForOrder } from '../../../../redux/actions/more-buttons-action';
import Confirmalertfordelete from 'components/ThemeComponents/confirmAlertForDelete';
import { createSortString, closeModal, helperFunctions as HF } from 'helper/helperFunctions'
import { themeCSSConstants } from 'redux/constant/themeCSS';

import 'react-table/react-table.css';
import { confirmAlert } from 'react-confirm-alert';
import Pagination from '../../../ThemeComponents/Pagination'
import { SC } from 'helper/ServerCall';


class OrderMoreButtonsList extends Component {

    constructor(props) {
        super(props);
        this.table = React.createRef();
        this.state = {
            pageSize: 10,
            filtered: [],
            moreButtonEditModal: false,
            moreButtonCreateModal: false,
            moreButtonEditData: {},
            is_active: '1',
            moreButtonCollapse:false,
            weTravelButton: OC.MOREBUTTONS,
            agency: false,
            order_details: {...this.props.order_details},
            length:0
        }
        this.showModal = this.showModal.bind(this);
    }

    editOrderWeTravelSetting(data) {
        return SC.postCall('editOrderWeTravelSetting', data);
    }

    handleOptions = (name, value) => {
		let tempValue = !value;
        // console.log(name, value);
        tempValue = tempValue ? 1 : 0;

        // this.editOrderWeTravelSetting({key:name, value:tempValue, agency_id:this.state.agency.id, order_id:this.props.order_id});

        // order_details && (order_details.settings.accepts_wt_payments) ? +order_details.settings.accepts_wt_payments.value : ((agency && agency.settings.accepts_wt_payments) ? +agency.settings.accepts_wt_payments.value : 0)

		// this.setState(prevState => ({
        //     ...prevState, order_details: {
        //         ...prevState.order_details, settings:{
        //             ...prevState.order_details.settings, [name]: {
        //                 value: tempValue
        //             }
        //         },
        //     }
		// })
		// this.setState(prevState => ({
        //     ...prevState, agency: {
        //         ...prevState.agency, settings:{
        //             ...prevState.agency.settings, [name]: {
        //                 value: tempValue
        //             }
        //         },
        //     }
		// // })
        // 	,
		// 	() => {
				// if (name === 'wt_show_on_all_orders' || name === 'wt_option_at_order_placement') {
				// 	var WtOrder = 'wt_show_on_all_orders';
				// 	if (name === WtOrder)
				// 		WtOrder = 'wt_option_at_order_placement';

                //     console.log('anonymous', WtOrder, value);
                //     this.setState(prevState => ({
                //         ...prevState, agency: {
                //             ...prevState.agency, settings:{
                //                 ...prevState.agency.settings, [WtOrder]: {
                //                     value: 0
                //                 }
                //             }
                //         }
                //     }));
				// }
                // else if (name === 'accepts_wt_payments' && tempValue == 0) {
                //     this.setState(prevState => ({
                //         ...prevState, order_details: {
                //             ...prevState.order_details, settings:{
                //                 ...prevState.order_details.settings, ['wt_show_on_all_orders']: {
                //                     value: 0
                //                 },
                //                 ['wt_option_at_order_placement']: {
                //                     value: 0
                //                 }
                //             }
                //         }
                //     }));
                // }
            // });
	}

    editMoreOrderBtn = (moreBtnData, id, check, e) => {

        // console.log(moreBtnData, 'moreBtnData');
        // console.log(this.props.uuid, 'this.props.uuid');
        const data = new FormData();
        Object.keys(moreBtnData).forEach(item => {
            if(item == 'is_active' || item == 'uuid'){
                return;
            }
            data.append(item,moreBtnData[item]);
        })

        if(+this.props.order_id > 0) {
            data.append('order_id', +this.props.order_id);
        }
        else if(+this.props.uuid > 0) {
            data.append('uuid', +this.props.uuid);
        }

        let tempValue = !check;
        tempValue = tempValue ? 1 : 0;
        data.append('is_active', tempValue);

         if(!moreBtnData.file_name && !moreBtnData.link){

            this.showModal(moreBtnData, e, 'moreButtonEditModal')
              this.setState({
                  moreButtonEditModal:true
              });

         }else{
            //  check
            //  ?
            //  this.deleteMoreButtonHandler(id)
            //  :
             this.props.updateMoreButtonForOrderCall(data, moreBtnData.id, this.props.order_id).then(
                response => {
                    this.pagination.dataCall();
                }
            );
         }
    }

    showModal(data = '', e, target_name = null) {
        let newData = { ...data };
        newData.file_path = {
            preview: data.file_path,
            name: data.file_name,
            type: data.mime_type,
            id: data.id
        }
        target_name = target_name ? target_name : e.target.name;
        this.setState({
            [target_name]: true,
            moreButtonEditData: newData,
        });
    }
    deleteConfirmMoreButton = (id) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <Confirmalertfordelete typeName="Button" description="Are you sure you want to delete this button" onClosePro={onClose} deleteType={() => this.deleteMoreButtonHandler(id)} />
                )
            }
        })
    }
    deleteMoreButtonHandler(id) {
        this.props.deleteMoreButtonCall(id).then(res => {
            this.pagination.dataCall();
        });
    }
    paginationCall = (data) => {
        // console.log(data,"paginationData");
        let sorted = data.sort.split(',');
        let { sort, orderType } = createSortString(sorted);
        // console.log(this.props.order_id, 'order_id');
        // console.log(this.props.agency_id, 'agency_id');
        // let paginationData = this.props.getMoreButtonsForOrderCall(data.page, data.filter, sort, data.pageSize, orderType, this.props.order_id, this.props.agency_id, this.props.uuid);
        let paginationData = this.props.getMoreButtonsForOrderCall(data.page, data.filter, sort, data.pageSize, orderType, this.props.order_id, this.props.agency_id, this.props.uuid);
            // response =>{


            // }
        // if(paginationData && paginationData.data) {
        //     console.log(paginationData.data.pagination,"paginationData.data.pagination.data.length");
        //     this.setState({
        //         moreButtonCollapse : true
        //     })
        // }
        return paginationData
        // return this.props.getMoreButtonsPaginationCall(data.page, data.filter, sort, data.pageSize, orderType,this.props.agency_id);

    }
    handleInactiveStatusUpdate = (moreButtonId, e) => {
        this.props.updateMoreButtonStatusCall(moreButtonId, { is_active: e.target.value ? true : false }).then(
          response => {
            this.pagination.dataCall();
          }
        );
      }
      handleActiveStatusUpdate = (moreButtonId, e) => {
        this.props.updateMoreButtonStatusCall(moreButtonId, { is_active: e.target.value ? false : true }).then(
          response => {
            this.pagination.dataCall();
          }
        );
      }
    static getDerivedStateFromProps(props, state) {
        // console.log(props.agency,'getDerivedStateFromProps');
        return {
            agency: {...props.agency}
        };
    }
    componentDidUpdate(prevProps, prevState) {
        // console.log(this.state.length,'this state lengthhh');
        if(this.state.length !== prevState.length){
            // console.log("function scucess");/\
            this.setState({
                moreButtonCollapse: true
            })
        }

        // console.log(this.state.is_active, prevState.is_active ,'componentDidUpdate this.state.is_active !== prevState.is_active');
        // if (this.state.is_active !== prevState.is_active) {
        // this.pagination.dataCall();
        // }
        // if( this.props.agency !=  prevProps.agency) {
        //     this.setState({
        //         agency: {...this.props.agency}
        //     });
        // }
    }
    componentDidMount(){
        // if(this.state.length > 0){
        //     console.log("function scucess");
        //     this.setState({
        //         moreButtonCollapse: true
        //     })
        // }
    }
    handleAgencyLink = (name, value) => {
		this.setState(prevState => ({
            ...prevState, agency: {
                ...prevState.agency, settings:{
                    ...prevState.agency.settings, [name]: {
                        value: value
                    }
                },
            }
		}))
	}
    handleCallback = (childData) =>{
        this.setState({length: childData})
    }
    getChildState = ( childState ) => {
        this.setState({
            weTravelButton: childState
        }, () => {
            this.props.childState(this.state.weTravelButton)
        })
    }

    render() {
        const agency = this.state.agency

        const {order_details} = this.props;
        const { moreButtons , is_active} = this.state;

        const columns = [
            {
                Header: 'Activate For Mobile App'
                    // <ToggleButton
                    //     inactiveLabel={''}
                    //     activeLabel={''}
                    //     colors={themeCSSConstants.THUMBICONSTYLE}
                    //     trackStyle={{ height: '10px', width: '30px' }}
                    //     thumbStyle={themeCSSConstants.THUMBSTYLE}
                    //     thumbAnimateRange={[0, 15]}
                    // />
                ,
                sortable: false,
                excExp: false,
                accessor: 'id',
                className: "action-center",
                Cell: ({ original, value }) => {
                    return(
                        <div className="togglePadding">
                            <ToggleButton
                                inactiveLabel={''}
                                activeLabel={''}
                                colors={themeCSSConstants.THUMBICONSTYLE}
                                trackStyle={{ height: '10px', width: '30px' }}
                                thumbStyle={themeCSSConstants.THUMBSTYLE}
                                name={'moreButtonEditModal'}
                                thumbAnimateRange={[0, 15]}
                                // value={order_details && (order_details.settings.accepts_wt_payments) ? +order_details.settings.accepts_wt_payments.value : ((agency && agency.settings.accepts_wt_payments) ? +agency.settings.accepts_wt_payments.value : 0)}
                                value={original.model_type == 'App\\Order' ? (original.is_active == '1' ? 1 : 0) : (original.show_on_all_orders == '1' ? 1 : 0)}
                                disabled={false}
                                readOnly={true}
                                onToggle={() => { this.editMoreOrderBtn(original, original.id, original.model_type == 'App\\Order' ? (original.is_active == '1' ? 1 : 0) : (original.show_on_all_orders == '1' ? 1 : 0)) }}
                            />
                        </div>
                    )
                }
            },

            {
                Header: 'Title',
                accessor: 'title',
                className: "action-center",
                width: 120,
                height: 100,

            },
            {
                Header: 'File Name/Link',
                accessor: 'id',
                className: "action-center",
                Cell: ({ original }) => {
                    return <span>{
                        original.file_name ? original.file_name : original.link
                        }</span>
                }
            },
            {
                Header: "Actions",
                accessor: "id",
                className: "action-center",
                Cell: ({ original, value }) => {
                    // console.log(original, "valueees")
                    return (
                        <button name='moreButtonEditModal' onClick={(e) => { this.showModal(original, e) }} type='button' className="orderDetailsAction editIcon" >&nbsp;</button>
                    )
                },

                className: "action-center",
                sortable: false,
                excExp: false,
            }
        ]

        // console.log(this.state.agency.settings , ' this.state.agency.settings');
        return (
            <div>

                <div className="form_tile_heading flexElem alignCenter spaceBetween" id="moreBtnContainer" style={{cursor: 'pointer'}} onClick={() => {this.setState({moreButtonCollapse: !this.state.moreButtonCollapse})}}>
                    <div className="flexElem alignCenter">
                        <i className="fa fa-delicious" style={{opacity:0.8}}></i> &nbsp;&nbsp;
                        <span>List of 'More' Buttons on App</span>
                    </div>

                    <div>
                        <i className="fa fa-chevron-down" style={{fontSize: 'smaller', transition: '0.2s all ease', transform: this.state.moreButtonCollapse ? 'rotate(180deg)' : 'rotate(0deg)'}}></i>
                    </div>
                </div>
                <Collapse in={this.state.moreButtonCollapse}>
                    <div className="form_tile_content" >

                        <React.Fragment>
                            <div className='noMarginTable frontEndTable noBorderTable'>

                                {/* <OrderMoreButtonsWeTravel
                                    agency_id={this.props.agency_id}
                                    order_id={this.props.order_id}
                                    uuid={this.props.uuid}
                                    withUuid={true}
                                    childState={this.getChildState}
                                /> */}

                                <Pagination
                                    noCols={true}
                                    ref={(p) => this.pagination = p}
                                    showPagination={false}
                                    columns={columns}
                                    pageSize={10}
                                    noDataText='No Item found'
                                    getDataCall={this.paginationCall}
                                    // filterView={true}
                                    // filterPlaceHolder={'Button'}
                                    getRowProps={this.getRowProps}
                                    parentCallback = {this.handleCallback}
                                // showAllToggle={true}
                                />
                            </div>
                        </React.Fragment>

                    </div>
                </Collapse>

                <ThemeModal size='lg' show={this.state.moreButtonEditModal} hide={closeModal.bind(this, 'moreButtonEditModal')} title='Edit More Button'>
                    <OrderMoreButtonForm getPagination={this.pagination ? this.pagination.dataCall : null} formType='edit' hide={closeModal.bind(this, 'moreButtonEditModal')} data={this.state.moreButtonEditData} agency_id={this.props.agency_id} uuid={this.props.uuid} withUuid={true} />
                </ThemeModal>

                <ThemeModal size='lg' show={this.state.moreButtonCreateModal} hide={closeModal.bind(this, 'moreButtonCreateModal')} title='Create More Button'>
                    <OrderMoreButtonForm getPagination={this.pagination ? this.pagination.dataCall : null} hide={closeModal.bind(this, 'moreButtonCreateModal')} formType='add' agency_id={this.props.agency_id} uuid={this.props.uuid} withUuid={true}/>
                </ThemeModal>

            </div>
        )
    }
}
const mapStateToProps = (state) => {
    const { moreButtonsPagination } = state.moreButtonReducer;
    return { moreButtonsPagination  }
}

const mapDispatchToProps = dispatch => {
    return {
        getMoreButtonsPaginationCall: (page, filter, sort, pageSize, orderType, agency_id) => { return dispatch(getMoreButtonsPagination(page, filter, sort, pageSize, orderType, agency_id)) },
        updateMoreButtonStatusCall: (moreButtonId, data) => { return dispatch(updateMoreButtonStatus(moreButtonId, data)) },
        getMoreButtonsForOrderCall: (page, filter, sort, pageSize, orderType, order_id, agency_id, uuid) => { return dispatch(getMoreButtonsForOrder(page, filter, sort, pageSize, orderType, order_id, agency_id,uuid)) },
        deleteMoreButtonCall: (id) => { return dispatch(deleteMoreButton(id)) },
        updateMoreButtonForOrderCall: (data, id) => { return dispatch(updateMoreButtonForOrder(data, id)) }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(OrderMoreButtonsList)

import React, {Component, Fragment} from 'react'
import {connect} from 'react-redux'
import {getOrderUploadInfo, orderImages, getSingleImage} from 'redux/actions/order-actions';
import User from 'helper/User';
import ImageControls from 'components/FrontEnd/PhotoGallery/ImageControls';
import Image from 'components/FrontEnd/PhotoGallery/Image';
import {SortableContainer, arrayMove, SortableElement} from 'react-sortable-hoc';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import $ from 'jquery';
import {rotateImage, deleteImage} from 'redux/actions/imageActions';
import {Roles} from 'constants/Roles';
import {isMobile} from 'react-device-detect'
import {confirmAlert} from 'react-confirm-alert';
import ConfirmAlertForDelete from 'components/ThemeComponents/confirmAlertForDelete';
import ThemeModal from 'components/ThemeComponents/ThemeModal';
import {toggleModal, s3StaticPath} from 'helper/helperFunctions';
import {deleteImages} from '../../../services/order';
import {cloneDeep} from 'lodash'

class EditedPhotosGallery extends Component {

    constructor(props) {
        super(props)

        this.state = {
            imagesData: this.props.imagesData,
            pageSize: '',
            photoIndex: 0,
            isOpen: false,
            saveDragDrop: false,
            singleImageOpen: false,
            imageSize: 3,
            selectedImages: [],
            saveAlert: false,
            showImgData: false,
            imageVersion: 'original',
            enableSave: false,
            sizes: ['thumb', 'thumb_mid', 'thumb_large', 'thumb_xlarge', 'thumb_xxlarge', 'thumb_xxxlarge', 'full'],
            fullIndex: 6
        }
        this.toggleModal = toggleModal.bind(this)
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.imagesData !== prevProps.imagesData) {
            this.setState({
                imagesData: this.props.imagesData,
                enableSave: false
            });
        }
        if ((this.props.orderUploadInfo.finalize_by_editor.value !== prevProps.orderUploadInfo.finalize_by_editor.value)) {
            let imageVersion = 'original';
            if (Number(this.props.orderUploadInfo.finalize_by_editor.value) === 1) {
                imageVersion = 'working'
            }
            this.setState({
                imageVersion: imageVersion
            }, () => {
                this.props.orderImagesCall(User.order('id'), this.state.imageVersion, 'Uploaded-images', 1, '', '', this.state.pageSize, User.user.role.name);
            })
        }
    }

    componentDidMount() {
        var self = this;
        setInterval(function (ind = 0) {
            if (self.state.isOpen && self.state.singleImageOpen === false) {
                if (self.props.imagesData.length > 1) {
                    $(".ril-next-button").trigger("click");
                }
            }
        }, 8000);
        if (this.props.orderUploadInfo.finalize_by_editor.value !== undefined) {
            let imageVersion = 'original';
            if (Number(this.props.orderUploadInfo.finalize_by_editor.value) === 1) {
                imageVersion = 'working'
            }
            this.setState({
                imageVersion: imageVersion
            }, () => {
                this.props.orderImagesCall(User.order('id'), this.state.imageVersion, 'Uploaded-images', 1, '', '', this.state.pageSize, User.user.role.name);
            })
        }
        this.props.orderImagesCall(this.props.orderId, 'working', 'Uploaded-images', 1, '', '', '', User.user.roles.name);
    }

    openPopup = (index, singleImage = false) => {
        if (singleImage) {
            this.setState({isOpen: true, photoIndex: index, singleImageOpen: singleImage})
        } else {
            this.setState({isOpen: true, photoIndex: index})
        }
    }

    sortStart = ({index}) => {
        let sizes = this.state.sizes;
        $('.moving').find('.uploadedImageSize').find('img').after(() => {
            return (this.state.selectedImages.map(item => {
                if (item !== index)
                    return `<img class="appendedImgs" src=${this.state.imagesData[item].resized_image_path.replace('SIZE', sizes[this.state.imageSize - 1])} />`
                else
                    return null
            }) + (this.state.selectedImages.length > 0 ? `<span class="selectedCount purple_blue_gradient text_18">${this.state.selectedImages.length}</span>` : ''))
        })
        return null;
    }

    onSortEnd = ({oldIndex, newIndex}) => {
        if (oldIndex !== newIndex) {
            let imagesData = this.state.imagesData
            if (this.state.selectedImages.length > 0) {
                const newElement = imagesData[newIndex];
                const tempArr = [];
                this.state.imagesData.forEach((item, index) => {
                    if (!this.state.selectedImages.includes(index)) {
                        tempArr.push(item)
                    }
                });
                let newElementIndex = tempArr.findIndex(item => {
                    return item.id === newElement.id
                })
                if (newIndex > oldIndex) {
                    console.log('increasing index')
                    newElementIndex += 1
                }
                this.state.selectedImages.forEach((item, index) => {
                    tempArr.splice(newElementIndex + index, 0, imagesData[item]);
                })
                imagesData = tempArr
            } else {
                imagesData = arrayMove(imagesData, oldIndex, newIndex)
            }
            this.setState({
                imagesData: imagesData,
                // saveAlert: true
            }, () => {
                if (this.state.selectedImages.length > 0) {
                    this.setState({
                        selectedImages: [],
                    })
                }
            });
        }
        this.setState({
            enableSave: true
        })
    }

    selectImageHandler = (index) => {
        let selectedImages = this.state.selectedImages;
        if (selectedImages.includes(index)) {
            selectedImages = selectedImages.filter(imageIndex => {
                return imageIndex !== index
            })
        } else {
            selectedImages.push(index);
        }
        var selectedImagesSorted = selectedImages.sort((a, b) => a - b);
        this.setState({
            selectedImages: selectedImagesSorted
        })
    }
    deleteImage = (id, photoIndex) => {
        this.props.deleteImageCall(id, this.state.imageVersion).then(
            res => {
                if (photoIndex === this.state.imagesData.length - 1) {
                    this.setState({
                        photoIndex: photoIndex - 1
                    })
                }
            }
        );
    }

    deleteSelected = () => {
        const images = [];
        this.state.selectedImages.forEach(item => {
            images.push(this.state.imagesData[item].id);
        });
        const data = {images, version: this.state.imageVersion}
        deleteImages(data).then(
            res => {
                this.props.orderImagesCall(User.order('id'), this.state.imageVersion, 'Uploaded-images', this.props.current_page, '', '', this.state.pageSize, User.user.role.name);
                this.setState({
                    selectedImages: []
                })
            }
        )
    }

    rotateImage = (id, direction) => {
        this.props.rotateImageCall(id, direction);
    }
    closeLightBox = () => {
        if (this.state.singleImageOpen) {
            this.setState({isOpen: false, singleImageOpen: false})
        } else {
            this.setState({isOpen: false})
        }
    }

    confirmImagesDelete = () => {
        confirmAlert({
            customUI: ({onClose}) => <ConfirmAlertForDelete typeName='Images' onClosePro={onClose} deleteType={() => {
                this.deleteSelected()
            }}/>
        })
    }

    confirmImageDelete = (id, photoIndex) => {
        confirmAlert({
            customUI: ({onClose}) => <ConfirmAlertForDelete typeName='Image' onClosePro={onClose} deleteType={() => {
                this.deleteImage(id, photoIndex)
            }}/>
        })
    }

    downloadImage = (file) => {
        const downloadUrl = file.image_versions.file_path.replace('SIZE', this.state.sizes[this.state.fullIndex]) + '?updated_at=' + file.updated_at;
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', "download")
        link.target = "_blank";
        document.body.appendChild(link);
        link.click();
    }

    getLightBoxImagePath = (index) => {
        if (index === this.state.imagesData.length || index < 0)
            return null;
        const image = this.state.imagesData[index];
        return image.image_versions.file_path.replace('SIZE', this.state.sizes[this.state.imageSize]) + "?updated_at=" + image.updated_at;
    }

    renderNewImage = (index) => {
        let imageSrc = this.getLightBoxImagePath(index);
        if (imageSrc.match(/\.jpg/)) {
            imageSrc = imageSrc.replace(/\.jpg/, '.JPG');
        } else {
            imageSrc = imageSrc.replace(/\.JPG/, '.jpg');
        }
        return <img className='resolvedImage' src={imageSrc} alt=""/>
    }
    getImagePath = (src, index) => {
        const images = cloneDeep(this.state.imagesData);
        const image = cloneDeep(images[index]);
        if (!image.image_versions.file_path.includes(src)) {
            return;
        }
        if (Number(this.props.orderUploadInfo.finalize_by_qa.value) === 1) {
            if ('file_path' in image.image_versions) {
                image.image_versions.file_path = src.replace(/\.jpg/, '.JPG');
            } else {
                image.image_versions.file_path = s3StaticPath('img/image-placeholder.png');
            }
        } else {
            if (src.match(/\.jpg/)) {
                image.image_versions.file_path = src.replace(/\.jpg/, '.JPG');
            } else {
                image.image_versions.file_path = src.replace(/\.JPG/, '.jpg');
            }
        }
        setTimeout(() => {
            images[index] = image;
            this.setState({
                imagesData: images
            })
        }, 1000)


    }

    render() {
        const {photoIndex, isOpen} = this.state;
        const {imagesData} = this.state;
        const {orderUploadInfo, pages, current_page, total_images, from_page} = this.props;
        const sliderStyle = {
            position: "relative",
            width: "100%"
        };
        let sizes = this.state.sizes;

        return (
            <React.Fragment>
                <div className="container">
                    {
                        Number(orderUploadInfo.photos_submitted_for_video_production.value) === 0 && User.hasRole(Roles.PHOTO_ADMIN) &&
                        <ImageControls imageVersion={this.state.imageVersion} saveDrag={this.state.saveDragDrop}
                                       images={this.state.imagesData}/>
                    }

                    <div className={`editedPhotoGallery grid_${this.state.imageSize - 1}`}>
                        {/* <ImagesList showData={this.state.showImgData} onSortStart={this.sortStart} distance={5}
                                    selectedImages={this.state.selectedImages}
                                    selectImageHandler={this.selectImageHandler}
                                    imageSize={sizes[this.state.imageSize - 1]}
                                    disabled={Number(orderUploadInfo.photos_submitted_for_video_production.value) === 1 || isMobile || User.hasRole([Roles.TRAVELER])}
                                    helperClass='moving' transitionDuration={400} lockToContainerEdges={true}
                                    openPopup={this.openPopup} axis='xy' imagesData={imagesData}
                                    onSortEnd={this.onSortEnd}
                                    /> */}
                                    {
                                        imagesData.map((image, i) => {
                                            return (
                                               <Image
                                                    editedPhotoGallery={true}
                                                    selected={this.state.selectedImages.includes(i) ? 'selected' : 'not selected'}
                                                    selectImageHandler={this.selectImageHandler}
                                                    imageSize={sizes[this.state.imageSize - 1]}
                                                    openPopup={this.openPopup}
                                                    image={image}
                                                    index={i}
                                                />
                                            );
                                        })
                                    }
                                    {/* <Image
                                     editedPhotoGallery={true}
                                    selected={selectedImages.includes(item_index) ? 'selected' : 'not selected'}
                                    selectImageHandler={selectImageHandler}
                                    imageSize={imageSize}
                                    openPopup={openPopup}
                                    image={image}
                                    index={item_index}/> */}
                    </div>
                </div>

                {(isOpen && imagesData.length > 0) && (
                    <Lightbox
                        mainSrc={this.getLightBoxImagePath(photoIndex)}
                        nextSrc={this.getLightBoxImagePath(photoIndex + 1)}
                        prevSrc={this.getLightBoxImagePath(photoIndex - 1)}
                        onCloseRequest={this.closeLightBox}
                        onImageLoadError={(src) => this.getImagePath(src, photoIndex)}
                        imageLoadErrorMessage={this.renderNewImage(photoIndex)}
                        onMovePrevRequest={() =>
                            this.setState({
                                photoIndex: photoIndex - 1,
                            })
                        }
                        onMoveNextRequest={() =>
                            this.setState({
                                photoIndex: photoIndex + 1,
                            })
                        }
                        toolbarButtons={
                            [
                                <div className="fb-share-button"
                                     data-href={imagesData[photoIndex].image_versions.file_path.replace('SIZE', 'full') + ''}
                                     data-layout="button" data-size="large">
                                    <a onClick={(e) => {
                                        e.preventDefault();
                                        window.open(`https://www.facebook.com/sharer/sharer.php?u=${imagesData[photoIndex].image_versions.file_path.replace('SIZE', 'full')}`, 'popup', 'height=600,width=600')
                                    }} style={{
                                        color: '#fff',
                                        background: '#3b5998',
                                        padding: '5px',
                                        borderRadius: '5px'
                                    }}
                                       href={`https://www.facebook.com/sharer/sharer.php?u=${imagesData[photoIndex].image_versions.file_path + imagesData[photoIndex].file_title + "." + imagesData[photoIndex].image_format.extension}`}
                                       className="fb-xfbml-parse-ignore"><i className='fa fa-facebook'></i> Share</a>
                                </div>,
                                Number(orderUploadInfo.photos_submitted_for_video_production.value) === 0 && !User.hasRole(Roles.TRAVELER) &&
                                <i onClick={() => {
                                    this.confirmImageDelete(imagesData[photoIndex].id, photoIndex)
                                }} className='fa fa-trash ml-md lightBoxDownload'></i>,
                                <i onClick={() => {
                                    this.downloadImage(imagesData[photoIndex])
                                }} className='fa fa-download mr-md ml-md lightBoxDownload'></i>,
                            ]
                        }
                        imageCaption={
                            this.state.singleImageOpen &&
                            <React.Fragment>
                                <div className='text-center'>
                                    <p>Photos {from_page + photoIndex} of {total_images}</p>
                                </div>
                                {
                                    Number(orderUploadInfo.photos_submitted_for_video_production.value) === 0 &&
                                    <div className='flexElem' style={{justifyContent: 'center'}}>
                                        <div style={{margin: '0 10px', cursor: 'pointer'}} onClick={() => {
                                            this.rotateImage(imagesData[photoIndex].id, 'left')
                                        }}>
                                            <i style={{fontSize: '40px', width: '100%'}} className="fa fa-undo"
                                               aria-hidden="true"></i>
                                            <p>Rotate Left</p>
                                        </div>
                                        <div style={{margin: '0 10px', cursor: 'pointer'}} onClick={() => {
                                            this.rotateImage(imagesData[photoIndex].id, 'right')
                                        }}>
                                            <i style={{fontSize: '40px', width: '100%'}} className="fa fa-repeat"
                                               aria-hidden="true"></i>
                                            <p>Rotate Right</p>
                                        </div>
                                    </div>
                                }
                            </React.Fragment>
                        }
                    />
                )}
                <div id="companyImagesInfo"></div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    const {orderUploadInfo} = state.frontEndUserReducer;
    const { loadingOrder, imagesData, pages, current_page, perPage, order_details, total_images, from_page } = state.ordersReducer;
    return {
        orderUploadInfo, loadingOrder, imagesData, pages, current_page, perPage, order_details, total_images, from_page
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getOrderUploadInfoCall: (orderId) => { dispatch(getOrderUploadInfo(orderId))},
        orderImagesCall: (orderId, version_type, tabName, page, filter, sort, pageSize, userRole) => {
            dispatch(orderImages(orderId, version_type, tabName, page, filter, sort, pageSize, userRole))
        },
        getSingleImageCall: (imageId) => { dispatch(getSingleImage(imageId)) },
        rotateImageCall: (imageId, direction) => { dispatch(rotateImage(imageId, direction)) },
        deleteImageCall: (id) => { return dispatch(deleteImage(id)) },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditedPhotosGallery)

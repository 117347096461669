import React from "react";
import Dropzone from "react-dropzone";
import Confirmalert from 'components/ThemeComponents/confirmAlert';
import { confirmAlert } from 'react-confirm-alert';
import { s3StaticPath, removeDuplicateImage } from 'helper/helperFunctions';

// var findOrientation = require('exif-orientation');
// import EXIF from "exif-js";
// window.EXIF = EXIF;
// var fixRotation = require('fix-image-rotation')

class FileUpload extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            warningMsg: "",
            showAlert: true,
            loading: false,
        };
        this.replaceImageAlert = this.replaceImageAlert.bind(this);
        this.onDrop = this.onDrop.bind(this);
        this.dropzoneRef = React.createRef();
    };
    toggle = () => {
        this.setState({ showAlert: !this.state.showAlert });
    }
    extraHtml = () => {
        return (
            <span><i>Dont Show this pop up again</i><input type="checkbox" onClick={() => this.toggle()} /></span>
        );
    }
    replaceImageAlert(index, fileObject) {
        var self = this;
        return new Promise(function (resolve, reject) {
            const options = {
                customUI: ({ onClose }) => <Confirmalert
                    header="Replace image with same name"
                    description={"Are you sure to replace image " + fileObject.name + "?"}
                    onClosePro={onClose}
                    extraHtml={self.extraHtml}
                    onNo={() => { resolve({ replace: false }) }}
                    onYes={() => { resolve({ replace: true }) }}
                    yesLabel='Yes'
                    noLabel='No'
                />,
                closeOnEscape: false,
                closeOnClickOutside: false,
            };
            confirmAlert(options);
        });
    }

    async processFiles(rejected) {
        this.setState({ warningMsg: "" });

        const generateFile = (count, fileObject) => {
            return new Promise((resolve, reject) => {

                fetch(URL.createObjectURL(fileObject))
                    .then((res) => {
                        fileObject['file'] = res.blob();
                        return resolve({ index: count, fileObject });
                    })
                    .catch((e) => {
                        // see error handling section
                        console.log(e);
                    });

            });
        };

        var i = 0;
        var length = rejected.length;
        //if multiple files
        if (this.props.multiple) {
            this.setState({ loading: true });
            // for (var i=0; i < rejected.length; i++){
            for (const file of rejected) {

                let filename = file.name;
                let extension = filename.split('.').pop();
                if (extension.toLowerCase() === 'heic' || extension.toLowerCase() === 'heif') {
                    var temp = file;
                    //if allow duplicate
                    if (this.props.allowDuplicate) {
                        await generateFile(i, temp).then((value) => {
                            this.props.setResourceFile(this.props.type ? this.props.type : "image/jpeg", value.fileObject); //add file data to file aray
                        });
                    } else {
                        //not allowed duplicate
                        var chechDuplicate = removeDuplicateImage(temp, this.props.files);

                        //check duplicate and ask to update
                        if (chechDuplicate.duplicate && this.props.askToUpdate) {

                            //showing alert to update
                            if (this.state.showAlert) {
                                const confirmBox = await this.replaceImageAlert(chechDuplicate.index, chechDuplicate.val);
                                // const confirmBox = this.replaceImageAlert(chechDuplicate.index, chechDuplicate.val);
                                if (confirmBox.replace) {
                                    await generateFile(chechDuplicate.index, chechDuplicate.val).then((value) => {
                                        this.props.updateResourceFile(value.index, value.fileObject); //update file data to file aray
                                    });
                                }
                            } else {
                                //not showing alert to update image
                                await generateFile(chechDuplicate.index, chechDuplicate.val).then((value) => {
                                    this.props.updateResourceFile(value.index, value.fileObject); //update file data to file aray
                                });
                            }
                        }
                        else if (chechDuplicate.duplicate && this.props.updateWithoutAsking) {
                            //check duplicate and not ask to update image
                            await generateFile(chechDuplicate.index, temp).then((value) => {
                                this.props.updateResourceFile(value.index, value.fileObject); //update file data to file aray
                            });
                        } else {
                            //console.log('temp before', temp);
                            this.setState({ loading: true });
                            await generateFile(i, temp).then((value) => {
                                //console.log('temp after', value.fileObject);
                                this.props.setResourceFile(this.props.type ? this.props.type : "image/jpeg", value.fileObject); //add file data to file aray
                            });
                        }
                    }
                }
                i++;
                if (i === length) {
                    this.setState({ loading: false });
                }
            }
        } else {
            let filename = rejected[0].name;
            let extension = filename.split('.').pop();
            if (extension.toLowerCase() === 'heic' || extension.toLowerCase() === 'heif') {
                //if single files
                generateFile(0, rejected[0]).then((value) => {
                    this.props.setResourceFile(this.props.type ? this.props.type : "image/jpeg", value.fileObject); //add file data to file aray
                });
            }
        }
    }

    async onDrop(accepted, rejected) {
        // onDrop(accepted, rejected){
        // if (Object.keys(rejected).length !== 0) {
        //   const message = "Please submit valid file type";
        //   this.setState({ warningMsg: message });
        // }
        if (Object.keys(rejected).length !== 0) {
            this.processFiles(rejected);
        }
        if(accepted) {
            this.setState({ warningMsg: "" });

            const generateFile = (count, fileObject) => {
                return new Promise((resolve, reject) => {
                    const reader = new window.FileReader();
                    reader.readAsDataURL(fileObject);
                    reader.onloadend = () => {
                        const base64data = reader.result;
                        fileObject['file'] = base64data;
                        //console.log('fileObject jpeg file after', fileObject);
                        resolve({ index: count, fileObject: fileObject });
                    };
                });
            };

            var i = 0;
            var length = accepted.length;
            //if multiple files
            if (this.props.multiple) {
                this.setState({ loading: true });
                // for (var i=0; i < accepted.length; i++){
                for (const file of accepted) {
                    // var temp = accepted[i];
                    var temp = file;
                    //if allow duplicate
                    if (this.props.allowDuplicate) {
                        await generateFile(i, temp).then((value) => {
                            this.props.setResourceFile(this.props.type ? this.props.type : null, value.fileObject); //add file data to file aray
                        });
                    } else {
                        //not allowed duplicate
                        var chechDuplicate = removeDuplicateImage(temp, this.props.files);

                        //check duplicate and ask to update
                        if (chechDuplicate.duplicate && this.props.askToUpdate) {

                            //showing alert to update
                            if (this.state.showAlert) {
                                const confirmBox = await this.replaceImageAlert(chechDuplicate.index, chechDuplicate.val);
                                // const confirmBox = this.replaceImageAlert(chechDuplicate.index, chechDuplicate.val);
                                if (confirmBox.replace) {
                                    await generateFile(chechDuplicate.index, chechDuplicate.val).then((value) => {
                                        this.props.updateResourceFile(value.index, value.fileObject); //update file data to file aray
                                    });
                                }
                            } else {
                                //not showing alert to update image
                                await generateFile(chechDuplicate.index, chechDuplicate.val).then((value) => {
                                    this.props.updateResourceFile(value.index, value.fileObject); //update file data to file aray
                                });
                            }

                        }
                        else if (chechDuplicate.duplicate && this.props.updateWithoutAsking) {

                            //check duplicate and not ask to update image
                            await generateFile(chechDuplicate.index, temp).then((value) => {
                                this.props.updateResourceFile(value.index, value.fileObject); //update file data to file aray
                            });
                        } else {
                            // console.log('temp before', temp);
                            await generateFile(i, temp).then((value) => {
                                // console.log('temp after', value.fileObject);
                                this.props.setResourceFile(this.props.type ? this.props.type : null, value.fileObject); //add file data to file aray
                            });
                        }
                    }
                    i++;
                    if (i === length) {
                        this.setState({ loading: false });
                    }
                }
            } else {
                //if single files
                generateFile(0, accepted[0]).then((value) => {
                    this.props.setResourceFile(this.props.type ? this.props.type : null, value.fileObject); //add file data to file aray
                });
            }
        }
    };

    open() {
        if (this.dropzoneRef.current) {
            this.dropzoneRef.current.open();
        }
    }

    render() {
        const { allowedFormatsStringMimePro, allowedFormatsStringExtPro, multiple, title } = this.props;
        const render =
            <p className="hello">Please drop {title ? title : 'files'} here with format {allowedFormatsStringExtPro}</p>;

        return (
            <div style={{ position: "relative" }}>
                {
                    this.state.loading &&
                    <span className="myLoader">
                        <span> Building Preview...  </span> <img className="ml-md" src={s3StaticPath('img/recycle.svg')} alt="Loading..." />
                    </span>
                }
                <p>{this.state.warningMsg}</p>
                <Dropzone
                    ref={this.dropzoneRef}
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "10vh",
                        border: " 2px dashed"
                    }}
                    multiple={multiple}
                    accept={allowedFormatsStringMimePro}
                    onDrop={
                        (accepted, rejected) =>
                            this.onDrop(accepted, rejected)
                    }
                >
                    {({ isDragAccept, isDragReject, acceptedFiles, rejectedFiles }) => {
                        if (isDragReject) return "Please submit a valid file";
                        return render;
                    }}
                </Dropzone>
            </div>
        );
    }
}

export default FileUpload;

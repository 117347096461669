import React from 'react';
import { connect } from 'react-redux';
import AWS from 'aws-sdk'
import {
	OverlayTrigger,
	Popover,
} from "react-bootstrap";
import { getOrder, allOrderImagesByPhase, storeEditorOrderImages, assignUserToOrder, getOrderImagesPath, storeOrderImagesInDB } from 'redux/actions/order-actions';
// import DownloadFile from 'components/ThemeComponents/downloadFile';
import ImagesList from '../ImagesList';
import FileUpload from "components/Upload/FileUpload";
// import InputDropzoneComponent from "components/Upload/InputDropzoneComponent";
import { toast, Slide, Rotate, Flip } from 'react-toastify';
import ImagePreview from "components/Upload/ImagePreview";
import { hasRole, s3StaticPath, UploadImagesToS3, uuid } from 'helper/helperFunctions';
import { Card } from "components/Card/Card.jsx";
import { ThemeFunctions as TF } from 'helper/ThemeFunctions';
import { showLoader } from 'redux/actions/settings-actions';
import Axios from 'axios';
import { SC } from "helper/ServerCall";
import { history } from 'helper/history';
import { baseUrl } from '../../../../helper/ApiCall';
import { uploadFile } from 'react-s3';
import {BiLoaderAlt} from 'react-icons/bi';
import EXIF from 'exif-js';


var cloneDeep = require('lodash.clonedeep');

class UploadedImagesPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			uploadedImages: [],
			copyOfUploadedImages: [],
            responseArray: [],
			allowedFormatsStringMime: 'image/jpeg',
			allowedFormatsStringExt: [".jpg"],
			// allowedFormatsStringExt : 'jpg, png',
			collapseImageUploaderAndViewer: false,
			firstName: '',
			lastName: '',
            uploadedFiles: 0,
            imageData: [],
            count: 0,
            toastId: null,
            imageName: '',
            allowSave: false,

		};
        this.props.getOrderImagesPathCall(this.props.orderId, 'original')
        // .then( response => {
        //     console.log("response:-->> " , response);
        //     this.setState({
        //         imagePath: response.data.images_path
        //     })
        //     if(this.state.imagePath){
        //         console.log("path is not empty", this.state.imagePath);
        //     }else{
        //         console.log("path is empty");
        //     }
        // })

	}

	// extractImageData =  (value) =>  {
	// 	const file = {};
	// 	file['file'] = value['file'];
	// 	file['name'] = value['name'];
	// 	file['size'] = value['size'];
	// 	file['extension'] = returnExtFromImage(value['name']);
	// 	file['preview'] = value['preview'];
	// 	file['type'] = value['type'];
	// 	file['lastModified'] = value['lastModified'];
	// 	return file;
	// }
	// setResourceFile = (key, value) => {
	// 	this.setState({
	// 		uploadedImages: [...this.state.uploadedImages, this.extractImageData(value) ],
	// 	});
	// }

	// setResourceFile = (key, value) => {
	// 	console.log(' value',  value);
	//     const CancelToken = Axios.CancelToken;
	// 	const source = CancelToken.source();
	// 	value.axiosToken = source;
	// 	this.setState((prevState) => {
	// 		return {
	// 			uploadedImages: [
	// 				...prevState.uploadedImages,
	// 				value
	// 			]
	// 		}
	// 	});

	// }

    // getOrderImagesPath = (orderId, folder) => {
    //     return SC.getCall(`getOrderImagesPath/${orderId}/${folder}`);
    //   }
    // imageCardHandler = (orderId, folder) => {
    //     this.getOrderImagesPath(orderId, folder).then( response => {
    //         console.log("response:-->> " , response);
    //         // this.setState({
    //         //     imagePath: response.data.images_path
    //         // })
    //         if(this.state.imagePath){
    //             console.log("path is not empty", this.state.imagePath);
    //         }else{
    //             console.log("path is empty");
    //         }
    //     })
    // }

	setResourceFile = (key, value) => {
		// console.log(' value',  value);
	    const CancelToken = Axios.CancelToken;
		const source = CancelToken.source();
		value.axiosToken = source;
		this.setState((prevState) => {
			return {
				uploadedImages: [
					...prevState.uploadedImages,
					value
				]
			}
		});
		// const uploadedImages = [...this.state.uploadedImages];
        // // console.log("setResourceFile----->", uploadedImages );

        // let responseArray = [];
		// uploadedImages.forEach((file) => {
        //     EXIF.getData(file, function() {
        //         let u_id = uuid().toString();
        //         let ext =  file.name.split('.').pop();
        //         console.log("extension===>", ext);
        //         console.log("uuid===>", uuid);
        //         const exifData = EXIF.pretty(this);
        //         if (exifData) {
        //             let Data = {
        //                 file_size : file.size,
        //                 file_title : file.name,
        //                 file_type : file.type,
        //                 file_name : u_id + '.' + ext,
        //                 exif_orientation : EXIF.getTag(this, "Orientation") ? EXIF.getTag(this, "Orientation") : null,
        //                 use_in_highlight_video  : EXIF.getTag(this, "use_in_highlight_video") ? EXIF.getTag(this, "use_in_highlight_video") : 0,
        //                 exif_date_taken : EXIF.getTag(this, "DateTime") ? EXIF.getTag(this, "DateTime") : null,
        //                 DateTimeOriginal : EXIF.getTag(this, "DateTimeOriginal") ? EXIF.getTag(this, "DateTimeOriginal") : null,
        //                 ModifyDate : EXIF.getTag(this, "ModifyDate") ? EXIF.getTag(this, "ModifyDate") : null,
        //                 exif_manufacturer : EXIF.getTag(this, "Make") ? EXIF.getTag(this, "Make") : null,
        //                 exif_camera_model : EXIF.getTag(this, "Model") ? EXIF.getTag(this, "Model") : null,
        //                 exif_long   : EXIF.getTag(this, "GPSLongitude") ? EXIF.getTag(this, "GPSLongitude") : null,
        //                 exif_lat : EXIF.getTag(this, "GPSLatitude") ? EXIF.getTag(this, "GPSLatitude") : null,
        //                 height : EXIF.getTag(this, "PixelXDimension") ? EXIF.getTag(this, "PixelXDimension") : null,
        //                 width  : EXIF.getTag(this, "PixelYDimension") ? EXIF.getTag(this, "PixelYDimension") : null,
        //             };
        //             responseArray.push(Data);

        //         //   console.log(exifData);
        //         //   console.log(EXIF.getTag(this, "Orientation"));
        //         } else {
        //         //   console.log("No EXIF data found in image '" + file.name + "'.");
        //         }
        //       });
        //     //   console.log("araay", responseArray);
        //       // const fd = new FormData();
		// });
        // this.setState({
        //     imageData : responseArray,
        // },() => {
        //     // console.log("success", this.state.imageData)
        // })

	}
	updateResourceFile = (index, fileObject) => {
		const uploadedImagesState = cloneDeep(this.state.uploadedImages);
		const CancelToken = Axios.CancelToken;
		const source = CancelToken.source();
		fileObject.axiosToken = source;
		uploadedImagesState[index] = fileObject;
		this.setState({
			uploadedImages: uploadedImagesState,
		});
	}
	removeFile = (key, index) => {
		const uploadedImagesState = [...this.state.uploadedImages]
		uploadedImagesState.splice(index, 1);
		this.setState({
			uploadedImages: uploadedImagesState,
		});
	}
	clearAllTempImages = () => {
		this.setState({
			uploadedImages: [],
		});
	}






    uploadFiles = async (myBucket, S3_BUCKET) => {
		var delay = 500;
		if(this.state.copyOfUploadedImages.length % 20 === 0 && this.state.copyOfUploadedImages.length > 0) {
			delay = 5000;
		}

		let self = this;
		//  create a loop function
		setTimeout(() => {   //  call a delay seconds setTimeout when the loop is called


			let uploadedImages = [...this.state.uploadedImages];

			let arr = [...this.state.copyOfUploadedImages];

			if(arr) {
				// At position 0, get 1 items:
				let file_slice = arr.slice(0, 1);
				var file = file_slice[0];
				// At position 0, remove 1 item:
				arr.splice(0, 1);
				this.setState({
					copyOfUploadedImages: arr,
				});
			}

			if(file == undefined || !file || !file.name || !arr) {
				return;
			}

			let u_id = uuid().toString();
			let ext =  file.name.split('.').pop();
			var currentImageName = u_id + '.' + ext;
			EXIF.getData(file, function() {
				const exifData = EXIF.pretty(this);
				let Data = {};
				if (exifData) {
					console.log("if u_id + '.' + ext===> file.name", file.name, 'original_file_name', u_id + '.' + ext);
					Data = {
						file_size : file.size,
						file_title : file.name,
						file_type : file.type,
						original_file_name : u_id + '.' + ext,
						exif_orientation : EXIF.getTag(this, "Orientation") ? EXIF.getTag(this, "Orientation") : null,
						use_in_highlight_video  : EXIF.getTag(this, "use_in_highlight_video") ? EXIF.getTag(this, "use_in_highlight_video") : 0,
						exif_date_taken : EXIF.getTag(this, "DateTime") ? EXIF.getTag(this, "DateTime") : null,
						DateTimeOriginal : EXIF.getTag(this, "DateTimeOriginal") ? EXIF.getTag(this, "DateTimeOriginal") : null,
						ModifyDate : EXIF.getTag(this, "ModifyDate") ? EXIF.getTag(this, "ModifyDate") : null,
						exif_manufacturer : EXIF.getTag(this, "Make") ? EXIF.getTag(this, "Make") : null,
						exif_camera_model : EXIF.getTag(this, "Model") ? EXIF.getTag(this, "Model") : null,
						exif_long   : EXIF.getTag(this, "GPSLongitude") ? EXIF.getTag(this, "GPSLongitude") : null,
						exif_lat : EXIF.getTag(this, "GPSLatitude") ? EXIF.getTag(this, "GPSLatitude") : null,
						height : EXIF.getTag(this, "PixelXDimension") ? EXIF.getTag(this, "PixelXDimension") : null,
						width  : EXIF.getTag(this, "PixelYDimension") ? EXIF.getTag(this, "PixelYDimension") : null,
					};
				} else {
					Data = {
						file_size : file.size,
						file_title : file.name,
						file_type : file.type,
						original_file_name : u_id + '.' + ext
					};
					  console.log("No EXIF data found in image '" + file.name + "'.", 'original_file_name : ', u_id + '.' + ext);
				}

				if(Data) {
					let tmp_elm = [...self.state.imageData];
					tmp_elm.push(Data);
					self.setState({
						imageData : tmp_elm,
					},() => {
						console.log("success", self.state.imageData)
					});
				}
			});

			try{

				const params = {
					ACL: 'public-read',
					Body: file,
					Bucket: S3_BUCKET,
					Key: currentImageName
				};

				setTimeout(() => {
					myBucket.putObject(params, (err, data)=>{


						if (err) {
							console.log('putObject err occored ', file.name, ' ', this.state.uploadedFiles, err, 'myBucket.putObject err.stack ', err.stack); // an error occurred
							//return;
						}
						else {
							console.log('myBucket.putObject response ', file.name, ' ', this.state.uploadedFiles);           // successful response
						}

						const uploaded = this.state.uploadedFiles;
						this.setState({
							uploadedFiles: uploaded + 1
						},()=>{
								if (self.state.uploadedFiles === uploadedImages.length) {
									toast.dismiss(self.toastIdImg);
									setTimeout(() => {

										const imageData = {images: self.state.imageData , uploaded_by_first_name: self.state.firstName, uploaded_by_last_name: self.state.lastName} ;
										self.props.storeOrderImagesInDBCall(imageData, self.props.orderId, 'original').then(

												response => {
													self.setState({
															uploadedImages: [],
															imageData: [],
															responseArray: [],
															uploadedFiles: 0,
															collapseImageUploaderAndViewer: false,
															firstName: '',
															lastName: '',
                                                            allowSave: false,
														})
													self.props.allOrderImagesByPhaseCall(self.props.orderId, 'original', self.props.tabName);
													self.props.showLoaderCall(true);
												},
												error => {
													console.log('error');
												}
												)

									}, 1000);
								}
								else {
									toast.update(self.toastIdImg, {
										render: <React.Fragment>Images are Uploading. Please wait! {self.state.uploadedFiles +'/'+ uploadedImages.length}  <BiLoaderAlt className="loadingSpinner" /></React.Fragment>,
										progress: uploadedImages.length,
										type: toast.TYPE.INFO,
										position: "bottom-right",
									});
								}
						})

					});
				}, delay);

			} catch (err) {
				console.log('S3 put object error', err);
				Object.keys(err).map(x => {
					file['message'] = err[x];
				});
			}

			if (this.state.uploadedFiles < uploadedImages.length) {           //  if the counter < 10, call the loop function
				this.uploadFiles(myBucket, S3_BUCKET);             //  ..  again which will trigger another
			}                       //  ..  setTimeout()

		}, delay)
	}


    saveImages = () => {
        this.setState({
            allowSave: true
        })
		this.props.showLoaderCall(false);
		const uploadedImages = [...this.state.uploadedImages];

		let temp_files = [...this.state.uploadedImages];
		this.setState({
			copyOfUploadedImages: temp_files
		});


        // const files = [...this.state.uploadedImages];
        // const total_files = files.length;
        const orderImagePath = this.props.orderImagePath;
		this.toastIdImg = null;
			this.toastIdImg =  toast(<React.Fragment>Images are Uploading. Please wait! {this.state.uploadedFiles +'/'+ uploadedImages.length}  <BiLoaderAlt className="loadingSpinner" /></React.Fragment>, {
				progress: uploadedImages.length,
				type: toast.TYPE.INFO,
				position: "bottom-right",
                autoClose: true
			});

        // let responseArray = [];
        // var currentImageName = '';

		try{
			const { REACT_APP_AWS_ACCESS_KEY_ID, REACT_APP_AWS_BUCKET, REACT_APP_AWS_DEFAULT_REGION, REACT_APP_AWS_SECRET_ACCESS_KEY  } = process.env;
			var S3_BUCKET = REACT_APP_AWS_BUCKET + '/' + orderImagePath;
			const REGION = REACT_APP_AWS_DEFAULT_REGION;
			// const https = require('https');
			AWS.config.update({
			//   httpOptions: {
			// 	agent: new https.Agent({
			// 	  secureProtocol: 'TLSv1_2_method',
			// 	  ciphers: 'ALL',
			// 	}),
			//   },
				accessKeyId: REACT_APP_AWS_ACCESS_KEY_ID,
				secretAccessKey: REACT_APP_AWS_SECRET_ACCESS_KEY
			})
			var myBucket = new AWS.S3({
				params: { Bucket: S3_BUCKET},
				region: REGION,
				maxRetries: 20, // retry 10 times
                useAccelerateEndpoint: true
				//retryDelayOptions: { base: 200 } // see AWS.Config for information
			})
		} catch (err) {
			console.log(err);
		}

		this.uploadFiles(myBucket, S3_BUCKET);                   //  start the loop
    }



















	// saveImagesCopy = async (e) => {
    //     e.preventDefault();
	// 	this.props.showLoaderCall(false);
	// 	const uploadedImages = [...this.state.uploadedImages];
	// 	const files = [...this.state.uploadedImages];
    //     const orderImagePath = this.props.orderImagePath;
	// 	let toastIdImg = null;
	// 		// toastIdImg =  toast('Images uploading in Progress ' + this.state.uploadedFiles +'/'+uploadedImages.length , {
	// 		toastIdImg =  toast(<React.Fragment>Images are Uploading. Please wait! {this.state.uploadedFiles +'/'+ uploadedImages.length}  <BiLoaderAlt className="loadingSpinner" /></React.Fragment>, {
	// 			progress: uploadedImages.length,
	// 			type: toast.TYPE.INFO,
	// 			position: "bottom-right",
    //             autoClose: true
	// 		});

    //     let responseArray = [];
    //     var currentImageName = '';



	// 	for (const file of files) {

    //         let u_id = uuid().toString();
    //         let ext =  file.name.split('.').pop();
    //         // console.log("uuid===>", u_id);
    //         currentImageName = u_id + '.' + ext;
    //         // console.log("currentImageName===>", currentImageName);
    //         EXIF.getData(file, function() {
    //             // this.setState({
    //             //     imageName: u_id + '.' + ext
    //             // }, () => {
    //             //     console.log("imageName===>", this.state.imageName);
    //             // })
    //             const exifData = EXIF.pretty(this);
    //             let Data = {};
    //             if (exifData) {
    //                 // console.log("currentImageName0000000===>", currentImageName);
    //                 console.log("u_id + '.' + ext===>", u_id + '.' + ext);
    //                 Data = {
    //                     file_size : file.size,
    //                     file_title : file.name,
    //                     file_type : file.type,
    //                     original_file_name : u_id + '.' + ext,
    //                     exif_orientation : EXIF.getTag(this, "Orientation") ? EXIF.getTag(this, "Orientation") : null,
    //                     use_in_highlight_video  : EXIF.getTag(this, "use_in_highlight_video") ? EXIF.getTag(this, "use_in_highlight_video") : 0,
    //                     exif_date_taken : EXIF.getTag(this, "DateTime") ? EXIF.getTag(this, "DateTime") : null,
    //                     DateTimeOriginal : EXIF.getTag(this, "DateTimeOriginal") ? EXIF.getTag(this, "DateTimeOriginal") : null,
    //                     ModifyDate : EXIF.getTag(this, "ModifyDate") ? EXIF.getTag(this, "ModifyDate") : null,
    //                     exif_manufacturer : EXIF.getTag(this, "Make") ? EXIF.getTag(this, "Make") : null,
    //                     exif_camera_model : EXIF.getTag(this, "Model") ? EXIF.getTag(this, "Model") : null,
    //                     exif_long   : EXIF.getTag(this, "GPSLongitude") ? EXIF.getTag(this, "GPSLongitude") : null,
    //                     exif_lat : EXIF.getTag(this, "GPSLatitude") ? EXIF.getTag(this, "GPSLatitude") : null,
    //                     height : EXIF.getTag(this, "PixelXDimension") ? EXIF.getTag(this, "PixelXDimension") : null,
    //                     width  : EXIF.getTag(this, "PixelYDimension") ? EXIF.getTag(this, "PixelYDimension") : null,
    //                 };
    //                 // console.log("responseArray======: ", responseArray);

    //                 //   console.log(exifData);
    //                 //   console.log(EXIF.getTag(this, "Orientation"));
    //             } else {
    //                 Data = {
    //                     file_size : file.size,
    //                     file_title : file.name,
    //                     file_type : file.type,
    //                     original_file_name : u_id + '.' + ext
    //                 };
    //                 //   console.log("No EXIF data found in image '" + file.name + "'.");
    //             }

    //             responseArray.push(Data);

    //           });
    //           try{
    //             //   let s3imgpath = orderImagePath + '/' + currentImageName;
    //             //   console.log(s3imgpath);
    //             // const config = UploadImagesToS3(s3imgpath);
    //             // console.log("image uploaded", file);

    //             // console.log("s3333333===>", u_id);
    //             // const updateName = cloneDeep(file);
    //             // console.log(updateName.name, "----->>>updateName");
    //             // file.name = currentImageName;


    //             const { REACT_APP_AWS_ACCESS_KEY_ID, REACT_APP_AWS_BUCKET, REACT_APP_AWS_DEFAULT_REGION, REACT_APP_AWS_SECRET_ACCESS_KEY  } = process.env;


    //             const S3_BUCKET = REACT_APP_AWS_BUCKET + '/' + orderImagePath;
    //             const REGION = REACT_APP_AWS_DEFAULT_REGION;
    //             AWS.config.update({
    //                 accessKeyId: REACT_APP_AWS_ACCESS_KEY_ID,
    //                 secretAccessKey: REACT_APP_AWS_SECRET_ACCESS_KEY
    //             })
    //             const myBucket = new AWS.S3({
    //                 params: { Bucket: S3_BUCKET},
    //                 region: REGION,
    //             })
    //             // const uploadFile = (file) => {

    //                 const params = {
    //                     ACL: 'public-read',
    //                     Body: file,
    //                     Bucket: S3_BUCKET,
    //                     Key: currentImageName
    //                 };
    //                myBucket.putObject(params, (data)=>{

    //                    const uploaded = this.state.uploadedFiles;
    //                     this.setState({
    //                         uploadedFiles: uploaded + 1
    //                     },()=>{
    //                         // console.log("stateeeeee-->", this.state.uploadedFiles);
    //                             if (this.state.uploadedFiles === uploadedImages.length) {
    //                                 // toast.update(toastIdImg, {
    //                                 //     render: 'Images uploaded successfully ',
    //                                 //     progress: uploadedImages.length,
    //                                 //     type: toast.TYPE.SUCCESS,
    //                                 //     position: "bottom-right",
    //                                 //     duration: 5000,
    //                                 //     transition: Slide,
    //                                 //     draggablePercent: 60,
    //                                 //     closeOnClick: true,

    //                                 // });

    //                                 toast.dismiss(toastIdImg);
    //                                 setTimeout(() => {


    //                                     const imageData = {images: this.state.imageData , uploaded_by_first_name: this.state.firstName, uploaded_by_last_name: this.state.lastName} ;
    //                                     this.props.storeOrderImagesInDBCall(imageData, this.props.orderId, 'original').then(

    //                                             response => {
    //                                                 this.setState({
    //                                                         uploadedImages: [],
    //                                                         uploadedFiles: 0,
    //                                                         collapseImageUploaderAndViewer: false,
    //                                                         firstName: '',
	// 		                                                lastName: '',
    //                                                     })
    //                                                 this.props.allOrderImagesByPhaseCall(this.props.orderId, 'original', this.props.tabName);
    //                                                 // this.props.getOrderCall(this.props.orderId);
    //                                                 this.props.showLoaderCall(true);
    //                                                 // this.setState({
    //                                                 //     uploadedImages: [],
    //                                                 //     uploadedFiles: 0,
    //                                                 //     collapseImageUploaderAndViewer: false
    //                                                 // })
    //                                             },
    //                                             error => {
    //                                                 console.log('error');
    //                                             }
    //                                             )

    //                                 }, 1000);
    //                             } else {
    //                                 toast.update(toastIdImg, {
    //                                     // render: 'Images uploading in Progress ' + this.state.uploadedFiles +'/'+uploadedImages.length,
    //                                     render: <React.Fragment>Images are Processing. Please wait! {this.state.uploadedFiles +'/'+ uploadedImages.length}  <BiLoaderAlt className="loadingSpinner" /></React.Fragment>,
    //                                     progress: uploadedImages.length,
    //                                     type: toast.TYPE.INFO,
    //                                     position: "bottom-right",
    //                                 });
    //                             }
    //                     })







    //                }).then((response) => {console.log(response);});
    //             //    console.log("hhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhh");
    //                     // .on('httpUploadProgress', (evt) => {
    //                     //     setProgress(Math.round((evt.loaded / evt.total) * 100))
    //                     // })
    //                     // .send((err) => {
    //                     //     if (err) console.log(err)
    //                     // })
    //             // }
    //             // uploadFile(file)
    //         } catch (err) {
    //             // console.log("errorrrrrrrrrrrrrrrrrrrrrrrrrrrr");
    //             Object.keys(err).map(x => {
    //                 file['message'] = err[x];
    //             });
    //         }




    //             // const res =  await uploadFile(file, config);


    //           this.setState({
    //                 imageData : responseArray,
    //             },() => {
    //                 console.log("success", this.state.imageData)
    //             });
	// 		// try{
    //         //     const config = UploadImagesToS3(orderImagePath);
    //         //     console.log("image uploaded", file);

    //         //     console.log("s3333333===>", u_id);
    //         //     const updateName = cloneDeep(file);
    //         //     console.log(updateName.name, "----->>>updateName");
    //         //     // file.name = currentImageName;
    //         //     const res =  await uploadFile(file, config);
    //         //     const uploaded = this.state.uploadedFiles;
    //         //     this.setState({
    //         //         uploadedFiles: uploaded + 1
    //         //     },()=>{
    //         //         // console.log("stateeeeee-->", this.state.uploadedFiles);
	// 		// 			if (this.state.uploadedFiles === uploadedImages.length) {
	// 		// 				toast.update(toastIdImg, {
	// 		// 					render: 'Images uploaded successfully ',
	// 		// 					progress: uploadedImages.length,
	// 		// 					type: toast.TYPE.SUCCESS,
	// 		// 					position: "bottom-right",
    //         //                     duration: 5000,
    //         //                     transition: Slide,
    //         //                     draggablePercent: 60,
    //         //                     closeOnClick: true,

	// 		// 			});
    //         //             setTimeout(() => {
    //         //                  toast.dismiss(toastIdImg);
    //         //                  this.setState({
    //         //                      uploadedImages: [],
    //         //                      uploadedFiles: 0,
    //         //                      collapseImageUploaderAndViewer: false
    //         //                  })
    //         //             }, 2000);
	// 		// 		} else {
	// 		// 			toast.update(toastIdImg, {
    //         //                 render: 'Images uploading in Progress ' + this.state.uploadedFiles +'/'+uploadedImages.length,
    //         //                 progress: uploadedImages.length,
    //         //                 type: toast.TYPE.INFO,
    //         //                 position: "bottom-right",
    //         //             });
	// 		// 		}
    //         //     })
    //         // } catch (err) {
    //         //     Object.keys(err).map(x => {
    //         //         file['message'] = err[x];
    //         //     });
    //         // }
	// 	};

    //     // this.props.allOrderImagesByPhaseCall(this.props.orderId, 'original', this.props.tabName);

    //     // console.log("state1" ,this.state.imageData);


    //         // const imageData = {images: this.state.imageData , uploaded_by_first_name: this.state.firstName, uploaded_by_last_name: this.state.lastName} ;
    //         // this.props.storeEditorOrderImagesCall(imageData, this.props.orderId, 'original').then(

    //         //          response => {
    //         //              this.props.getOrderCall(this.props.orderId);
    //         //              this.props.showLoaderCall(true);
    //         //          },
    //         //          error => {
    //         //              console.log('error');
    //         //          }
    //         //          )
    //         // const con = UploadImagesToS3(this.state.imagePath);
    //         // // console.log("image uploaded", files);
    //         //     uploadFile(fd, con).then(
    //         //         data =>{
    //         //             console.log("image uploaded successfully");
    //                         //  this.props.storeEditorOrderImagesCall(imageData, this.props.orderId, 'original').then(

    //                         //      response => {
    //                         //          this.props.getOrderCall(this.props.orderId);
    //                         //          this.props.showLoaderCall(true);
    //                         //      },
    //                         //      error => {
    //                         //          console.log('error');
    //                         //      }
    //                         //      )
    //         //          })
    //         //         .catch(err => console.error(err))
	// }
	assignEditorToOrder = (orderId) => {
		this.props.assignUserToOrderCall(orderId, { userId: this.props.userAdmin.user.id, role: 'editor' }).then(response => {
			if (hasRole(['admin', 'gm admin'])) {
				history.push(`/Admin/${orderId}/order-edit?orderDetailCode=production&imageCode=editor`);
				history.go();

			} else {
				history.push(`/Admin/${orderId}/order-edit?imageCode=editor`);
				history.go();

			}
		});
	}
	componentDidUpdate(prevProps, prevState) {
		if (prevProps.imageUpdates !== this.props.imageUpdates) {
			this.setState({ uploadedImages: [] }, function () {
				this.ctrl_list.imagesPaginationReload();
			});
        }
		if (prevProps.order_details !== this.props.order_details) {
			console.log("calllllllllllllllllllllllllll");
			// setTimeout(
			//     function() {
			//         this.setState({uploadedImages:[]}, function(){
			// 			this.ctrl_list.imagesPaginationReload();
			// 		});
			//     }
			//     .bind(this),
			//     3500
			// );
		}

        if (prevState.uploadedFiles !== this.state.uploadedFiles){
            this.setState({
                uploadedFiles: this.state.uploadedFiles})
        }

        if (prevState.imageData !== this.state.imageData){
            this.setState({
                imageData: this.state.imageData})
        }

        if (prevState.uploadedImages !== this.state.uploadedImages){
            this.setState({
                uploadedImages: this.state.uploadedImages})
        }

        if (prevState.count !== this.state.count){
            this.setState({
                count: this.state.count})
        }
        if (prevState.imageName !== this.state.imageName){
            this.setState({
                imageName: this.state.imageName})
        }
	}
	onChangeName = (e) => {
		const { name, value } = e.target;
		this.setState({ [name]: value });

	}
	componentDidMount() {
		this.props.allOrderImagesByPhaseCall(this.props.orderId, 'original', this.props.tabName);
	}
	render() {
        // console.log("Render_imageData--->>>", this.state.uploadedFiles);
		const { orderId, tabName, allImagesByPhase, order_details } = this.props;
		const { allowedFormatsStringExt, allowedFormatsStringMime, uploadedImages, firstName, lastName, allowSave } = this.state;

		return (
			<div>
				{
					hasRole(['admin', 'gm admin', 'Remote Editor','Remote Editor/Producer']) &&
					<Card bsClass={["innerCard"]} title="Upload Images" collapseTrigger={this.state.collapseImageUploaderAndViewer} collapsHandler={() => this.setState({ collapseImageUploaderAndViewer: !this.state.collapseImageUploaderAndViewer })} collapsable={true}
						content={
							<div>
								<div className="clearfix mb-md mt-xs text-right">
                                    {
										uploadedImages.length > 0 &&
										<span style={{ 'color': 'red' }} >Count of images to upload : {uploadedImages.length}</span>
									}
									{
										uploadedImages.length > 0 &&
										<button type="button" disabled={allowSave} className="orderDetailsAction finalizeIcon" onClick={this.saveImages}>Save</button>
									}
									{
										uploadedImages.length > 0 &&
										<button type="button" disabled={allowSave} className="orderDetailsAction clearIcon ml-md" onClick={this.clearAllTempImages}>Clear ALL</button>
									}
								</div>
								{
									uploadedImages.length > 0 &&
									<div className="firstAndLastNameImages flexElem">
										<input type="text" onChange={(e) => this.onChangeName(e)} value={firstName} name='firstName' placeholder='Enter First Name' className="form-control mr-sm" />
										<input type="text" onChange={(e) => this.onChangeName(e)} value={lastName} name='lastName' placeholder='Enter Last Name' className="form-control ml-sm" />
									</div>
								}

								{
									!order_details.editor_id
										?
										<React.Fragment>
											<span className="customUploader"></span>
											<FileUpload
												files={uploadedImages}
												setResourceFile={this.setResourceFile}
												updateResourceFile={this.updateResourceFile}
												allowedFormatsStringMimePro={allowedFormatsStringMime}
												allowedFormatsStringExtPro={allowedFormatsStringExt}
												askToUpdate={true}
												updateWithoutAsking={false}
												allowDuplicate={false}
												multiple={true}
											/>
										</React.Fragment>

										:
										TF.errorMsg("Can't upload now! Editor is assign now", "danger", s3StaticPath('img/critical_icon.png'), " flex-center align-items-center")
								}
								<ImagePreview
									filesToPreview={uploadedImages}
									removeFile={this.removeFile}
								/>
							</div>
						}
					/>
				}
				<ImagesList
					provideCtrlListing={ctrlList => this.ctrl_list = ctrlList}
					orderId={orderId}
					versionType='original'
					tabName={tabName}
					extraHtml={
						<div className="mt-sm">

							{
								Object.keys(allImagesByPhase.images).length > 0
								&&
                                <a href={`${baseUrl}downloadImagesOriginal/${order_details.id}`} target="_blank" className="orderDetailsAction downloadIcon ml-sm mt-lg display_ib">Download All Images</a>
								// <DownloadFile
								// 	title="Download All Images"
								// 	multiple={true}
								// 	files={allImagesByPhase.images}
								// 	downloadName={order_details.barcode}
								// />
							}
							{
								Number(order_details.settings.on_hold.value) !== 1 &&
								order_details.editor_id === null &&
								hasRole(['editor', 'admin', 'gm admin', 'Remote Editor','Remote Editor/Producer']) &&
								order_details.originalImages > 0 &&
								parseInt(order_details.settings.photos_renamed.value, 10) === 1 &&
								order_details.order_dates.receive_date.value != '' &&
								<OverlayTrigger placement="bottom" overlay={<Popover id="tooltip">{'Order assign me for editing process'}</Popover>}>
									<button className="orderDetailsAction assignIcon ml-md" onClick={() => this.assignEditorToOrder(orderId)} >Assign Me For Editing Process</button>
								</OverlayTrigger>
							}


						</div>
					}
				/>
			</div>
		);
	};
}

function mapStateToProps(state) {
	const { loadingOrder, allImagesByPhase, imageUpdates, orderImagePath } = state.ordersReducer;
	const { loading, userAdmin } = state.adminReducer;

	return {
		loadingOrder, allImagesByPhase, imageUpdates, orderImagePath,
		loading, userAdmin,
	};
}

const mapDispatchToProps = dispatch => {
	return ({
		getOrderCall: (orderId) => { dispatch(getOrder(orderId)) },
		allOrderImagesByPhaseCall: (orderId, version_type, tabName) => { dispatch(allOrderImagesByPhase(orderId, version_type, tabName)) },
		storeEditorOrderImagesCall: (imagesData, orderId, folder) => { return dispatch(storeEditorOrderImages(imagesData, orderId, folder)) },
		storeOrderImagesInDBCall: (imagesData, orderId, folder) => { return dispatch(storeOrderImagesInDB(imagesData, orderId, folder)) },
		getOrderImagesPathCall: (orderId, folder) => { return dispatch(getOrderImagesPath(orderId, folder)) },
		assignUserToOrderCall: (orderId, data) => { return dispatch(assignUserToOrder(orderId, data)) },
		showLoaderCall: (status) => { dispatch(showLoader(status)) },
	});
};

const UploadedImages = connect(mapStateToProps, mapDispatchToProps)(UploadedImagesPage);
export default UploadedImages;

import { adminConstants } from 'redux/constant/admin-constant';
import { alertActions } from './alert-actions';
import { history } from 'helper/history';
import { adminUserService } from 'services/adminUser';
import { generalActions } from './generalActions';

export function dashboardMenu() {
    return dispatch => {
        dispatch(request());
        adminUserService.dashboardMenu()
            .then(
                response => {
                    dispatch(generalActions.success(adminConstants.GET_MENU_DASHBOARD_SUCCESS, response));
                },
                error => {
                    dispatch(generalActions.failure(adminConstants.GET_MENU_DASHBOARD_FAILURE, error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: adminConstants.GET_MENU_DASHBOARD_REQUEST } }
}

export function login(username, password) {
    return dispatch => {
        dispatch(request({ username }));
        adminUserService.login(username, password)
            .then(
                user => {
                    dispatch(generalActions.success(adminConstants.LOGIN_ADMIN_REQUEST, user));
                    history.push('/Admin/dashboard');
                    // history.go(0);
                },
                error => {
                    dispatch(generalActions.failure(adminConstants.LOGIN_ADMIN_FAILURE, error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(username) { return { type: adminConstants.LOGIN_ADMIN_REQUEST, payload: username } }
}

export function logout() {
    return dispatch => {
        dispatch(request());
        adminUserService.logout()
            .then(
                response => {
                    dispatch(generalActions.success(adminConstants.LOGOUT_ADMIN_SUCCESS, response));
                    localStorage.removeItem('user');
                    localStorage.removeItem('searchParams');
                    history.push('/Admin/admin-login');
                },
                error => {
                    dispatch(generalActions.failure(adminConstants.LOGOUT_ADMIN_FAILURE, error));
                    dispatch(alertActions.error(error));
                }
            );
    };


    function request() { return { type: adminConstants.LOGOUT_ADMIN_REQUEST } }
}

export function dashboard() {
    return dispatch => {
        dispatch(request());
        adminUserService.dashboard()
            .then(
                response => {
                    dispatch(generalActions.success(adminConstants.GET_DASHBOARD_SUCCESS, response));
                    history.push('/Admin/dashboard');
                },
                error => {
                    dispatch(generalActions.failure(adminConstants.GET_DASHBOARD_FAILURE, error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: adminConstants.GET_DASHBOARD_REQUEST } }
}

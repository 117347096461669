import React, { Component } from "react";
import { connect } from 'react-redux';
import { getWebLog } from "redux/actions/report-actions";
import ReactValidator from "simple-react-validator";
import DatePicker from "react-datepicker";
import { Row, Col, Grid } from "react-bootstrap";
import { Card } from "components/Card/Card.jsx";
import 'react-table/react-table.css';
import { Link } from 'react-router-dom';
import Pagination from "../../ThemeComponents/Pagination";
import { hasRole } from "../../../helper/helperFunctions";
const moment = window.moment;

class WebLogPage extends Component {
	constructor(props) {
		super(props);
		this.dateValidator = new ReactValidator();
		var today = new Date(),
			date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
		date = '';
		const { id } = this.props.match.params;
		console.log('order-id', id);
		this.state = {
			fromDate: date,
			toDate: date,
			pageSize: 10,
			page: 0,
			sorted: '',
			order_id: id,
		}
		this.table = React.createRef();
	}
	DateChangeHandler = (value, field) => {
		this.setState({ [field]: value === null ? '' : moment(value).format("YYYY-MM-DD") }, () => {
			if (this.dateValidator.allValid()) {
				// this.pagination.dataCall();;
				this.pagination.dataCall();
			} else {
				this.dateValidator.showMessages();
				this.forceUpdate();
			}
		})
	}
	dateElement(label, name, value, rules) {
		var selectedDate = this.state[name] !== '' ? new Date(this.state[name]) : '';
		const { fromDate, toDate } = this.state;
		return (
			<div className='form-group fullCalendar' id={name}>
				<label>{label}</label>
				<DatePicker className="form-control calender_icon" dateFormat={hasRole(['gm admin']) ? 'dd/MM/yyyy' : 'MM/dd/yyyy'} selected={selectedDate} name={name} onChange={(event) => this.DateChangeHandler(event, name)} />
				{
					(fromDate !== '' && toDate !== '') &&
					this.dateValidator.message(name, value, rules)
				}
			</div>
		);
	}
	reportsPaginationData = (page = this.state.page, sorted = this.state.sorted, pageSize = this.state.pageSize) => {
		const { fromDate, toDate, order_id } = this.state;
		this.props.getReportsCall(order_id, fromDate, toDate, page, pageSize, sorted);
	}
	paginationCall = (data) => {
		const { fromDate, toDate, order_id } = this.state;
		return this.props.getReportsCall(order_id, fromDate, toDate, data.page, data.pageSize, data.sort);
	}
	render() {
		const { toDate, fromDate } = this.state;
		const { /*weblogData, REPORTSpages, loadingOrder,*/ REPORTStotal_orders } = this.props;
		const columns = [
			{
				Header: "Report Testing",
				headerClassName: 'headerPagination',
				columns: [
					{
						Header: "Barcode",
						width: 200,
						id: "model.barcode",
						accessor: "model",
						Cell: ({ value, original }) => {
							return <Link to={'/Admin/' + (value.reorder ? value.reorder : value.id) + '/order-edit'} className="blue_link">{value.barcode}</Link>
						},
						className: "action-center",
						sortable: false,
					},
					{
						Header: "Company",
						accessor: "model",
						Cell: ({ value }) => (
							<div>{value.agency.name}</div>
						),
						className: "action-center",
						sortable: false,
					},
					{
						Header: "Page Visited",
						accessor: "event",
						className: "action-center",
						sortable: false,
					},
					{
						Header: "Time",
						accessor: "created_at",
						Cell: ({ value }) => (
							<div>
								{moment(value).format('MMMM D Y')}
							</div>
						),
						className: "action-center",
						sortable: false,
					},
					{
						Header: "IP",
						accessor: "ip",
						className: "action-center",
						sortable: false,
					},

				]
			}
		];
		return (
			<div className="content">
				<Grid fluid>
					<Row>
						<Col md={12}>
							<Card
								ctTableResponsive
								content={
									<div>
										<Grid fluid>
											<div className="row">
												<Col md={12}>
													<div className="secHeading">GTV Weblog</div>
												</Col>

												<Col md={4} >
													<div className='row flexElem flexResponsive align-items-center' >
														<div className="col-md-6" >
															{
																this.dateElement('Date From', 'fromDate', fromDate !== '' && moment(fromDate, 'YYYY-MM-DD'), [{ before_or_equal: moment(this.state.toDate) }])
															}
														</div>
													</div>
												</Col>
												<Col md={4} >
													<div className='row flexElem flexResponsive align-items-center' >
														<div className="col-md-6" >
															{
																this.dateElement('Date To', 'toDate', toDate !== '' && moment(toDate, 'YYYY-MM-DD'), [{ after_or_equal: moment(this.state.fromDate) }])
															}
														</div>

													</div>
												</Col>
												<Col md={12}>
													<div>Total Records: {REPORTStotal_orders ? REPORTStotal_orders : 0}</div>
												</Col>
												{/* <Col md={12} className="mt-md">
													<ReactTable
														ref={this.table}
														noDataText='No web log report found'
														data={weblogData}
														pages={REPORTSpages}
														columns={columns}
														defaultPageSize={10}
														loading={loadingOrder}
														className="-striped listing"
														loadingText={'Loading...'}
														pageData={this.reportsPaginationData}
														manual
														onFetchData={(state, instance) => {

															var sort = state.sorted.length === 0 ? '' : state.sorted[0].id + ',desc:' + state.sorted[0].desc;
															state.pageData(state.page + 1, sort, state.pageSize);
														}}
													/>
												</Col> */}
												<Pagination
													ref={(p) => this.pagination = p}
													showPagination={false}
													columns={columns}
													pageSize={10}
													noDataText='No web log report found'
													getDataCall={this.paginationCall}
													filterView={false}
													// downloadData={true}
													// downloadFileName={'Orders'}
													// lowerContent = {null}
													// filterPlaceHolder = {'Orders'}
													getRowProps={this.getRowProps}
												// showAllToggle={true}
												/>
											</div>
										</Grid>
									</div>
								} />
						</Col>
					</Row>
				</Grid>
			</div>
		);
	}
}

const mapDispatchToProps = dispatch => {
	return ({
		getReportsCall: (value, fromDate, toDate, page, pageSize, sorted) => { return dispatch(getWebLog(value, fromDate, toDate, page, pageSize, sorted)) },//example function
		// success: (response) => { return dispatch({ type: 'GET_ALL_WEB_LOG_SUCCESS', payload: response }) }
	});
};

function mapStateToProps(state) {
	const { weblogData, REPORTSpages, REPORTScurrent_page, REPORTStotal_orders, loadingOrder } = state.reportReducer;
	return {
		weblogData, REPORTSpages, REPORTScurrent_page, REPORTStotal_orders, loadingOrder
	};
}


const WebLog = connect(mapStateToProps, mapDispatchToProps)(WebLogPage);
export default WebLog;
import React, { Component } from 'react'
import { Dashboard } from 'layouts/Frontend/Dashboard/Dashboard'
import { Link } from 'react-router-dom'

class ThanksConfirmation extends Component {

    constructor(props) {
        super(props);
        this.state = {
          barcode: this.props.location.state ? this.props.location.state.barcode : '',
        };
    }
    componentDidMount() {
        if (this.props.location.state === undefined) {
            this.props.history.push('/dashboard');
        }
    }

    render() {
        const { barcode } = this.state;
        return (
            <div className="container pb-xlg text-center">
                <div className="row">
                    <div className="col-md-12">
                        <h2 className="panel_heading userLogin_heading">Thank you for your order!</h2>
                        <h2 className="panel_heading userLogin_heading text-muted mb-md">your order confirmation # is {barcode}</h2>
                        <p>We appreciate your business and look forward to producing your traveler's Keepsake Videos. <strong>You will receive a confirmation email containing all your order information.</strong> If you do not see the email, please check your spam or junk folder as sometimes emails are mistakenly placed there.</p>
                        <p>If you have any questions or concerns, please <Link target='_blank' className='blue_link' to='/Contact_Us'>Contact Us.</Link></p>
                    </div>
                </div>
            </div>
        )
    }
}

export default (Dashboard(ThanksConfirmation))

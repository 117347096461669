import { adminConstants } from '../constant/admin-constant';
// import { objectsConstants as OC } from '../constant/objects-constant';

const initialState = {
	loadingSettings: false,
	countries: [],
	primaryStates: [],
	secondaryStates: [],
	states: [],
	programType: [],
	agency_type_id: [],
	agencySalesRep:[],
	salesRep: [],
	invoice_style_id: [],
	statesType: '',
	addtionalServcies: {},
	showLoader: true,
	uiSettings: {
		itemCategory: [],
		globalSetting: [],
		accountSetting: [],
	},
	orderStatuses: [],
	UserSearches:[],
	agencyUserSearches:[],
    agencyDefaultPreferences: [],
	// invLocations:[],
	/*UserSearches:OC.USERPREFERENCES, additionalFilters:OC.ADITIONALFILTERS, advanceSearch:OC.ADVANCESEARCH*/
};

export function settingsReducer(state = initialState, action) {

	switch (action.type) {

		case adminConstants.GET_AGENCY_TYPE_REQUEST:
			return { ...state, loadingSettings: true };
		case adminConstants.GET_AGENCY_TYPE_SUCCESS:
			return { ...state, loadingSettings: false, agency_type_id: action.payload && action.payload.agencyTypes ? action.payload.agencyTypes : initialState.agency_type_id };
		case adminConstants.GET_AGENCY_TYPE_FAILURE:
			return { ...state, loadingSettings: false, error: action.payload };

		case adminConstants.GET_COUNTRIES_REQUEST:
			return { ...state, loadingSettings: true };
		case adminConstants.GET_COUNTRIES_SUCCESS:
			// return { ...state, loadingSettings: false, countries: action.payload.countries };
			return { ...state, loadingSettings: false, countries: action.payload && action.payload.countries ? action.payload.countries : initialState.countries};
		case adminConstants.GET_COUNTRIES_FAILURE:
			return { ...state, loadingSettings: false, error: action.payload };

		case adminConstants.GET_PRIMARY_STATES_REQUEST:
			return { ...state, loadingSettings: true };
		case adminConstants.GET_PRIMARY_STATES_SUCCESS:
			return { ...state, loadingSettings: false, primaryStates: action.payload.states };
		case adminConstants.GET_PRIMARY_STATES_FAILURE:
			return { ...state, loadingSettings: false, error: action.payload };

		case adminConstants.GET_SECONDARY_STATES_REQUEST:
			return { ...state, loadingSettings: true };
		case adminConstants.GET_SECONDARY_STATES_SUCCESS:
			return { ...state, loadingSettings: false, secondaryStates: action.payload.states };
		case adminConstants.GET_SECONDARY_STATES_FAILURE:
			return { ...state, loadingSettings: false, error: action.payload };

		case adminConstants.GET_STATES_REQUEST:
			return { ...state, loadingSettings: true };
		case adminConstants.GET_STATES_SUCCESS:
			return { ...state, loadingSettings: false, states: action.payload.data.states, statesType: action.payload.data.type };
		case adminConstants.GET_STATES_FAILURE:
			return { ...state, loadingSettings: false, error: action.payload };

		case adminConstants.GET_SALES_REP_REQUEST:
			return { ...state, loadingSettings: true };
		case adminConstants.GET_SALES_REP_SUCCESS:
			return { ...state, loadingSettings: false, salesRep: action.payload.user };
		case adminConstants.GET_SALES_REP_FAILURE:
			return { ...state, loadingSettings: false, error: action.payload };

		case adminConstants.GET_AGENCY_SALES_REP_REQUEST:
			return { ...state, loadingSettings: true };
		case adminConstants.GET_AGENCY_SALES_REP_SUCCESS:
			return { ...state, loadingSettings: false, agencySalesRep: action.payload.user };
		case adminConstants.GET_AGENCY_SALES_REP_FAILURE:
			return { ...state, loadingSettings: false, error: action.payload };

		case adminConstants.GET_PROGRAM_TYPE_REQUEST:
			return { ...state, loadingSettings: true };
		case adminConstants.GET_PROGRAM_TYPE_SUCCESS:
			return { ...state, loadingSettings: false, programType: action.payload && action.payload.programTypes ? action.payload.programTypes : initialState.programType };
		case adminConstants.GET_PROGRAM_TYPE_FAILURE:
			return { ...state, loadingSettings: false, error: action.payload };


		case adminConstants.GET_MAIN_PROGRAM_TYPE_REQUEST:
			return { ...state, loadingSettings: true };
		case adminConstants.GET_MAIN_PROGRAM_TYPE_SUCCESS:
			return { ...state, loadingSettings: false, mainProgramType: action.payload && action.payload.mainProgramType && action.payload.mainProgramType };
		case adminConstants.GET_MAIN_PROGRAM_TYPE_FAILURE:
			return { ...state, loadingSettings: false, error: action.payload };

		case adminConstants.GET_PROGRAM_ITEM_REQUEST:
			return { ...state, loadingSettings: true };
		case adminConstants.GET_PROGRAM_ITEM_SUCCESS:
			return { ...state, loadingSettings: false, programItems: action.payload.items };
		case adminConstants.GET_PROGRAM_ITEM_FAILURE:
			return { ...state, loadingSettings: false, error: action.payload };

		case adminConstants.GET_INVOICING_REQUEST:
			return { ...state, loadingSettings: true };
		case adminConstants.GET_INVOICING_SUCCESS:
			return { ...state, loadingSettings: false, invoice_style_id: action.payload.invoiceStyles };
		case adminConstants.GET_INVOICING_FAILURE:
			return { ...state, loadingSettings: false, error: action.payload };

		case adminConstants.GET_SETTING_VALUE_REQUEST:
			return { ...state, loadingSettings: true };
		case adminConstants.GET_SETTING_VALUE_SUCCESS:
			return { ...state, loadingSettings: false, settings: action.payload.settings };
		case adminConstants.GET_SETTING_VALUE_FAILURE:
			return { ...state, loadingSettings: false, error: action.payload };

		case adminConstants.GET_SETTING_VALUE_GM_REQUEST:
			return { ...state, loadingSettings: true };
		case adminConstants.GET_SETTING_VALUE_GM_SUCCESS:
			return { ...state, loadingSettings: false, settings: action.payload.settings };
		case adminConstants.GET_SETTING_VALUE_GM_FAILURE:
			return { ...state, loadingSettings: false, error: action.payload };

		case adminConstants.GET_USER_PREFERENCE_REQUEST:
			return { ...state, loadingSettings: true };
		case adminConstants.GET_USER_PREFERENCE_SUCCESS:
			return { ...state, loadingSettings: false, UserSearches: action.payload && action.payload.UserSearches ? action.payload.UserSearches : initialState.UserSearches };
		case adminConstants.GET_USER_PREFERENCE_FAILURE:
			return { ...state, loadingSettings: false, error: action.payload };

		case adminConstants.GET_AGENCY_USER_PREFERENCE_REQUEST:
			return { ...state, loadingSettings: true };
		case adminConstants.GET_AGENCY_USER_PREFERENCE_SUCCESS:
			return { ...state, loadingSettings: false, agencyUserSearches: action.payload && action.payload.agencyUserSearches ? action.payload.agencyUserSearches : initialState.agencyUserSearches };
		case adminConstants.GET_AGENCY_USER_PREFERENCE_FAILURE:
			return { ...state, loadingSettings: false, error: action.payload };

		case adminConstants.GET_SETTING_BY_MODEL_ID_REQUEST:
			return { ...state, loadingSettings: true };
		case adminConstants.GET_SETTING_BY_MODEL_ID_SUCCESS:
			return { ...state, loadingSettings: false, settings: action.payload.settings };
		case adminConstants.GET_SETTING_BY_MODEL_ID_FAILURE:
			return { ...state, loadingSettings: false, error: action.payload };

		case adminConstants.STORE_SETTINGS_DATA_REQUEST:
			return { ...state, loadingSettings: true };
		case adminConstants.STORE_SETTINGS_DATA_SUCCESS:
			return { ...state, loadingSettings: false, storedSetting: action.payload.storedSetting };
		case adminConstants.STORE_SETTINGS_DATA_FAILURE:
			return { ...state, loadingSettings: false, error: action.payload };

		case adminConstants.UPDATE_SETTINGS_DATA_REQUEST:
			return { ...state, loadingSettings: true };
		case adminConstants.UPDATE_SETTINGS_DATA_SUCCESS:
			return { ...state, loadingSettings: false, updatedSetting: action.payload.settings };
		case adminConstants.UPDATE_SETTINGS_DATA_FAILURE:
			return { ...state, loadingSettings: false, error: action.payload };
		case adminConstants.CLEAR_SETTINGS_REQUEST:
			return { ...initialState };
		case adminConstants.GET_ADDITIONAL_SERVICES_SUCCESS:
			return {
				...state,
				addtionalServcies: action.payload.data
			}
		case adminConstants.GET_SINGLE_ADDITIONAL_ITEM_SUCCESS:
			return {
				...state,
				singleAdditionalService: action.payload.data
			}
		case adminConstants.GET_SINGLE_ADDITIONAL_ITEM_FAILURE:
			return {
				...state,
				error: action.payload
			}
		case adminConstants.UPDATE_SINGLE_ADDITIONAL_ITEM_SUCCESS:
			const data = [...state.addtionalServcies.data];
			data[action.index] = action.payload.data;
			return {
				...state,
				addtionalServcies: {
					...state.addtionalServcies,
					data: data
				}
			}
		case adminConstants.UPDATE_SINGLE_ADDITIONAL_ITEM_FAILURE:
			return {
				...state,
				error: action.payload
			}
		case adminConstants.DELETE_SINGLE_ADDITIONAL_ITEM_SUCCESS:
			return {
				...state,
				addtionalServcies: {
					...state.addtionalServcies,
					data: action.payload
				}
			}
		case adminConstants.DELETE_SINGLE_ADDITIONAL_ITEM_FAILURE:
			return {
				...state,
				error: action.payload
			}
		case adminConstants.SET_GLOBAL_LOADER:
			return { ...state, showLoader: action.payload };

		case adminConstants.GET_ITEM_CATEGORY_REQUEST:
			return { ...state, loadingSettings: true };
		case adminConstants.GET_ITEM_CATEGORY_SUCCESS:
			return { ...state, loadingSettings: false, itemCategories: action.payload.itemCategories };
		case adminConstants.GET_ITEM_CATEGORY_FAILURE:
			return { ...state, loadingSettings: false, error: action.payload };

		case adminConstants.GET_ITEM_TYPE_REQUEST:
			return { ...state, loadingSettings: true };
		case adminConstants.GET_ITEM_TYPE_SUCCESS:
			return { ...state, loadingSettings: false, itemTypes: action.payload.itemTypes };
		case adminConstants.GET_ITEM_TYPE_FAILURE:
			return { ...state, loadingSettings: false, error: action.payload };

		case adminConstants.GET_INV_LOCATIONS_REQUEST:
			return { ...state, loadingSettings: true, loadingInvLocations: true };
		case adminConstants.GET_INV_LOCATIONS_SUCCESS:
			return { ...state, loadingSettings: false, loadingInvLocations: false, invLocations: action.payload.inventoryLocations };
		case adminConstants.GET_INV_LOCATIONS_FAILURE:
			return { ...state, loadingSettings: false, error: action.payload, loadingInvLocations: false };

		case adminConstants.GET_DESTINATIONS_REQUEST:
			return { ...state, loadingSettings: true, loadingDestinationsSettings: true };
		case adminConstants.GET_DESTINATIONS_SUCCESS:
			return { ...state, loadingSettings: false, loadingDestinationsSettings: false, destinations: action.payload.getDestinations };
		case adminConstants.GET_DESTINATIONS_FAILURE:
			return { ...state, loadingSettings: false, error: action.payload, loadingDestinationsSettings: false };


		case adminConstants.IMAGE_PROCESSING_DOWNLOAD_FILE_REQUEST:
			return { ...state, loadingSettings: true };
		case adminConstants.IMAGE_PROCESSING_DOWNLOAD_FILE_SUCCESS:
			return { ...state, loadingSettings: false };
		case adminConstants.IMAGE_PROCESSING_DOWNLOAD_FILE_FAILURE:
			return { ...state, loadingSettings: false };

		case adminConstants.GET_RANGES_REQUEST:
			return { ...state, loadingSettings: true };
		case adminConstants.GET_RANGES_SUCCESS:
			return { ...state, loadingSettings: false, ranges: action.payload.ranges };
		case adminConstants.GET_RANGES_FAILURE:
			return { ...state, loadingSettings: false, error: action.payload };

		case adminConstants.GET_UI_SETTINGS_REQUEST:
			return { ...state, loadingSettings: true };
		case adminConstants.GET_UI_SETTINGS_SUCCESS:
			return { ...state, loadingSettings: false, uiSettings: action.payload.data };
		case adminConstants.GET_UI_SETTINGS_FAILURE:
			return { ...state, loadingSettings: false, error: action.payload };
		case adminConstants.GET_ORDER_STATUSES_SUCCESS:
			return {
				...state,
				orderStatuses: action.payload && action.payload.data ? action.payload.data : initialState.orderStatuses
			}

		case adminConstants.GET_DEFAULT_USER_PREFERENCE_REQUEST:
			return { ...state, loadingSettings: true };
		case adminConstants.GET_DEFAULT_USER_PREFERENCE_SUCCESS:
			return { ...state, loadingSettings: false, defaultPreferences: action.payload && action.payload.defaultPreferences && action.payload.defaultPreferences };
		case adminConstants.GET_DEFAULT_USER_PREFERENCE_FAILURE:
			return { ...state, loadingSettings: false, error: action.payload };

		case adminConstants.GET_AGENCY_DEFAULT_USER_PREFERENCE_REQUEST:
			return { ...state, loadingSettings: true };
		case adminConstants.GET_AGENCY_DEFAULT_USER_PREFERENCE_SUCCESS:
			return { ...state, loadingSettings: false, agencyDefaultPreferences: action.payload && action.payload.agencyDefaultPreferences && action.payload.agencyDefaultPreferences };
		case adminConstants.GET_AGENCY_DEFAULT_USER_PREFERENCE_FAILURE:
			return { ...state, loadingSettings: false, error: action.payload };

		case adminConstants.STORE_PREFERENCE_REQUEST:
			return { ...state, loadingSettings: true };
		case adminConstants.STORE_PREFERENCE_SUCCESS:
			return { ...state, loadingSettings: false};
		case adminConstants.STORE_PREFERENCE_FAILURE:
			return { ...state, loadingSettings: false, error: action.payload };

		case adminConstants.STORE_AGENCY_PREFERENCE_REQUEST:
			return { ...state, loadingSettings: true };
		case adminConstants.STORE_AGENCY_PREFERENCE_SUCCESS:
			return { ...state, loadingSettings: false};
		case adminConstants.STORE_AGENCY_PREFERENCE_FAILURE:
			return { ...state, loadingSettings: false, error: action.payload };

		case adminConstants.GET_INVOICING_PERIOD_REQUEST:
			return { ...state, loadingSettings: true };
		case adminConstants.GET_INVOICING_PERIOD_SUCCESS:
			return { ...state, loadingSettings: false, invoicePeriods: action.payload.invoicePeriods };
		case adminConstants.GET_INVOICING_PERIOD_FAILURE:
			return { ...state, loadingSettings: false, error: action.payload };

		default:
			return { ...state };
	}
}

import React, { Component } from 'react'
import { connect } from 'react-redux'
import OrderForm from './OrderForm/OrderForm';
import { Link } from 'react-router-dom'
import { Dashboard } from 'layouts/Frontend/Dashboard/Dashboard';
// import AgencyLogo from 'components/ThemeComponents/AgencyLogo';
import { orderService } from 'services/order';
import { getOrder } from 'redux/actions/order-actions';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import DownloadFile from 'components/ThemeComponents/downloadFile';
import { browserName, browserVersion } from "react-device-detect";
// import { s3StaticPath } from 'helper/helperFunctions';

var moment = require("moment");
class OrderSummary extends Component {
    changeStatus = (orderId, status) => {
        orderService.updateOrderStatusByTitle(orderId, status).then(response=>{
            this.props.getOrderCall(this.props.order_details.id);
        })
    }
    render() {
        console.log("browserName", browserName + " "  + browserVersion);
        const { order_details } = this.props;
        let resourcesArray = ['full_video', 'highlight_video', 'mosaic_file', /*'original_mosaic'*/];
        let orderProps = {notEditable:true};
        if (Number(order_details.settings.photos_submitted_for_video_production.value)) {
            orderProps.notChangeAble = true
        }
        return (
            <div className='container'>
                <h2 className="panel_heading userLogin_heading">Order Summary</h2>
                <div style={{ justifyContent: 'space-between', alignItems: 'center' }} className='flexElem'>
                    <div>
                        {/* {
                            Number(order_details.settings.finalize_by_qa.value)
                            ?
                                <Link target='_blank' className='blue_link' to={`/print-order-summary/${this.props.match.params.id}`}><i className='fa fa-print'></i> Print Summary</Link>
                            :
                                null
                        } */}
                        {
                            order_details.status.title === 'Cancelled' &&
                            <button onClick={()=>this.changeStatus(order_details.id, 'Approved')} className='themeBtn_14 ml-sm pt-sm pb-sm purple_gradient hover_dark' >Undo Cancel Order</button>
                        }
                        <span>
                        {/* {
                            (
                                'orderResources' in order_details && Number(order_details.settings.finalize_by_qa.value)
                            )
                            ?
                                Object.keys(order_details.orderResources).map((key) => {

                                    if (resourcesArray.includes(key)) {
                                        return (
                                            <OverlayTrigger key={order_details.orderResources[key].id} placement="left"
                                                overlay={<Tooltip key={order_details.orderResources[key].id} >{order_details.orderResources[key].resource_type.title}</Tooltip>}>
                                                <DownloadFile key={order_details.orderResources[key].id} extraClass={
                                                    order_details.orderResources[key].resource_type.title === "Full Video"
                                                    ?
                                                        " linkColor downloadVideo ml-sm"
                                                    :
                                                        (
                                                            order_details.orderResources[key].resource_type.title === "Highlight Video"
                                                            ?
                                                                " linkColor downloadHighlightVideo ml-sm"
                                                            :
                                                                (
                                                                    order_details.orderResources[key].resource_type.title === "Mozaix File"
                                                                    ?
                                                                        " linkColor downloadIconHeight ml-sm"
                                                                    :  ""
                                                                )
                                                        )
                                                    } files={{
                                                    'file_title': order_details.orderResources[key].file_title,
                                                    'file_path': order_details.orderResources[key].file_path
                                                }}
                                                title={order_details.orderResources[key].resource_type.title }
                                                space={'&nbsp;&nbsp;&nbsp;'}
                                                />
                                            </OverlayTrigger>
                                        )
                                    }


                                })
                            :
                                null
                        } */}
                        </span>
                        {/* {
                            (
                                moment(order_details.order_dates.departure_date.value).diff(moment.now(), 'days') <= 30
                                &&
                                !Number(order_details.settings.photos_submitted_for_video_production.value)
                                &&
                                order_details.status.title !== 'Cancelled'
                            )
                            ?
                                <div className="infoBox redBox mb-lg mt-md" style={{maxWidth:'550px'}}>This order is within the 30 days of departure or date has passed. Please <Link className="text-primary" to="/Contact_Us">Contact Us</Link> for assistance.</div>
                            :
                                null
                        } */}
                    </div>

                    <div log={console.log("difff", moment(order_details.order_dates.departure_date.value).diff(moment.now(), 'days'))}>


                    </div>
                </div>

                <OrderForm {...orderProps} formType='edit' id={this.props.match.params.id} />
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    const { order_details } = state.ordersReducer;
    return {
        order_details
    }
}


const mapDispatchToProps = dispatch => {
    return {
        getOrderCall: (orderId) => { dispatch(getOrder(orderId)) },
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard(OrderSummary))

import React, { Component } from "react";
import { connect } from 'react-redux';
import { getForcastReport } from "redux/actions/report-actions";
import ReactValidator from "simple-react-validator";
import DatePicker from "react-datepicker";
import { Row, Col, Grid } from "react-bootstrap";
import { Card } from "components/Card/Card.jsx";
import 'react-table/react-table.css';
import Pagination from "../../ThemeComponents/Pagination";
import { hasRole } from "../../../helper/helperFunctions";
const moment = window.moment;

class ForeCastPage extends Component {
	constructor(props) {
		super(props);
		this.dateValidator = new ReactValidator();
		this.state = {
			fromDate: '',
			to: '',
			pageSize: 10,
			page: 0,
			sorted: '',
		}
	}
	DateChangeHandler = (value, field) => {
		this.setState({ [field]: value === null ? '' : moment(value).format("YYYY-MM-DD") }, () => {
			if (this.dateValidator.allValid()) {
				// const { fromDate, to, page, pageSize, sorted } = this.state;
				this.pagination.dataCall();
			} else {
				this.dateValidator.showMessages();
				this.forceUpdate();
			}
		})
	}
	dateElement(label, name, value, rules) {
		var selectedDate = this.state[name] !== '' ? new Date(this.state[name]) : '';
		const { fromDate, to } = this.state;
		return (
			<div className='form-group fullCalendar' id={name}>
				<label>{label}</label>
				<DatePicker className="form-control calender_icon" dateFormat={hasRole(['gm admin']) ? 'dd/MM/yyyy' : 'MM/dd/yyyy'} selected={selectedDate} name={name} onChange={(event) => this.DateChangeHandler(event, name)} />
				{
					(fromDate !== '' && to !== '') &&
					this.dateValidator.message(name, value, rules)
				}
			</div>
		);
	}
	reportsPaginationData = (page = this.state.page, sorted = this.state.sorted, pageSize = this.state.pageSize) => {
		const { fromDate, to } = this.state;
		this.props.getReportsCall(1, fromDate, to, page, pageSize, sorted);
	}
	paginationCall = (data) => {
		const { fromDate, to } = this.state;
		return this.props.getReportsCall(1, fromDate, to, data.page, data.pageSize, data.sort);
	}
	render() {
		// const { reportsData, REPORTSpages, loadingOrder } = this.props;
		const columns = [
			{
				Header: "-",
				columns: [

					{
						Header: "Tour Operator Name",
						accessor: "agency_name",
						className: "action-center",
						sortable: false,
					}

				]
			},
			{
				Header: "Change",
				columns: [

					{
						Header: "#Orders",
						accessor: "changeOrders",
						className: "action-center",
						sortable: false,
					},
					{
						Header: "%",
						accessor: "percentageOrder",
						className: "action-center",
						sortable: false,
					},
					{
						Header: "#Videos",
						accessor: "changeVideos",
						className: "action-center",
						sortable: false,
					},
					{
						Header: "%",
						accessor: "percentageVideo",
						className: "action-center",
						sortable: false,
					},
					{
						Header: "Total $'s",
						accessor: "changeAmount",
						className: "action-center",
						sortable: false,
					},
					{
						Header: "%",
						accessor: "percentageTotal",
						className: "action-center",
						sortable: false,
					},

				]
			},
			{
				Header: "Prior Year",
				columns: [

					{
						Header: "#Orders",
						accessor: "orderPrev",
						className: "action-center",
						sortable: false,
					},
					{
						Header: "#Videos",
						accessor: "videoPrev",
						className: "action-center",
						sortable: false,
					},
					{
						Header: "Total $'s",
						accessor: "amountPrev",
						className: "action-center",
						sortable: false,
					},
					{
						Header: "%",
						accessor: "agency_name",
						className: "action-center",
						sortable: false,
					},

				]
			},

			{
				Header: "Current Year",
				columns: [

					{
						Header: "#Orders",
						accessor: "orderCurrent",
						className: "action-center",
						sortable: false,
					},
					{
						Header: "#Videos",
						accessor: "videoCurrent",
						className: "action-center",
						sortable: false,
					},
					{
						Header: "Total $'s",
						accessor: "amountCurent",
						className: "action-center",
						sortable: false,
					},
					{
						Header: "%",
						accessor: "agency_name",
						className: "action-center",
						sortable: false,
					},

				]
			},
		];
		return (
			<div className="content">
				<Grid fluid>
					<Row>
						<Col md={12}>
							<Card
								ctTableResponsive
								content={
									<div>
										<Grid fluid>
											<div className="row">
												<Col md={12}>
													<div className="secHeading">Sales Forecast</div>
												</Col>
												<Col md={12} className="mt-md">
													{/* <ReactTable
														noDataText='No report found'
														showPagination={false}
														data={reportsData}
														pages={REPORTSpages}
														columns={columns}
														defaultPageSize={10}
														loading={loadingOrder}
														className="-striped listing"
														loadingText={'Loading...'}
														pageData={this.reportsPaginationData}
														manual
														onFetchData={(state, instance) => {

															var sort = state.sorted.length === 0 ? '' : state.sorted[0].id + ',desc:' + state.sorted[0].desc;
															state.pageData(state.page + 1, sort, state.pageSize);
														}}
													/> */}
													<Pagination
														ref={(p) => this.pagination = p}
														showPagination={false}
														noDataText='No report found'
														columns={columns}
														pageSize={10}
														getDataCall={this.paginationCall}
														filterView={false}
														// downloadData={true}
														// downloadFileName={'Orders'}
														// lowerContent = {null}
														// filterPlaceHolder = {'Orders'}
														getRowProps={this.getRowProps}
													// showAllToggle={true}
													/>
												</Col>

											</div>
										</Grid>
									</div>
								} />
						</Col>
					</Row>
				</Grid>
			</div>
		);
	}
}

const mapDispatchToProps = dispatch => {
	return ({
		getReportsCall: (value, fromDate, toDate, page, pageSize, sorted) => { return dispatch(getForcastReport(value, fromDate, toDate, page, pageSize, sorted)) },//example function
	});
};

function mapStateToProps(state) {
	const { reportsData, REPORTSpages, REPORTScurrent_page, REPORTStotal_orders, loadingOrder } = state.reportReducer;
	return {
		reportsData, REPORTSpages, REPORTScurrent_page, REPORTStotal_orders, loadingOrder
	};
}


const ForeCast = connect(mapStateToProps, mapDispatchToProps)(ForeCastPage);
export default ForeCast;
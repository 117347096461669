import React, { Component, refs } from 'react'
import { connect } from 'react-redux'
import { getLPRData, updateOrderDate } from 'redux/actions/order-actions'
import MessageCom from 'layouts/Dashboard/Message'
import { getCommentsByColumn } from 'redux/actions/comment-actions'
import MyImage from 'components/Upload/MyImage';
import parse from 'html-react-parser';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { clean, dateFormatMy, s3StaticPath } from 'helper/helperFunctions';
import { repeat } from 'lodash'


class printLPR extends Component {
    constructor(props) {
        super(props);
        this.state = {
            not_sending_dvds: false,
            dvds_quantity: false,
            is_additional_services: false
        }
        this.props.getLPR(this.props.match.params.id);
        this.clean = clean.bind(this);
        this.props.getCommentsByColumn({
            column: 'commentCategory.code',
            where: ['Admin', 'Production', 'PreTripMaterialsShip', 'DVD_MozaixShip'],
            order_id:this.props.match.params.id,
        });
    }

    componentDidUpdate(prevProps, prevState) {

        if (this.props.LPR_Data.order_details.order_dates.receive_date.value === null) {
            const receive_date = this.props.LPR_Data.order_details.order_dates.receive_date;
            if (receive_date.value === null) {
                receive_date.value = new Date();
                this.props.updateOrderDate(this.props.match.params.id, receive_date)
            }
        }

        if (typeof prevProps.LPR_Data.order_details.settings.print_lpr !== 'undefined') {
            if (this.props.LPR_Data.order_details.settings.print_lpr === prevProps.LPR_Data.order_details.settings.print_lpr) {
                if (this.props.LPR_Data.order_details.settings.print_lpr.updated_at && this.props.LPR_Data.order_details.settings.print_lpr.updated_by_user && this.props.LPR_Data.order_details.settings.print_lpr.value === '1') {
                    confirmAlert({
                        title: `Print LPR`,
                        message: `Warning: Order Received/LPR Already Generated On ${dateFormatMy(this.props.LPR_Data.order_details.settings.print_lpr.updated_at)} by ${this.props.LPR_Data.order_details.settings.print_lpr.updated_by_user.name}. Make sure you are not about to make more than one for production!`,
                        buttons: [
                            {
                                label: 'Ok'
                            }
                        ]
                    });
                }
            }

        }
        if (typeof prevProps.LPR_Data.order_details.settings.send_gl_fd_copy !== 'undefined') {

            if (this.props.LPR_Data.order_details.settings.send_gl_fd_copy === prevProps.LPR_Data.order_details.settings.send_gl_fd_copy) {

                if ((Number(this.props.LPR_Data.order_details.settings.send_gl_fd_copy.value) === 1 ) || Number(this.props.LPR_Data.order_details.settings.no_gl_copy.value) === 1 ) {
                    if ( this.state.not_sending_dvds === false) {
                        this.setState ({
                            not_sending_dvds : true
                        })
                    }
                }
            }
        }

        if ( this.props.LPR_Data.pricing_details !== prevProps.LPR_Data.pricing_details) {
            const {pricing_details} = this.props.LPR_Data;
            if (
                (pricing_details && pricing_details.order_items['Videos/Travelers'] &&
                    pricing_details.order_items['Videos/Travelers'].quantity > 0 ||
                    pricing_details.free_additional_dvds_to_client > 0 ||
                    pricing_details.free_additional_dvds_to_agency > 0
                )
            ) {
                if (this.state.dvds_quantity === false) {
                    this.setState({
                        dvds_quantity : true
                    })
                }
            }
        }
        if ( this.props.LPR_Data.pricing_details !== prevProps.LPR_Data.pricing_details) {
            const {pricing_details} = this.props.LPR_Data;
            Object.keys(pricing_details.order_items).map(orderItem => {

                    if ( (pricing_details.order_items[orderItem].item && Number(pricing_details.order_items[orderItem].item.show_on_order_form) !== 1 ) ) {
                        if (this.state.is_additional_services === false) {
                            this.setState({
                                is_additional_services : true
                            })
                        }
                    }


            })
        }
    }
    // componentDidMount() {
    //     // le imgBG = document.createElement('img');


    // const canva = document.getElementById('canvas');
    // const ctx = canva.getContext('2d');
    // var image = new Image();
    // image.src = s3StaticPath('img/GTV_ReorderForm.jpg');
    // image.crossOrigin = 'anonymous';
    // // const { relativeBox } = this.order_record.orderPoseCrop.crop;

    //     image.onload = () => {

    //         let imgWidth = image.width;
    //         let imgHeight = image.height;
    //         // image.width = image.width /2;
    //         // image.height = image.height /2;

    //         canva.setAttribute('width', image.width);
    //         canva.setAttribute('height', image.height);
    //         ctx.drawImage(
    //             image,
    //             10, 10, image.width, image.height
    //         );

    //         ctx.fillText("Hello World",170,50);

    //     }

    //     // var img = canvas.toDataURL();


    //     // const canvas = this.refs.canvas
    //     // // const ctx = canvas.getContext("2d")
    //     // const img = this.refs.image
    //     // ctx.fillText("Hello World",170,50);
    //     // let imgBG = document.createElement('img');
    //     // imgBG.src = '"'+s3StaticPath('img/GTV_ReorderForm.jpg')+'"';

    //     // ctx.drawImage(imgBG, 10, 10);
    //     // <img ref="image" src={s3StaticPath('img/GTV_ReorderForm.jpg')}  />
    //   }
    render() {
        const { not_sending_dvds, dvds_quantity, is_additional_services } = this.state;
        const { LPR_Data, comments } = this.props;
        const { order_details, shipping_details, pricing_details, production_details } = LPR_Data;
        const {video_shipping_address} = shipping_details;
        let agency_csv_value = '';
        agency_csv_value = order_details && order_details.agency && order_details.agency.primary_address && order_details.agency.primary_address.csv_value;
        let order_csv_value = '';
        order_csv_value = video_shipping_address.csv_value;
        let same_address = false;
        if (agency_csv_value === order_csv_value) {
            same_address = true;
        }
        let is_gm = false;
        is_gm = order_details.agency && order_details.agency.single_account &&
        order_details.agency.single_account.account.domain_slug === 'gm' ? true : false;
        // console.log('addddreesssss0', comments);
        // let address = '';
        // let match_order_address =  address.concat(video_shipping_address && video_shipping_address.street_address_1, video_shipping_address.street_address_2 ? video_shipping_address.street_address_2 : '',
        //                video_shipping_address.zipcode ? video_shipping_address.zipcode : '', video_shipping_address.city, video_shipping_address.state ? video_shipping_address.state : '',)

        // useEffect(() => {
        //     var c = document.getElementById("myCanvas");
        //     var ctx = c.getContext("2d");
        //     ctx.moveTo(0, 0);
        //     ctx.lineTo(200, 100);
        //     ctx.stroke();
        //     ctx.fillText("Hello World",170,50);
        //   }, []);


        return (
            <div className='container'>
                <MessageCom />
                <div className='row' style={{pageBreakAfter:'always'}}>
                    <div className='col-md-6'>
                        {
                            'on_hold' in order_details.settings && parseFloat(order_details.settings.on_hold.value) === 1 &&
                            <div className="orangeText">
                                <h1 className='font-weight-extra-bold'>ON HOLD</h1>
                                <p className='font-weight-extra-bold'>
                                    {
                                        <span>	This order was placed on hold on <strong>{dateFormatMy(order_details.settings.on_hold.updated_at)}</strong>

                                                {  order_details.settings.on_hold.updated_by_user ? ` by ${order_details.settings.on_hold.updated_by_user.name}` : null }
                                        </span>
                                        // <span>This order was placed on hold {order_details.settings.on_hold && order_details.settings.on_hold.updated_at ? 'at ' + dateFormatMy(order_details.settings.on_hold.updated_at)}</span>
                                        // ('description' in order_details.settings.on_hold && order_details.settings.on_hold.description) &&
                                        // parse(order_details.settings.on_hold.description.replace('ON_HOLD_DATE', dateFormatMy(order_details.settings.on_hold.updated_at)).replace('UPLOAD_CUT_OFF_DATE', dateFormatMy(order_details.order_dates.upload_cut_off_date.value)))
                                    }
                                </p>
                                <p className='font-weight-extra-bold'>
                                <span>Comment:
                                    {
                                        order_details.onHoldLastComment !== null &&
                                        ('comment' in order_details.onHoldLastComment && order_details.onHoldLastComment.comment) &&
                                        order_details.onHoldLastComment.comment
                                    //    <span>Comment: {order_details.onHoldLastComment.comment}</span>
                                    }
                                    </span>
                                </p>
                            </div>
                        }
                        {
                            order_details.agency.agency_logo &&
                            <div className='agencyLogoContainer'>
                                <MyImage src={order_details.agency.agency_logo.file_path} alt="" />
                            </div>
                        }
                                              {/* ----------------------- SecondaryLogo ----------------------------*/}
                        {
                            order_details.customer_slates_for_videos_id &&
                            order_details.agency_id !== order_details.customer_slates_for_videos_id
                                ?
                                order_details.customer_slates_for_videos.agency_logo !== undefined && order_details.customer_slates_for_videos.agency_logo !== null
                                    ?
                                    <div className='agencyLogoContainer'>
                                        <MyImage src={order_details.customer_slates_for_videos.agency_logo.file_path} alt="secondary logo" className="agencyLogo secondaryLogo" />
                                    </div>
                                    :
                                    <MyImage src={s3StaticPath('img/placeholder.png')} className="agencyLogo secondaryLogo" alt="secondary agency logo placeholder" width="50px" height="50px" />
                                :
                                null
                        }
                        <h4>Received: <span>{dateFormatMy(order_details.order_dates.receive_date.value)}</span></h4>
                        {/* <h4>Ship By: <span>{order_details.order_dates.finish_by_date.created_by ? order_details.order_dates.finish_by_date.created_by.name : '--'}</span></h4> */}
                        <h4>Ship By: <span style={{'fontWeight': 'bold'}}>{order_details.order_dates.finish_by_date.value ? dateFormatMy(order_details.order_dates.finish_by_date.value) : '--'}</span></h4>
                        {/* <h1>Titles Entered</h1> */}
                        <div className="mb-md">
                            <div className='well well-sm' id='video_shipping_address'>
                                <h6 className='text-uppercase'>video shipping address</h6>
                            </div>
                            <address>
                            {
                                (shipping_details.video_shipping_address.country.title === 'France')
                                ?
                                    <div className={same_address ? "lprRedText" : ""}>
                                        <p>{(agency_csv_value === order_csv_value) ? order_details.agency_sales_rep && order_details.agency_sales_rep.name : order_details.group_leader_contact && order_details.group_leader_contact.name }</p>
                                        <p>{shipping_details.video_shipping_address.street_address_1 ? shipping_details.video_shipping_address.street_address_1 : ''}

                                               { shipping_details.video_shipping_address.street_address_2
                                                ?
                                                ', ' + shipping_details.video_shipping_address.street_address_2
                                                : ''
                                                }
                                        </p>
                                        <p>{shipping_details.video_shipping_address.zipcode ? shipping_details.video_shipping_address.zipcode : ''} {shipping_details.video_shipping_address.city}{shipping_details.video_shipping_address.country.title ? ', ' + shipping_details.video_shipping_address.country.title : ''}</p>
                                        {/* <p>{shipping_details.video_shipping_address.country.title}</p> */}
                                    </div>
                                :
                                    <div className={same_address ? "lprRedText" : ""}>

                                        <p>{(agency_csv_value === order_csv_value) ? order_details.agency_sales_rep && order_details.agency_sales_rep.name : order_details.group_leader_contact && order_details.group_leader_contact.name }</p>
                                        <p>{shipping_details.video_shipping_address.street_address_1 ? shipping_details.video_shipping_address.street_address_1 : ''}

                                               { shipping_details.video_shipping_address.street_address_2
                                                ?
                                                ', ' + shipping_details.video_shipping_address.street_address_2
                                                : ''
                                                }
                                        </p>
                                        <p>{shipping_details.video_shipping_address.city ? shipping_details.video_shipping_address.city  +',' : ''} {shipping_details.video_shipping_address.state && shipping_details.video_shipping_address.state.code ? shipping_details.video_shipping_address.state.code : ''} {shipping_details.video_shipping_address.zipcode ? shipping_details.video_shipping_address.zipcode : ''}</p>
                                        <p>{shipping_details.video_shipping_address.country.title}</p>
                                    </div>
                            }
                            </address>
                            {
                                same_address &&
                                <span className="text-danger mb-md" >Videos are being sent to Agency</span>
                            }
                        </div>
                        {
                          shipping_details.order_shipping_items && shipping_details.order_shipping_items.length > 0 ?
                        //   'order_shipping_items' in shipping_details ?
                            <div id='orderInventory'>
                                <div className='well well-sm'>
                                    <h6 className='text-uppercase'>Order Inventory</h6>
                                </div>
                                <table className='table orderTable'>
                                    <tbody>
                                        {
                                            shipping_details.order_shipping_items.map(shippingItem => {
                                                return (
                                                    <tr key={shippingItem.id}>
                                                        <td>{this.clean(shippingItem.inventory.barcode, '')}</td>
                                                        <td>{shippingItem.item.title}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                            :''
                        }
                        <div>
                            <div className='well well-sm'>
                                {/* <h6 className='text-uppercase'>Music Options</h6> */}
                                <h6 className='text-uppercase'>Order Details</h6>
                            </div>
                            <table className="table orderTable">
                                    <tbody>
                                        {
                                            // ( 'Videos/Travelers' in pricing_details.order_items && 'quantity' in pricing_details.order_items['Videos/Travelers'] ) &&
                                            <tr>
                                                {/* <td className='text-capitalize'>Music: </td> */}
                                                <td  className={order_details.music_selection_criteria.includes('age_based') ? 'text-capitalize text-danger' : 'text-capitalize'}>Music:  {order_details.music_selection_criteria.replace(/_/g, ' ')}</td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                            {/* <p className={order_details.music_selection_criteria.includes('age_based') ? 'text-capitalize text-danger' : 'text-capitalize'} >
                              Music:  {order_details.music_selection_criteria.replace(/_/g, ' ')}
                            </p> */}
                                {/* <div className='flexElem'>
                                    <p className='text_14'>Uploaded Pics:
                                        <span className='font-weight-light'>{order_details.images_count}</span>
                                    </p>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <p className='text_14'>Audio/Video Clips:
                                        <span className='font-weight-light'>
                                            {
                                            order_details.orderResources && 'audio_video' in order_details.orderResources
                                                ?
                                                order_details.orderResources.audio_video.length
                                                :
                                                0
                                            }
                                        </span>
                                    </p>
                                </div> */}
                                <table className="table orderTable">
                                    <tbody>
                                        {
                                            // ( 'Videos/Travelers' in pricing_details.order_items && 'quantity' in pricing_details.order_items['Videos/Travelers'] ) &&
                                            <tr>
                                                <td className='text-capitalize lprFontBold'>Uploaded Pics: {order_details.originalImages}</td>
                                                <td className={order_details.order_resources_audio_video_count > 0 ? 'text-danger lprFontBold' : 'lprFontBold' }>Audio/Video Clips: {order_details.order_resources_audio_video_count}</td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                            {/* {
                                order_details.settings.non_uploaded_photos_received &&
                                <p className='text_14'>Non Uploaded Pics: <span className='font-weight-light'>{this.clean(order_details.settings.non_uploaded_photos_received.value)}</span></p>
                            } */}
                            <div className='mt-md'>
                                <strong>Production Comments</strong>
                                <table className='table orderTable'>
                                    <tbody>
                                        {
                                            comments && comments.length > 0 && comments.map(item => {
                                                return (
                                                    item.comment_category.code === 'Production' &&
                                                    <tr key={item.id}>
                                                        <td>{item.comment}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div className='mt-md'>
                                <strong>DVD Shipping Comments</strong>
                                <table className='table orderTable'>
                                    <tbody>
                                        {
                                            comments && comments.length > 0 && comments.map(item => {
                                                return (
                                                    item.comment_category.code === 'DVD_MozaixShip' &&
                                                    <tr key={item.id}>
                                                        <td>{item.comment}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                            {/* <div className='mt-md'>
                                <strong>Admin Comments</strong>
                                <table className='table orderTable'>
                                    <tbody>
                                        {
                                            comments && comments.length > 0 && comments.map(item => {
                                                return (
                                                    item.comment_category.code === 'Admin' &&
                                                    <tr key={item.id}>
                                                        <td>{item.comment}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div> */}

                            {/* <div className='mt-md'>
                                <strong>Shipping Comments</strong>
                                <table className='table orderTable'>
                                    <tbody>
                                        {
                                            comments && comments.length > 0 && comments.map(item => {
                                                return (
                                                    item.comment_category.code === 'PreTripMaterialsShip' &&
                                                    <tr key={item.id}>
                                                        <td>{item.comment}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div> */}
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='text-center mt-md'>
                            <MyImage src={`data:image/png;base64,${order_details.barcode_img}`} alt='barcode' />
                        </div>
                        <div className='mt-md'>
                            <table className='table orderTable'>
                                <tbody>
                                    <tr>
                                        <td>Company Name:</td>
                                        <td>{order_details.agency.name}</td>
                                    </tr>
                                    <tr>
                                        <td>Group Name:</td>
                                        <td>
                                            {order_details.group_name}
                                            {
                                                order_details.is_fto ?
                                                <span className="fto"><strong>&nbsp; FTO</strong></span>
                                                :
                                                ''
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Destination:</td>
                                        <td>{order_details.destinationText}</td>
                                    </tr>
                                    <tr>
                                        <td>Departure Date:</td>
                                        <td>{dateFormatMy(order_details.order_dates.departure_date.value)}</td>
                                    </tr>
                                    <tr>
                                        <td>Return Date:</td>
                                        <td>{dateFormatMy(order_details.order_dates.return_date.value)}</td>
                                    </tr>
                                    <tr>
                                        <td>Special Need Date:</td>
                                        <td>{dateFormatMy(order_details.order_dates.video_special_need_date.value)}</td>
                                    </tr>
                                    <tr>
                                        <td>Last Day of School:</td>
                                        <td>{dateFormatMy(order_details.order_dates.last_day_of_school.value)}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div id='DVDs'>
                            <div className="well well-sm">
                                <h6 className='text-uppercase'><span>Total # of Travelers: {pricing_details.order_items && pricing_details.order_items['Videos/Travelers'] && pricing_details.order_items['Videos/Travelers'].quantity && pricing_details.order_items['Videos/Travelers'].quantity}</span></h6>
                            </div>
                            {/* <table className="table orderTable">
                                <thead>
                                    {
                                        ( 'Videos/Travelers' in pricing_details.order_items && 'quantity' in pricing_details.order_items['Videos/Travelers'] ) &&
                                        <tr>
                                            <td className='td_heading'>Total # of Travelers: {pricing_details.order_items['Videos/Travelers'].quantity}</td>
                                            <td>{pricing_details.order_items['Videos/Travelers'].quantity}</td>
                                        </tr>
                                    }
                                </thead>
                            </table> */}
                            {/* {
                                ( 'Videos/Travelers' in pricing_details.order_items && 'quantity' in pricing_details.order_items['Videos/Travelers'] ) &&
                                // && 'quantity' in pricing_details.order_items['Video/Travlers']
                                <p className=''>Total # of Travelers: &nbsp;
                                    <span className=''>{pricing_details.order_items['Videos/Travelers'].quantity}</span>
                                </p>
                            } */}
                            {
                                ( 'send_gl_fd_copy' in order_details.settings && Number(order_details.settings.send_gl_fd_copy.value) === 1 )
                                 ?
                                 <p className='text-danger lprFontBold'>NO DVDs for travelers, send GL FD Copy</p>
                                 :
                                    ( 'no_gl_copy' in order_details.settings && Number(order_details.settings.no_gl_copy.value) === 1 )
                                    ?
                                    <p className='text-danger lprFontBold'>NO DVDs for travelers, NO GL FD Copy</p>
                                    :
                                    <p className='text-danger lprFontBold'>DVDs Will Be Shipped</p>
                            }
                            {
                                !not_sending_dvds
                                // not_sending_dvds || dvds_quantity
                                // false
                                ?
                                    <table className='table orderTable'>
                                        <thead>
                                            <tr>
                                                <td></td>
                                                <td className='td_heading'>DVD</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                // ( '20x30 PhotoMozaix' in pricing_details.order_items && 'quantity' in pricing_details.order_items['20x30 PhotoMozaix'] ) &&
                                                // ( 'send_gl_fd_copy' in order_details.settings && Number(order_details.settings.send_gl_fd_copy.value) === 1 ) ||
                                                // ( 'no_gl_copy' in order_details.settings && Number(order_details.settings.no_gl_copy.value) === 1 )
                                                not_sending_dvds
                                                ?

                                                    <tr>
                                                        <td className='td_heading'>Client</td>
                                                        {/* <td>{pricing_details.order_items['20x30 PhotoMozaix'].quantity}</td> */}
                                                        <td>0</td>
                                                    </tr>
                                                :
                                                    ( 'Videos/Travelers' in pricing_details.order_items && 'quantity' in pricing_details.order_items['Videos/Travelers'] ) &&
                                                    <tr>
                                                        <td className='td_heading'>Client</td>
                                                        {/* <td>{pricing_details.order_items['20x30 PhotoMozaix'].quantity}</td> */}
                                                        <td>{pricing_details.order_items['Videos/Travelers'].quantity}</td>
                                                    </tr>
                                            }
                                            <tr>
                                                <td className='td_heading'>Client*</td>
                                                <td>{pricing_details.free_additional_dvds_to_client}</td>
                                            </tr>
                                            <tr>
                                                <td className='td_heading'>Agency*</td>
                                                <td>{pricing_details.free_additional_dvds_to_agency}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                :
                                ''
                            }
                        </div>
                        <div id='Mosaics'>
                            <div className="well well-sm">
                                <h6 className='text-uppercase'>Mozaix</h6>
                            </div>
                            <table className='table orderTable'>
                                <thead>
                                    <tr>
                                        <td>Quantity</td>
                                        <td>Description</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        Object.keys(pricing_details.order_items).map(orderItem => {
                                            return (
                                                (orderItem.match(/Mozaix/g)) &&
                                                <tr key={orderItem}>
                                                    <td>{pricing_details.order_items[orderItem].quantity}</td>
                                                    <td>{pricing_details.order_items[orderItem].title}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                        {
                            is_additional_services &&
                            <div id='AdditionalServices'>
                                <div className="well well-sm">
                                    <h6 className='text-uppercase'>Additional services</h6>
                                </div>
                                        <table className='table orderTable'>
                                        <thead>
                                            <tr>
                                                <td className='td_heading'>Quantity</td>
                                                <td className='td_heading'>Description</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                Object.keys(pricing_details.order_items).map(orderItem => {
                                                    // (orderItem !== 'Videos/Travelers' && orderItem !== '20x30 Mosaic') &&
                                                    return (
                                                        (pricing_details.order_items[orderItem].item && Number(pricing_details.order_items[orderItem].item.show_on_order_form) !== 1 && Number(pricing_details.order_items[orderItem].quantity) >= 1) &&
                                                            <tr key={orderItem}>
                                                                <td>{pricing_details.order_items[orderItem].quantity}</td>
                                                                <td>{pricing_details.order_items[orderItem].title}</td>
                                                            </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                            </div>
                        }
                        <div id='Titles'>
                            <div className='well well-sm'>
                                <h6 className='text-uppercase'>Titles</h6>
                            </div>
                            <table className='table orderTable'>
                                <tbody>
                                    {
                                        Object.keys(production_details.video_title_templates).map(item => {
                                            return (
                                                <tr key={item}>
                                                    {/* <td>{production_details.video_title_templates[item].pivot.value}</td> */}
                                                    <td className={Number(production_details.video_title_templates[item].pivot.is_changed) ? 'text-danger' : null}>{production_details.video_title_templates[item].pivot.value}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                {/*<div style={{pageBreakBefore:'always',pageBreakAfter:'always'}}></div>

                 <div style={{ position: 'relative', width:'100%', height:'100vh'}}>
                    <canvas ref="canvas" id='canvas' width={'100%'} height={'100%'}  />
                </div> */}

                <div style={{ position: 'relative', paddingTop:'50px',width:'100%', height:'100vh'}}>

                    <div style={{position:'absolute',left:0,top:0, width:'100%',height:'100%'}}>
                    {
                        order_details.agency && order_details.agency.single_account &&
                         order_details.agency.single_account.account.domain_slug === 'gm' ?
                            <MyImage className='img-responsive' src={s3StaticPath('img/GM_ReorderForm.jpg')} alt="" />
                        :
                        <MyImage className='img-responsive' src={s3StaticPath('img/GTV_ReorderForm.jpg')} alt="" />
                    }
                    </div>
                    <div className='printText'>
                            <table style={{width:'100%',tableLayout:'fixed'}}>
                                <tbody>
                                    <tr>
                                        <td><strong>Username: {order_details.username}</strong></td>
                                        <td><strong>Password: {order_details.password}</strong></td>
                                    </tr>
                                </tbody>
                            </table>
                    </div>
                    
                    
                        
                    <div className={is_gm ? 'printBarcode top40' : 'printBarcode' } >

                        <table style={{width:'100%', tableLayout:'fixed'}}>
                            <tbody>
                                <tr>
                                    <td colspan={2}>
                                        <MyImage className='img-responsive barcodePrint' src={`data:image/png;base64,${order_details.barcode_img}`} alt="barcode" />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    {/* <div style={{position:'absolute',right:0,bottom:0, width:'5in',height:'100vh',background:'red'}}>
                        <span>Username: {order_details.username}</span>
                        <span className='ml-md'>Password: {order_details.password}</span>
                        <table className='mt-xlg' style={{ position: 'relative' }}>
                             <div style={{
                                position: 'absolute',
                                top: '34%',
                                right: '5%',
                                fontSize: '18px', color: '#2680be',
                                fontWeight: '600'
                            }}>

                             </div>
                            <MyImage className='img-responsive' src={s3StaticPath('img/GTV_ReorderForm.jpg')} alt="" />
                            <MyImage className='img-responsive barcodePrint' src={`data:image/png;base64,${order_details.barcode_img}`} alt="barcode" />
                        </table>
                    </div> */}
                </div>

            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { LPR_Data } = state.ordersReducer;
    const { comments } = state.commentReducer;
    console.log('LPR_Data', LPR_Data);
    return {
        LPR_Data,
        comments
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getLPR: (id) => { dispatch(getLPRData(id)) },
        getCommentsByColumn: (data) => { dispatch(getCommentsByColumn(data)) },
        updateOrderDate: (id, data) => { dispatch(updateOrderDate(id, data)) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(printLPR)

import React, { Component } from 'react'
import { connect } from 'react-redux'
import Validator from 'simple-react-validator'
import { getCountries, getStates } from 'redux/actions/settings-actions';
import { editAgency } from 'redux/actions/agency-actions';
import Select from 'react-select';
import $ from 'jquery'
import { helperFunctions as HF } from 'helper/helperFunctions';
// import phoneNumber from '../../../ThemeComponents/phoneNumber';
import PhoneNumber from "components/ThemeComponents/phoneNumber";
import CustomPhoneNumber from '../../../ThemeComponents/CustomPhoneNumber';
import { customStyles } from '../../../../helper/helperFunctions';


var cloneDeep = require('lodash.clonedeep');
class CompanyInfoForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      agency: this.props.agency,
      options: {
        statesprimary_address: [],
        statessecondary_address: [],
      },
      is_france: false,
      ptma_initial_country_France: false,
      vsa_initial_country_France: false,
      ptma_is_france: false,
      vsa_is_france: false,
    }
    this.Validator = new Validator();
    this.props.getCountriesCall();
    this.clean = HF.clean.bind(this);
    this.inputChangeHandler = HF.inputChangeHandler.bind(this);
  }

  setAddressStates = (type, statesData) => {
    this.setState(prevState => ({
      ...prevState, options: {
        ...prevState.options, ['states' + type]: statesData
      }
    }));
  }

  selectChangeHandler = (selected, action, type) => {

    if (selected.label === 'France' && action.name === 'primary_address.country_id') {
      this.setState({
        ptma_is_france: true
      }, () => {
        this.setState({
          ptma_initial_country_France: true
        })
      })
    }
    if (selected.label !== 'France' && action.name === 'primary_address.country_id') {
      this.setState({
        ptma_is_france: false
      }, () => {
        this.setState({
          ptma_initial_country_France: false
        })
      })

    }
    // ----------------------Secondary Adderess France Check------------------------------//

    if (selected.label === 'France' && action.name === 'secondary_address.country_id') {
      this.setState({
        vsa_is_france: true
      }, () => {
        this.setState({
          vsa_initial_country_France: true
        })
      })
    }
    if (selected.label !== 'France' && action.name === 'secondary_address.country_id') {
      this.setState({
        vsa_is_france: false
      }, () => {
        this.setState({
          vsa_initial_country_France: false
        })
      })

    }
    const container = action.name.split('.');
    this.setState(prevState => ({
      agency: {
        ...prevState.agency,
        [container[0]]: {
          ...prevState.agency[container[0]],
          [container[1]]: selected.value
        }
      }
    }), () => {
      if (type === 'country' && action.name === 'primary_address.country_id') {
        this.props.getStateCall(this.state.agency[container[0]].country_id, container[0]);
        let shipping_details = { ...this.state.agency };
        shipping_details['primary_address']['state_id'] = null;
        if (shipping_details.primary_address.state && shipping_details.primary_address.state) {
          shipping_details['primary_address']['state']['code'] = null;
          shipping_details['primary_address']['state']['id'] = null;
          shipping_details['primary_address']['state']['title'] = null;
        }
      }
      if (type === 'country' && action.name === 'secondary_address.country_id') {
        this.props.getStateCall(this.state.agency[container[0]].country_id, container[0]);
        let shipping_details = { ...this.state.agency };
        shipping_details['secondary_address']['state_id'] = null;
        if (shipping_details.secondary_address.state && shipping_details.secondary_address.state) {
          shipping_details['secondary_address']['state']['code'] = null;
          shipping_details['secondary_address']['state']['id'] = null;
          shipping_details['secondary_address']['state']['title'] = null;
        }
      }

    })
  }

  formSubmitHandler = () => {
    try {
      if (this.state.agency.primary_address.country.title === 'France') {
        this.Validator.fields['primary_address.state_id'] = true;
        this.Validator.errorMessages['primary_address.state_id'] = null;
      }
    } catch (e) {
      console.log("eeeeeee", e);
    }
    if (this.Validator.allValid()) {
      const agency = this.state.agency;
      // const data = {
      //     ...agency,
      //     primary_address: this.ctrl_PA.returnAddressData(),
      //     secondary_address: this.ctrl_SA.returnAddressData()
      // }
      const data = this.state.agency;
      this.props.updateAgencyCall(data, agency.id);
    } else {
      this.Validator.showMessages();
      this.forceUpdate();
    }
  }

  contactChangeHandler = (e) => {
    const { name, value } = e.target;
    // console.log('name', name, 'value' ,value);
    const agencyState = cloneDeep(this.state.agency.primary_contacts);
    agencyState[name] = value;
    // this.setState({
    //     agency: {
    //         ...this.state.agency, primary_contacts: agencyState
    //     }
    // });
    // console.log('this.state.agency', this.state.agency);
    this.setState(prevState => ({
      ...prevState, agency: {
        ...prevState.agency, primary_contacts: {
          ...prevState.agency.primary_contacts,
          [name]: value

        }
      }
    }));
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.agency !== this.props.agency) {
      this.setState({
        agency: this.props.agency
      })
    }

    if (this.props.states !== this.state.options['statesprimary_address'] && this.props.statesType === 'primary_address') {
      this.setAddressStates('primary_address', this.props.states);
    }

    if (this.props.states !== this.state.options['statessecondary_address'] && this.props.statesType === 'secondary_address') {
      this.setAddressStates('secondary_address', this.props.states);
    }

    if (this.props.agency.primary_address.country_id && this.props.agency.primary_address.country_id !== prevProps.agency.primary_address.country_id) {
      this.props.getStateCall(this.props.agency.primary_address.country_id, 'primary_address');
    }

    if (this.props.agency.secondary_address.country_id && this.props.agency.secondary_address.country_id !== prevProps.agency.secondary_address.country_id) {
      this.props.getStateCall(this.props.agency.secondary_address.country_id, 'secondary_address');
    }

    if ((prevProps.agency !== this.props.agency) && (this.props.agency && this.props.agency.primary_address && this.props.agency.primary_address.country && this.props.agency.primary_address.country.title && this.props.agency.primary_address.country.title === 'France')) {
      this.setState({
        ptma_initial_country_France: true
      })
    }
    if ((prevProps.agency !== this.props.agency) && (this.props.agency && this.props.agency.secondary_address && this.props.agency.secondary_address.country && this.props.agency.secondary_address.country.title && this.props.agency.secondary_address.country.title === 'France')) {
      this.setState({
        vsa_initial_country_France: true
      })
    }

    if ((prevState.ptma_is_france !== this.state.ptma_is_france) || (prevState.ptma_initial_country_France !== this.state.ptma_initial_country_France) ||
      (prevState.vsa_initial_country_France !== this.state.vsa_initial_country_France) || (prevState.vsa_is_france !== this.state.vsa_is_france)) {
      const tempValue = { ...this.state.agency };
      tempValue['primary_contacts']['phone1'] = this.state.agency.primary_contacts.phone1;
      this.setState({
        agency: tempValue
      }, () => {
        let event = new MouseEvent('click', {
          'bubbles': true,
          'cancelable': false
        })
        var node1 = document.getElementById('france_1');
        node1.dispatchEvent(event);
      });
    }

    if (this.props.submit && (this.props.submit !== prevProps.submit)) {
      this.formSubmitHandler();
    }
  }


  render() {
    const { countries, states } = this.props;
    const { agency, options, ptma_is_france, ptma_initial_country_France, vsa_is_france, vsa_initial_country_France } = this.state;
    const { primary_address, secondary_address } = agency;
    let account_agency_is_france = false;

    account_agency_is_france = ptma_initial_country_France || ptma_is_france || vsa_is_france || vsa_initial_country_France;

    return (
      <React.Fragment>
        <div className="form_tile">
          <div className="form_tile_heading">
            <img src={HF.s3StaticPath('img/gen_information.png')} alt="General Information" />
            <span>Company Information</span>
          </div>

          <div className="form_tile_content">
            <div className="flexElem respFlex alignCenter spaceBetween form_tile_row">
              <div className="col-md-4">
                Company Name*
              </div>
              <div className="col-md-8 text-primary">
                <input value={agency.name} onChange={this.inputChangeHandler} name='agency.name' type="text" placeholder="Company Name" className="form_tile_input block" />
                {this.Validator.message('company name', agency.name, 'required')}
              </div>
            </div>
            <div className="flexElem respFlex alignCenter spaceBetween form_tile_row">
              <div className="col-md-4">
                Primary Address*
              </div>
              <div className="col-md-8 text-primary">
                <input value={primary_address.street_address_1} onChange={this.inputChangeHandler} name='agency.primary_address.street_address_1' type="text" placeholder="Street Address #1" className="form_tile_input block" />
                {this.Validator.message('primary_address.street_address_1', primary_address.street_address_1, 'required')}
              </div>
            </div>

			<div className="flexElem respFlex alignCenter spaceBetween form_tile_row">
              <div className="col-md-4">
                Country*
              </div>
              <div className="col-md-8 text-primary">
                <Select
                  value={HF.returnLabelWithValue(primary_address.country_id, countries) || null}
                  onChange={(selected, action) => { this.selectChangeHandler(selected, action, 'country') }}
                  options={countries}
                  isSearchable={true}
                  isMulti={false}
                  name='primary_address.country_id'
                  placeholder={'Select Country'}
                  styles={customStyles()}
                />
                {this.Validator.message('primary_address.country_id', primary_address.country_id, 'required')}
              </div>
            </div>

            <div className="flexElem respFlex alignCenter spaceBetween form_tile_row">
              <div className="col-md-4">
                City*
              </div>
              <div className="col-md-8 text-primary">
                <input value={primary_address.city} onChange={this.inputChangeHandler} name='agency.primary_address.city' type="text" placeholder="City" className="form_tile_input block" />
                {this.Validator.message('primary_address.city', primary_address.city, 'required')}
              </div>
            </div>

            <div className="flexElem respFlex alignCenter spaceBetween form_tile_row">
              <div className="col-md-4">
                State*
              </div>
              <div className="col-md-8 text-primary">
                <Select
                  value={HF.returnLabelWithValue(primary_address.state_id, states) || null}
                  onChange={(selected, action) => { this.selectChangeHandler(selected, action, 'state') }}
                  options={states}
                  isSearchable={true}
                  isMulti={false}
                  name='primary_address.state_id'
                  placeholder={'Select State'}
                  styles={customStyles()}
                />
                {ptma_is_france || ptma_initial_country_France ? '' : this.Validator.message('primary_address.state_id', primary_address.state_id, 'required')}
              </div>
            </div>

            <div className="flexElem respFlex alignCenter spaceBetween form_tile_row">
              <div className="col-md-4">
                Zip*
              </div>
              <div className="col-md-8 text-primary">
                <div>
                  <input name='agency.primary_address.zipcode' onChange={(e) => { this.inputChangeHandler(e) }} value={primary_address.zipcode} type="text" placeholder="00000" className="form_tile_input block" />
                </div>
                {this.Validator.message('primary_address.zipcode', primary_address.zipcode, 'required')}
              </div>
            </div>

            <div className="flexElem respFlex alignCenter spaceBetween form_tile_row">
              <div className="col-md-4">
                Secondary Address
              </div>
              <div className="col-md-8 text-primary">
                <input value={this.clean(secondary_address.street_address_1)} name='agency.secondary_address.street_address_1' onChange={this.inputChangeHandler} type="text" placeholder="Street Address #1" className="form_tile_input block" />
              </div>
            </div>

            <div className="flexElem respFlex alignCenter spaceBetween form_tile_row">
              <div className="col-md-4">
                Country*
              </div>
              <div className="col-md-8 text-primary">
                <div className="flexElem flexThreeCols spaceBetween">
                  <Select
                    value={HF.returnLabelWithValue(secondary_address.country_id, countries) || null}
                    onChange={(selected, action) => { this.selectChangeHandler(selected, action, 'country') }}
                    options={countries}
                    isSearchable={true}
                    isMulti={false}
                    name='secondary_address.country_id'
                    placeholder={'Select Country'} styles={customStyles()}
                  />
                </div>
              </div>
            </div>

            <div className="flexElem respFlex alignCenter spaceBetween form_tile_row">
              <div className="col-md-4">
                City
              </div>
              <div className="col-md-8 text-primary">
                <input name='agency.secondary_address.city' onChange={this.inputChangeHandler} value={this.clean(secondary_address.city)} type="text" placeholder="City" className="form_tile_input block" />
              </div>
            </div>

            <div className="flexElem respFlex alignCenter spaceBetween form_tile_row">
              <div className="col-md-4">
                State*
              </div>
              <div className="col-md-8 text-primary">
                <div>
                  <Select
                    value={HF.returnLabelWithValue(secondary_address.state_id, options.statessecondary_address) || null}
                    onChange={(selected, action) => { this.selectChangeHandler(selected, action, 'state') }}
                    options={options.statessecondary_address}
                    isSearchable={true}
                    isMulti={false}
                    name='secondary_address.state_id'
                    placeholder={'Select State'} styles={customStyles()}
                  />
                </div>
              </div>
            </div>

            <div className="flexElem respFlex alignCenter spaceBetween form_tile_row">
              <div className="col-md-4">
                Zip*
              </div>
              <div className="col-md-8 text-primary">
                <input name='agency.secondary_address.zipcode' onChange={(e) => { this.inputChangeHandler(e) }} value={this.clean(secondary_address.zipcode)} type="text" placeholder="00000" className="form_tile_input block" />
              </div>
            </div>
            <div className="flexElem respFlex alignCenter spaceBetween form_tile_row">
              <div className="col-md-4">
                Website
              </div>
              <div className="col-md-8 text-primary">
                <input onChange={this.inputChangeHandler} name='agency.website' value={this.clean(agency.website)} type="text" placeholder="Website" className="form_tile_input block" />
              </div>
            </div>
            <div className="flexElem respFlex alignCenter spaceBetween form_tile_row">
              <div className="col-md-4">
                Phone*
              </div>
              <div className="col-md-8 text-primary">
                <div className="flexElem flexThreeCols spaceBetween">
                  <div>
                    <CustomPhoneNumber
                      value={agency && agency.primary_contacts && agency.primary_contacts.phone1}
                      handleParent={this.contactChangeHandler}
                      validationName={'primaryUserContact'}
                      validator={this.Validator}
                      required={true}
                      name={'phone1'}
                      elemId={'france_1'}
                      account_agency_is_france={account_agency_is_france}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  const { countries, states, statesType } = state.settingsReducer;
  return { countries, states, statesType }
}
const mapDispatchToProps = dispatch => {
  return {
    getCountriesCall: () => { dispatch(getCountries()) },
    updateAgencyCall: (agencyData, agency_id) => { dispatch(editAgency(agencyData, agency_id)) },
    getStateCall: (countryId, type) => { dispatch(getStates(countryId, type)) },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyInfoForm)

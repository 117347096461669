import React, { Component } from "react";
import { connect } from 'react-redux';
import { getTodaysShipments } from "redux/actions/report-actions";
import ReactValidator from "simple-react-validator";
import DatePicker from "react-datepicker";
import { Row, Col, Grid } from "react-bootstrap";
import { Card } from "components/Card/Card.jsx";
import 'react-table/react-table.css';
import { Link } from 'react-router-dom';
import { dateWithDayMy, hasRole } from 'helper/helperFunctions';
import Pagination from "../../ThemeComponents/Pagination";

const moment = window.moment;

class TodaysShipmentPage extends Component {
	constructor(props) {
		super(props);
		this.dateValidator = new ReactValidator();
		var today = new Date(),
			date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();

		this.state = {
			fromDate: date,
			toDate: date,
			pageSize: 10,
			page: 0,
			sorted: '',
		}
		this.table = React.createRef();
	}
	DateChangeHandler = (value, field) => {
		this.setState({ [field]: value === null ? '' : moment(value).format("YYYY-MM-DD") }, () => {
			if (this.dateValidator.allValid()) {
				this.pagination.dataCall();;
			} else {
				this.dateValidator.showMessages();
				this.forceUpdate();
			}
		})
	}
	dateElement(label, name, value, rules) {
		var selectedDate = this.state[name] !== '' ? new Date(this.state[name]) : '';
		const { fromDate, toDate } = this.state;
		return (
			<div className='form-group fullCalendar' id={name}>
				<label>{label}</label>
				<DatePicker className="form-control calender_icon" dateFormat={hasRole(['gm admin']) ? 'dd/MM/yyyy' : 'MM/dd/yyyy'} selected={selectedDate} name={name} onChange={(event) => this.DateChangeHandler(event, name)} />
				{
					(fromDate !== '' && toDate !== '') &&
					this.dateValidator.message(name, value, rules)
				}
			</div>
		);
	}
	reportsPaginationData = (page = this.state.page, sorted = this.state.sorted, pageSize = this.state.pageSize) => {
		const { fromDate, toDate } = this.state;
		this.props.getReportsCall(1, fromDate, toDate, page, pageSize, sorted);
	}

	paginationCall = (data) => {
		const { fromDate, toDate } = this.state;
		return this.props.getReportsCall(1, fromDate, toDate, data.page, data.pageSize, data.sort);
	}

	render() {
		const { toDate, fromDate } = this.state;
		const { /*todaysData, REPORTSpages, loadingOrder,*/ daysData, daysTotal } = this.props;
		const columns = [
			{
				Header: "Report Testing",
				headerClassName: 'headerPagination',
				columns: [
					{
						Header: "Shipment Type",
						accessor: "order_shipping_items",
						Cell: ({ value }) => (
							<div>
								{
									value.map(data => {
										return (<div>{data.item.title}</div>)
									})
								}
							</div>
						),
						className: "action-center",
						sortable: false,
					},
					{
						Header: "Barcode",
						width: 200,
						id: "order.barcode",
						accessor: "order",
						Cell: ({ value, original }) => {
							return <Link to={'/Admin/' + (value.reorder ? value.reorder : value.id) + '/order-edit'} className="blue_link">{value.barcode}</Link>
						},
						className: "action-center",
						sortable: false,
					},
					{
						Header: "Tracking",
						accessor: "shipping_method",
						Cell: ({ value, original }) => {
							return <Link to={(value.tracking_url ? value.tracking_url.replace("{track_no}", original.tracking_number) : 0)} className="blue_link">{original.tracking_number}</Link>
						},
						className: "action-center",
						sortable: false,
					},
					{
						Header: "Tour Operator",
						accessor: "order",
						Cell: ({ value }) => (
							<div>
								{value.agency.name}
							</div>
						),
						className: "action-center",
						sortable: false,
					},
					{
						Header: "Group Name",
						accessor: "order",
						Cell: ({ value }) => (
							<div>
								{value.group_name}
							</div>
						),
						className: "action-center",
						sortable: false,
					},
					{
						Header: "Group Contact",
						accessor: "order",
						Cell: ({ value }) => (
							<div>
								{value.group_leader_contact.first_name + ' ' + value.group_leader_contact.last_name}
							</div>
						),
						className: "action-center",
						sortable: false,
					},
					{
						Header: "Street Address",
						accessor: "address",
						Cell: ({ value }) => (
							<div>
								{value.street_address_1}
							</div>
						),
						className: "action-center",
						sortable: false,
					},
					{
						Header: "City",
						accessor: "address",
						Cell: ({ value }) => (
							<div>
								{value.city}
							</div>
						),
						className: "action-center",
						sortable: false,
					},
					{
						Header: "State",
						accessor: "address",
						Cell: ({ value }) => (
							<div>
								{value.state.title}
							</div>
						),
						className: "action-center",
						sortable: false,
					},
					{
						Header: "Zip",
						accessor: "address",
						Cell: ({ value }) => (
							<div>
								{value.zipcode}
							</div>
						),
						className: "action-center",
						sortable: false,
					},
					{
						Header: "Shipment",
						accessor: "order_shipping_items",
						Cell: ({ value }) => (
							<div>
								{
									value.map(data => {
										return (<div>({data.quantity}){data.item.item_category.title}</div>)
									})
								}
							</div>
						),
						className: "action-center",
						sortable: false,
					}
				]
			}
		];
		return (
			<div className="content">
				<Grid fluid>
					<Row>
						<Col md={12}>
							<Card
								ctTableResponsive
								content={
									<div>
										<Grid fluid>
											<div className="row">
												<Col md={12}>
													<div className="secHeading">Today’s Shipments</div>
												</Col>

												<Col md={12}>

													{

														Object.keys(daysData).map(function (key, value) {
															var myday = (value === 0 ? "(Today)" : "(" + value + " days ago)")
															return <Col md={2}> {dateWithDayMy(key)} {myday}: {daysData[key]}</Col>
														})
													}

												</Col>
												<Col md={12}>&nbsp;</Col>
												<Col md={12}>
													<Col md={4}><strong>Past 7 Days Total: </strong> {daysTotal}</Col>
													<Col md={4}><strong> Daily Average of Past 7 Days: </strong>{(daysTotal / 7).toFixed(2)}</Col>
												</Col>
												<Col md={12}>&nbsp;</Col>
												<Col md={4} >
													<div className='row flexElem flexResponsive align-items-center' >
														<div className="col-md-6" >
															{
																this.dateElement('Date From', 'fromDate', fromDate !== '' && moment(fromDate, 'YYYY-MM-DD'), [{ before_or_equal: moment(this.state.toDate) }])
															}
														</div>
													</div>
												</Col>
												<Col md={4} >
													<div className='row flexElem flexResponsive align-items-center' >
														<div className="col-md-6" >
															{
																this.dateElement('Date To', 'toDate', toDate !== '' && moment(toDate, 'YYYY-MM-DD'), [{ after_or_equal: moment(this.state.fromDate) }])
															}
														</div>

													</div>
												</Col>


												{/* <Col md={12} className="mt-md">
					                        	<ReactTable
													ref={this.table}
						                            noDataText= 'No shipment report found'
						                            data={todaysData}
						                            pages={REPORTSpages}
						                            columns={columns}
						                            defaultPageSize={10}
						                            loading={loadingOrder}
						                            className="-striped listing"
						                            loadingText={'Loading...'}
						                            pageData={this.reportsPaginationData}
						                            manual
						                            onFetchData={(state, instance) => {						                             
						                            	var sort = state.sorted.length === 0 ? '' : state.sorted[0].id + ',desc:' + state.sorted[0].desc;
                              							state.pageData(state.page + 1, sort, state.pageSize);
						                            }}
					                          	/>
					                        </Col> */}
												<Pagination
													ref={(p) => this.pagination = p}
													showPagination={false}
													columns={columns}
													pageSize={10}
													getDataCall={this.paginationCall}
													noDataText='No shipment report found'
													filterView={false}
													// downloadData={true}
													// downloadFileName={'Orders'}
													// lowerContent = {null}
													// filterPlaceHolder = {'Orders'}
													getRowProps={this.getRowProps}
												// showAllToggle={true}
												/>
											</div>
										</Grid>
									</div>
								} />
						</Col>
					</Row>
				</Grid>
			</div>
		);
	}
}

const mapDispatchToProps = dispatch => {
	return ({
		getReportsCall: (value, fromDate, toDate, page, pageSize, sorted) => { return dispatch(getTodaysShipments(value, fromDate, toDate, page, pageSize, sorted)) },//example function
		success: (response) => { return dispatch({ type: 'GET_ALL_TODAYS_SHIPMENT_SUCCESS', payload: response }) }
	});
};

function mapStateToProps(state) {
	const { todaysData, daysData, daysTotal, REPORTSpages, REPORTScurrent_page, REPORTStotal_orders, loadingOrder } = state.reportReducer;
	return {
		todaysData, daysData, daysTotal, REPORTSpages, REPORTScurrent_page, REPORTStotal_orders, loadingOrder
	};
}


const TodaysShipment = connect(mapStateToProps, mapDispatchToProps)(TodaysShipmentPage);
export default TodaysShipment;
import { orderService } from "../../services/order";
import { orderImages,getOrderUploadInfo } from "./order-actions";
import { alertActions } from "./alert-actions";
import User from "../../helper/User";
import { frontEndUserConstants } from "../constant/front-end-constant";

export function rotateImage(imageId, direction, version_type = 'original') {
    return (dispatch, getState) => {
        return orderService.rotateImage(imageId, direction, version_type).then(
            response => {
                dispatch(orderImages(User.order('id'), 'original', 'uploaded_images', getState().ordersReducer.current_page, '', '', getState().ordersReducer.perPage))
                dispatch(alertActions.success(response));
                return response;
            },
            error => {
                 return Promise.reject(error)
            }
        )
    }
}

export function toggleImageStatus(images) {
    return (dispatch, getState) => {
        return orderService.toggleImageStatus(images).then(
            response => {
                dispatch(orderImages(User.order('id'), 'original', 'uploaded-images', getState().ordersReducer.current_page, '', '', getState().ordersReducer.perPage, User.user.role.name));
                dispatch(getOrderUploadInfo(User.order('id')));
                dispatch(alertActions.success(response.message))
                return response
            },
            error => {
                dispatch(alertActions.error(error));
                return Promise.reject(error)
            }
        )
    }
}

export function toggleImageKeepsake(images) {
    return (dispatch, getState) => {
        return orderService.toggleImageKeepsake(images).then(
            response => {
                dispatch(orderImages(User.order('id'), 'original', 'uploaded-images', getState().ordersReducer.current_page, '', '', getState().ordersReducer.perPage, User.user.role.name));
                dispatch(getOrderUploadInfo(User.order('id')));
                dispatch(alertActions.success(response.message))
                return response
            },
            error => {
                dispatch(alertActions.error(error));
                return Promise.reject(error)
            }
        )
    }
}

export function changeImagesSortOrder(sortOrder, images = null, order_id) {
    return (dispatch, getState) => {
        return orderService.changeImagesSortOrder(sortOrder, images, order_id).then(
            response => {
                dispatch(alertActions.success(response))
                return response;
                dispatch(orderImages(User.order('id'), 'original', 'uploaded-images', 1, '', '', getState().ordersReducer.perPage, User.user.role.name));
            },
            error => {
            }
        )
    }
}

export function deleteImage(imageId, version_type='original') {
    return (dispatch, getState) => {
        return orderService.deleteImage(imageId, version_type).then(
            response => {
                dispatch(alertActions.success(response));
                dispatch(orderImages(User.order('id'), version_type, 'uploaded-images', getState().ordersReducer.current_page, '', '', getState().ordersReducer.perPage));
                dispatch(getOrderUploadInfo(User.order('id')));
            },
            error => {
                dispatch(alertActions.error(error));
            }
        )
    }
}

export function getProgress(progress) {
    return dispatch => {
        dispatch(sendProgress(progress))
    }
    function sendProgress(progress) { return { type: frontEndUserConstants.SHOW_PROGRESS, payload: progress } }
}

export function resetProgress() {
    return dispatch => {
        dispatch(resetProgress())
    }
    function resetProgress() { return { type: frontEndUserConstants.RESET_PROGRESS } }
}
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getReorder } from 'redux/actions/order-actions'
import { clean, s3StaticPath } from 'helper/helperFunctions';
import MyImage from 'components/Upload/MyImage';
import { getCommentCategoryByTitle, getAllComments } from 'redux/actions/comment-actions';
import InputMask from 'react-input-mask';
const moment = window.moment;

class printReOrderLPR extends Component {
    constructor(props) {
        super(props)
        this.orderId = this.props.match.params.id
        this.state = {

        }
        this.props.getReOrder(this.orderId);
        this.clean = clean.bind(this)
    }

    componentDidMount() {
        this.props.getCommentCategoryByTitle(['Admin']);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.singleCommentCategory !== prevProps.singleCommentCategory) {
            this.props.getAllCommentsCall(1, '', '', 1, this.props.singleCommentCategory.comment_category_id, this.props.match.params.id, '', '');
        }
    }



    render() {
        const { ReOrder, shippingSettings, comments, is_ttk } = this.props;
        console.log("ReOrder", ReOrder);
        const styles = {
            h3: {
                fontSize: '24px',
                marginTop: '0'
            },
            h2: {
                marginTop: '0',
                fontSize: '40px',
                textTransform: 'uppercase',
                fontWeight: '700'
            }
        }
        let productionMethod = {
            key: ''
        }
        if (shippingSettings.length > 0) {
            productionMethod = shippingSettings.find(item => {
                return parseFloat(item.value) === parseFloat(ReOrder.orderTotals.production.value) && item.key.match(ReOrder.orderTotals.production.code);
            });
        }

        let overnightMethod = {
            key: ''
        }
        if (shippingSettings.length > 0 && ReOrder.orderTotals.overnight) {
            overnightMethod = shippingSettings.find(item => {
                return parseFloat(item.value) === parseFloat(ReOrder.orderTotals.overnight.value) && item.key.match(ReOrder.orderTotals.overnight.code);
            });
        }
        return (
            <div className='container'>
                <table className='table table-bordered'>
                    <tbody>
                        <tr>
                            <td className='w-50'>
                                {
                                    ReOrder.agency && ReOrder.agency.single_account &&
                                    ReOrder.agency.single_account.account.domain_slug === 'gm' ?
                                        <MyImage className='img-responsive' src={s3StaticPath('img/GM_Logo_black.jpg')} alt="gm_logo" />
                                    :
                                        <MyImage className='img-responsive' src={s3StaticPath('img/GTV_Logo_black.jpg')} alt="gtv_logo" />
                                }
                                
                            </td>
                            <td className='text-center'>
                                <h1 style={{ fontSize: '80px' }} className='text-uppercase font-weight-bold'>reorder</h1>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan='2' className='text-center'>
                                <p className='text-uppercase font-weight-bold'>important: shipping use gtv reorder key import & priority mail</p>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan='2' className='text-center'>
                                <MyImage src={ReOrder.reorder_barcode} alt="reorder_barcode" />
                            </td>
                        </tr>
                        <tr className='border-bottom'>
                            <td colSpan='2' className='text-center'>
                                <p>order #{ReOrder.id}</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div className='pl-md'>
                                    <h3 style={styles.h3} className='font-weight-normal'>Shipping Address</h3>
                                    {
                                        ReOrder &&
                                        (ReOrder.video_shipping_address.country.title == 'France')
                                        ?
                                            <>
                                                <p>{ReOrder.reorderCustomerName ? ReOrder.reorderCustomerName : ''}</p>
                                                <p>{ReOrder.video_shipping_address.street_address_1 ? ReOrder.video_shipping_address.street_address_1 : ''}

                                                   { ReOrder.video_shipping_address.street_address_2
                                                    ?
                                                       ', ' + ReOrder.video_shipping_address.street_address_2
                                                    : ''
                                                    }
                                                </p>
                                                <p>{ReOrder.video_shipping_address.zipcode ? ReOrder.video_shipping_address.zipcode : ''} {ReOrder.video_shipping_address.city ? ReOrder.video_shipping_address.city : ''}{ReOrder.video_shipping_address.country.title ? ', ' + ReOrder.video_shipping_address.country.title : ''}</p>
                                                {/* <p>{ReOrder.video_shipping_address.country.title ? ReOrder.video_shipping_address.country.title : ''}</p> */}
                                            </>
                                        :
                                            <>
                                                <p>{ReOrder.reorderCustomerName ? ReOrder.reorderCustomerName : ''}</p>
                                                <p>{ReOrder.video_shipping_address.street_address_1 ? ReOrder.video_shipping_address.street_address_1 : ''}

                                                   { ReOrder.video_shipping_address.street_address_2
                                                    ?
                                                       ', ' + ReOrder.video_shipping_address.street_address_2
                                                    : ''
                                                    }
                                                    </p>
                                                <p>{ReOrder.video_shipping_address.city ? ReOrder.video_shipping_address.city : ''}{ReOrder.video_shipping_address.state.code ?', ' + ReOrder.video_shipping_address.state.code: ''} {ReOrder.video_shipping_address.zipcode ? ReOrder.video_shipping_address.zipcode : ''}</p>
                                            </>
                                    }
                                </div>
                            </td>
                            <td>
                                <div>
                                    <h3 style={styles.h3} className='font-weight-normal'>Customer Contact Information</h3>

                                    {
                                        (ReOrder.video_shipping_address.country.title == 'France')
                                            ?
                                                <p>
                                                <InputMask
                                                    mask={"99-9-99-99-99-99"}
                                                    maskChar={null}
                                                    type="text"
                                                    // name={name}
                                                    className="form-control notCard bg_none pl-none cursorDefault"
                                                    placeholder="12-3-45-67-89-10"
                                                    value={ReOrder.group_leader_contact.phone1}
                                                    readOnly={true}
                                                />
                                                </p>
                                            :
                                                <p>
                                                    <InputMask
                                                        mask={"999-999-9999-999999"}
                                                        maskChar={null}
                                                        type="text"
                                                        // name={name}
                                                        className="form-control notCard bg_none pl-none cursorDefault"
                                                        placeholder="123-456-7890-123456"
                                                        value={ReOrder.group_leader_contact.phone1}
                                                        readOnly={true}
                                                    />
                                                </p>
                                    }
                                    <p>{ReOrder.group_leader_contact.email}</p>
                                    {
                                        (ReOrder.parent_order.video_ship_status && ReOrder.parent_order.video_ship_status.length > 0) &&
                                        <React.Fragment>
                                            <h3 style={styles.h3} className='font-weight-normal'>Video Ship Date</h3>
                                            <p>{ ReOrder.parent_order.video_ship_status.length > 0 ? moment(ReOrder.parent_order.video_ship_status[0].process_date).format('M/D/Y') : '' }</p>
                                        </React.Fragment>
                                    }
                                    {
                                        ReOrder.ups &&
                                        <React.Fragment>
                                            <h3 style={styles.h3} className='font-weight-normal'>Tracking Number</h3>
                                            <p>{ ReOrder.ups ? ReOrder.ups.UPSTrack : '' }</p>
                                        </React.Fragment>
                                    }
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan='2'>
                                <div className='text-center'>
                                    <h2 style={styles.h2}>Products Ordered</h2>
                                    {
                                        ReOrder.order_items.map(orderItem => {
                                            if ((!is_ttk && orderItem.item.title !== 'Flash Drive with Gallery Photos') ||
                                                (is_ttk && orderItem.item.title !== 'Flash Drive with Video and Photos' &&
                                                    orderItem.item.title !== 'Keepsake Video on DVD'
                                                    )){

                                                return (
                                                    <p key={orderItem.id}>{orderItem.item.title} = {orderItem.quantity}</p>
                                                )
                                            }
                                        })
                                    }
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan='2'>
                                <div className='text-center'>
                                    <h2 style={styles.h2}>Production & Shipping</h2>
                                    <p><span className='text-capitalize'>{productionMethod.key.replace('_', ' ')}</span> - {productionMethod.description}</p>
                                    {
                                        overnightMethod && overnightMethod.key != '' ?
                                            <p><span className='text-capitalize'>{overnightMethod.key.replace('_', ' ')}</span> - {overnightMethod.description}</p>
                                            :
                                            ''
                                    }
                                </div>
                            </td>
                        </tr>
                        {
                            ReOrder.program_type.video_quantity > 0 &&
                            <tr>
                                <td className='text-center'>
                                    {
                                        ReOrder.resources.sleeve ?
                                            <MyImage src={ReOrder.resources.sleeve.file_path} alt="" />
                                            :
                                            <p>{ReOrder.agency.name} does not have a sleeve</p>
                                    }
                                </td>
                                <td className='text-center'>
                                    {
                                        ReOrder.resources.dvd_label ?
                                            <div>
                                                <div className='mb-md'><strong>Custom DVD Label</strong></div>
                                                <MyImage style={{ margin: '0 auto' }} className='img-responsive w-25' src={ReOrder.resources.dvd_label.file_path} alt="" />
                                            </div>
                                            :
                                            <strong>{ReOrder.agency.name} does not have a Custom DVD Label</strong>
                                    }
                                </td>
                            </tr>
                        }
                        <tr>
                            <td>
                                <h3 style={styles.h3} className='font-weight-normal'>Original Order Barcode</h3>
                                <div className='ml-lg my-20'>
                                    <MyImage src={ReOrder.parent_order_barcode} alt="" />
                                </div>

                                {
                                    ReOrder.parent_order.producer &&
                                    <React.Fragment>
                                        <h3 style={styles.h3} className='font-weight-normal'>Producer:</h3>
                                        <p>{this.clean(ReOrder.parent_order.producer.name, '')}</p>
                                    </React.Fragment>
                                }
                            </td>
                            <td>
                                <h3 style={styles.h3} className='font-weight-normal'>Group Name</h3>
                                <p>{ReOrder.group_name}</p>
                                <h3 style={styles.h3} className='font-weight-normal'>Destination</h3>
                                <p>{ReOrder.destinations}</p>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan='2'>
                                <h3 style={styles.h3} className='font-weight-normal'>Admin Comments</h3>
                                {
                                    comments && comments.data && comments.data.length > 0 &&
                                    <p>{comments.data[0].comment}</p>
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <h3 style={styles.h3}> <span className='font-weight-normal'>Date Order Submitted:</span> {ReOrder.OrderCreated}</h3>
                            </td>
                            <td className='text-right'>
                                <h3 style={styles.h3}><span className='font-weight-normal'>Order Total</span>: ${ReOrder.orderTotals.grand_total.value}</h3>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { ReOrder, shippingSettings, is_ttk } = state.ordersReducer;
    const { singleCommentCategory, comments } = state.commentReducer;
    return {
        ReOrder,
        shippingSettings,
        singleCommentCategory,
        comments,
        is_ttk
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getReOrder: (id) => { dispatch(getReorder(id)) },
        getCommentCategoryByTitle: (titles) => { dispatch(getCommentCategoryByTitle(titles)) },
        getAllCommentsCall: (page, filter, sort, pageSize, cat_id, modelId, from, to, uuid) => { return dispatch(getAllComments(page, filter, sort, pageSize, cat_id, modelId, from, to, uuid)) },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(printReOrderLPR)

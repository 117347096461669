import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Grid, Row, Col, Button } from 'react-bootstrap'
import DatePicker from "react-datepicker";
import Card from '../../Card/Card'
import { storeMoreButton, getMoreButton, updateMoreButtonForOrder } from '../../../redux/actions/more-buttons-action';
import ImagePreview from "components/Upload/ImagePreview";
// import ToggleButton from 'react-toggle-button';
// import { themeCSSConstants } from 'redux/constant/themeCSS';
import Validator from 'simple-react-validator';
import FileUpload from "components/Upload/FileUpload";
import {objectsConstants as OC} from 'redux/constant/objects-constant';
import { hasRole } from '../../../helper/helperFunctions';

const moment = window.moment;

class OrderMoreButtonForm extends Component {
    constructor(props) {
        super(props);
        this.dateValidator = new Validator();
        var date = moment(new Date()).format('YYYY-MM-DD');
        // console.log(date, 'dateee');
        this.state = {
            moreButtons: OC.MOREBUTTONS,
            expiration_date: date,
            uploadLink: false,
            uploadFile: false
        }
        this.Validator = new Validator();

    }

    inputChangeHandler = (e) => {
        // console.log(this.state.moreButtons);
        const moreButtons = { ...this.state.moreButtons };
        moreButtons[e.target.name] = e.target.value;
        this.setState({
            moreButtons: moreButtons,
        })
    }

    inputChangeHandlerLink = (e) => {
        // console.log(this.state.moreButtons);
        const moreButtons = { ...this.state.moreButtons };
        moreButtons[e.target.name] = e.target.value;
        var length = e.target.value.length
        this.setState({
            moreButtons: moreButtons,

        }, () => {
            if(length > 0){
                this.setState({
                    uploadLink: false,
                    uploadFile: true
                })

            } else{
                this.setState({
                    uploadLink: false,
                    uploadFile: false
                })
            }
        })
    }

    // takes a {} object and returns a FormData object
    objectToFormData = (obj, form, namespace) => {
        var fd = form || new FormData();
        var formKey;
        for(var property in obj) {
            if(obj.hasOwnProperty(property)) {
                if(namespace) {
                    formKey = namespace + '[' + property + ']';
                } else {
                    formKey = property;
                }
                // if the property is an object, but not a File,
                // use recursivity.
                if(typeof obj[property] === 'object' && !(obj[property] instanceof File)) {
                    // console.log('obj', obj);
                    // console.log('property', property);
                    // if(obj == 'file_path'){
                    //     continue;
                    // }
                    this.objectToFormData(obj[property], fd, property);
                } else {
                    // if(obj == 'file_path[id]'){
                    //     continue;
                    // }
                    // console.log('obj2', obj);
                    // console.log('property2', property);
                    // if it's a string or a File object
                    fd.append(formKey, obj[property]);
                }
            }
        }
        return fd;
    }

    formSubmitHandler = () => {

        const data = new FormData();
        Object.keys(this.state.moreButtons).forEach(item => {
            if(item === 'expiration_date' || (item === 'order_id' && this.props.order_id)){
                return;
            }
            data.append(item,this.state.moreButtons[item]);
        })
        // // if(this.props.agency_id && this.state.moreButtons.model_type == 'App\\Agency') {
        // //     data.append('model_id', this.props.agency_id);
        // // }


        // var data = this.objectToFormData(this.state.moreButtons);

        if(this.state.expiration_date) {
            data.append('expiration_date', moment(new Date(this.state.expiration_date)).format('YYYY-MM-DD'));
        }
        if(this.props.order_id) {
            data.append('order_id', this.props.order_id);
        }

            // const data = { ...this.state.moreButtons, model_id : this.props.agency_id};
            if (this.props.formType === 'add') {
                this.props.storeMoreButtonCall(data).then(
                    response => {
                        if ('hide' in this.props) {
                            this.props.hide();
                            this.props.getPagination();
                            console.log('function called successfully');
                        }
                    }
                );
            } else if (this.props.formType === 'edit') {
                console.log('function called successfully');
                this.props.updateMoreButtonForOrderCall(data, this.props.data.id, this.props.order_id).then(
                    response => {
                        if ('hide' in this.props) {
                            this.props.hide();
                            this.props.getPagination();
                            console.log('function called successfully');
                        }
                    }
                );
            }
    }

    setResourceFile = (key, value) => {
		this.setState({ moreButtons: { ...this.state.moreButtons, file_path: value } ,
            uploadLink: true,
            uploadFile: false }, function () {
			// console.log('pro show file', this.state.moreButtons);
		})
	}
	removeFile = (key, value) => {
		this.setState(prevState => ({
			moreButtons: {
				...prevState.moreButtons,
				file_path: '',
				file_name: '',
                mime_type: '',
                extension: '',
                file_size: '',

			},
            uploadLink: false,
            uploadFile: false
		}), () => {
			console.log('pro show file', this.state.moreButtons);
		});
	}

    handleOptionAtOrderPlacement = (name, value) => {
		let tempValue = !value;
        tempValue = tempValue ? 1 : 0;
		this.setState(prevState => ({
					moreButtons: {
                        ...prevState.moreButtons,
                        [name]: tempValue
                    }
		})
        	,
			() => {
				if (name === 'show_on_all_orders' || name === 'option_at_order_placement') {
					var WtOrder = '';
					if (name === 'show_on_all_orders')
						WtOrder = 'option_at_order_placement';
					else
						WtOrder = 'show_on_all_orders';
					this.setState(prevState => ({
						moreButtons: {
                            ...prevState.moreButtons,
                            [WtOrder]:0
                        }
					}));
				}
            });
	}
    DateChangeHandler = (value, field) => {
        console.log(value, 'valueeee#35');
        this.setState({ [field]: value === null ? '' : moment(new Date(value)).format('YYYY-MM-DD') }, () => {
            if (this.dateValidator.allValid()) {
            } else {
                this.dateValidator.showMessages();
                this.forceUpdate();
            }
        })
    }
    dateElement(label, name, value, rules) {
        var selectedDate = this.state[name] !== '' ? new Date(this.state[name]) : '';
        return (
            <div className='form-group fullCalendar' id={name}>
                <label>{label}</label>
                <DatePicker
                    className="form-control calender_icon"
                    dateFormat={hasRole(['gm admin']) ? 'dd/MM/yyyy' : 'MM/dd/yyyy'}
                    selected={selectedDate}
                    name={name}
                    // disabled={true}
                    readOnly={true}
                    onChange={(event) => this.DateChangeHandler(event, name)}
                />

            </div>
        );
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.moreButtons !== this.props.moreButtons) {
            this.setState({
                moreButtons: this.props.moreButtons
            })
        }
    }


componentDidMount(){
    let data = this.props.data
    this.setState({
        moreButtons:{...this.props.data},
        expiration_date: (this.props.data.expiration_date !== '' && this.props.data.expiration_date !== null && this.props.data.expiration_date !== 'null') ? moment(new Date(this.props.data.expiration_date)).format('YYYY-MM-DD') : ''
    }, ()=>{
        if(data.link && data.link.length > 0){
            this.setState({
                uploadLink: false,
                uploadFile: true
            })

        }else if(data.file_path && data.file_path.name && data.file_path.name.length > 0){
            this.setState({
                uploadLink: true,
                uploadFile: false
            })
        }else{
            this.setState({
                uploadLink: false,
                uploadFile: false
            })
        }

    })

    // this.props.updateCall("this.formSubmitHandler")
}

    render() {
        const { moreButtons, expiration_date } = this.state;
        // console.log(moreButtons)
        const title = this.props.formType === 'add' ? 'Create' : 'Edit';
        return (
            <div>
                <div className="flexElem alignCenter">
                    <div className="togglePadding mb-sm" style={{color:"red"}}>
                        You may change the Link or file here but to change button content you must go to your agency account
                    </div>
                </div>
                <Grid fluid>
                    <Row>
                        <Col md={12} >
                            <Card title={title + ' More Button'} bsClass={['innerCard']} content={
                                <React.Fragment>
                                    <form id="moreButtons-form" encType='multipart/form-data'>
                                         <div className="row">
                                                <div className="col-md-6">
                                                    <div className='form-group' id="title">
                                                        <label htmlFor="title">Title<span className="requiredClass">*</span></label>
                                                        <input type="text" name="title" readOnly className="form-control" value={moreButtons.title} onChange={this.inputChangeHandler} />

                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className='form-group' id="sort_order">
                                                        <label htmlFor="sort_order">Sort Order</label>
                                                        <input type="number" min="1" name="sort_order" readOnly className="form-control " value={moreButtons.sort_order} onChange={this.inputChangeHandler} />
                                                    </div>
                                                </div>
                                        </div>

                                            <div className="row">
                                                <div className="col-md-6">
                                                    {
                                                        this.dateElement('Expiration Date','expiration_date', expiration_date !== '' && moment(expiration_date, 'MM-DD-YYYY').format('MM-DD-YYYY'),'date')
                                                    }
                                                </div>
                                            </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="togglePadding form-group">
                                                    <label className="container-check flexElem alignCenter">
                                                    {/* <ToggleButton
                                                        readOnly={true}
                                                        inactiveLabel={''}
                                                        activeLabel={''}
                                                        height={'40px'}
                                                        colors={themeCSSConstants.THUMBICONSTYLE}
                                                        trackStyle={{ height: '10px', width: '30px' }}
                                                        thumbStyle={themeCSSConstants.THUMBSTYLE}
                                                        thumbAnimateRange={[0, 15]}
                                                        value={moreButtons.show_on_all_orders}
                                                        onToggle={() => this.handleOptionAtOrderPlacement("show_on_all_orders", moreButtons.show_on_all_orders)} /> */}
                                                        <div style={{fontSize: '16px'}}>Show on all orders: <strong>{parseInt(moreButtons.show_on_all_orders, 10) == 1 ? 'Yes' : 'No'}</strong></div>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="togglePadding form-group">
                                                    <label className="container-check flexElem alignCenter">
                                                    {/* <ToggleButton
                                                        readOnly={true}
                                                        inactiveLabel={''}
                                                        activeLabel={''}
                                                        height={'40px'}
                                                        colors={themeCSSConstants.THUMBICONSTYLE}
                                                        trackStyle={{ height: '10px', width: '30px' }}
                                                        thumbStyle={themeCSSConstants.THUMBSTYLE}
                                                        thumbAnimateRange={[0, 15]}
                                                        value={moreButtons.option_at_order_placement}
                                                        onToggle={() => this.handleOptionAtOrderPlacement("option_at_order_placement", moreButtons.option_at_order_placement)} /> */}
                                                        <div style={{fontSize: '16px'}}>Option at order placement: <strong>{parseInt(moreButtons.option_at_order_placement, 10) == 1 ? 'Yes' : 'No'}</strong></div>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className='form-group' id="description">
                                                    <label htmlFor="description">Description<span className="requiredClass">*</span></label>
                                                    <textarea type="text" readOnly style={{height: '130px'}} name="description" className="form-control " value={moreButtons.description} onChange={this.inputChangeHandler} />

                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className='form-group' id="link">
                                                    <label htmlFor="link">Upload Link</label>
                                                    <input type="text" name="link" className="form-control " disabled = {(this.state.uploadLink)} value={moreButtons.link} onChange={this.inputChangeHandlerLink} />
                                                </div>
                                            </div>
                                        </div>
                                        {/* {
                                          this.state.uploadFile == false ? */}
                                        <div className="row">
                                            <div className="col-md-12" id="File">
                                                <label htmlFor="name">Upload File </label>
                                                <div className={"pofilePicContainer " + (this.state.uploadFile ? 'disableDropzone' : '')}>
                                                    {
                                                        !moreButtons.file_path || !moreButtons.file_path.name ?
                                                            <div className='form-group dropzone_profileImage'>
                                                                <FileUpload
                                                                    files={moreButtons.file_path}
                                                                    setResourceFile={this.setResourceFile}
                                                                    allowedFormatsStringMimePro={'image/jpeg, image/png, video/quicktime, video/mp4, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/pdf ,application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}
                                                                    allowedFormatsStringExtPro={'jpg, png, docx, doc, Jpeg, pdf, xls, xlsx, mov, mp4'}
                                                                    multiple={false}
                                                                />
                                                            </div>
                                                            :
                                                                <ImagePreview
                                                                    hideVideoControls
                                                                    log={console.log('moreButtons', moreButtons)}
                                                                    filesToPreview={moreButtons.file_path}
                                                                    removeFile={this.removeFile}
                                                                    resetImage={true}
                                                                />
                                                    }
                                                </div>
                                            </div>
                                        </div>
{/* :''
} */}
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className='text-center'>
                                                    <Button type='button' onClick={this.formSubmitHandler} form="moreButtons-form" bsSize="large" bsStyle="info" className="backButton pt-sm no_radius pb-sm success btn btn-lg btn-info ml-sm mt-sm btn-default " >{this.props.formType =="add" ? "Save" : "Update"}</Button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </React.Fragment>
                            } />
                        </Col>
                    </Row>
                </Grid>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { moreButtons } = state.moreButtonReducer;
    return { moreButtons }
}

const mapDispatchToProps = dispatch => {
    return {
        storeMoreButtonCall: (data) => { return dispatch(storeMoreButton(data)) },
        getMoreButtonCall: (id) => { dispatch(getMoreButton(id)) },
        updateMoreButtonForOrderCall: (data, id) => { return dispatch(updateMoreButtonForOrder(data, id)) }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(OrderMoreButtonForm);

import React, { Component } from 'react'
import { Link } from 'react-router-dom';
// import MyImage from 'components/Upload/MyImage';

class Invoicing extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="infoBox redBox mt-lg">
                    <div>When invoiced, please make checks payable and remit to:</div>
                    <div className="flexElem justifyCenter mt-md">    
                        <address className="text_14 red_bold">
                            <div className="mt-xs">PhotoVision, Inc</div>
                            <div className="mt-xs">8300 Fairview Road</div>
                            <div className="mt-xs">Mint Hill, NC 28227.</div>
                        </address>
                    </div>

                    <div className="mt-md">Need Help? Feel Free to <Link target='_blank' className="linkColor link_hover" to="/Contact_Us">Contact Us</Link></div>
                </div>
                {/*<div className="form_tile">
                     <div className="form_tile_heading">
                        <img src={invoicing} alt="Invoicing" />
                        <span>Invoicing</span>
                    </div>
                    <div className="form_tile_content">
                        <div className="flexElem respFlex spaceBetween form_tile_row">
                            <div className="col-md-12">
                                <div className="form_tile_title">When invoiced, please make checks payable and remit to:</div>

                                <div className="flexElem justifyCenter">
                                    <address className="text-primary text_16">
                                        <div className="mt-sm">PhotoVision, Inc</div>
                                        <div className="mt-sm">8300 Fairview Road</div>
                                        <div className="mt-sm">Mint Hill, NC 28227</div>
                                    </address>
                                </div>
                                <div className="flexElem justifyCenter">
                                    <div className="mt-lg">
                                        <div className="theme_color text_16">Need Help? Feel Free</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="flexElem form_tile_row">
                            <div className="col-md-12 text-center">
                                <a href='/Contact_Us' target='_blank' className="themeBtn_14 pl-xlg pr-xlg purple_gradient hover_dark">Click Here to Contact Us</a>
                            </div>
                        </div>
                    </div>
                </div>*/}
            </React.Fragment>
        )
    }
}

export default Invoicing

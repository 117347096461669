import React, { Component } from "react";
import InputMask from 'react-input-mask';

class phoneNumber extends Component {

	constructor(props) {
		super(props);
		this.state = {
			validatorArray : [
				{
					'regex': [/^[\d]{3}[-]?[\d]{3}[-]?[\d]{4}[-]?[\d]{0,6}$/]
				},
			],
		}

	}
	componentDidMount(){
		const { required } = this.props;
		if ( required ) {
			this.setState({ validatorArray: [...this.state.validatorArray, 'required'] })
		}
	}

	render(){
		const { validatorArray } = this.state;
		const { name, value, validationName } = this.props;
		return(
			<React.Fragment>
				<InputMask
					mask={"999-999-9999-999999"}
					maskChar={null}
					type="text"
					name={name}
                    id={this.props.elemId}
                    onClick={this.props.handleParent}
					className="form-control phone_icon"
					placeholder="123-456-7890-123456"
					value={value}
					onChange={this.props.handleParent}
				/>
				<div style={{width: '100%'}}>
				{
					this.props.validator &&
						this.props.validator.message(validationName, value, validatorArray)
				}
				</div>
			</React.Fragment>
		);
	};
}



export default phoneNumber;

import React from 'react';
import {
	Grid,
	Row,
	Col,
} from "react-bootstrap";
import { connect } from 'react-redux';
import { Card } from "components/Card/Card.jsx";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import "react-tabs/style/react-tabs.css";
import OrderDetailShowAndModal from 'components/Admin/Order/OrderDetailShowAndModal';
import Images from 'components/Admin/Order/Images';
import ShippingDetailShowAndModal from 'components/Admin/Order/ShippingDetailShowAndModal';
import PricingDetailShowAndModal from 'components/Admin/Order/PricingDetailShowAndModal';
import { updateOrderSettings, updateSettingsInOrderDetail, updateOrderStatusByTitle, getOrder, getAllowReorder } from 'redux/actions/order-actions';
import { getMoreButtonsPagination } from '../../../redux/actions/more-buttons-action';
import { objectsConstants as OC } from 'redux/constant/objects-constant';
import OrderDocument from "components/Admin/Order/OrderDocument";
import Travelers from 'components/Admin/Order/Travelers';
import LoadingOverlay from "react-loading-overlay";
import { BounceLoader } from "react-spinners";
import OrderSettings from 'components/Admin/Order/OrderSettings';
import OrderComments from 'components/Admin/Order/OrderComments';
import AppCard from 'components/Admin/Order/AppCard';
import { hasRole, s3StaticPath, returnDynamicURL } from 'helper/helperFunctions';
import { ThemeFunctions as TF } from 'helper/ThemeFunctions';
import { getSettingValue, getOrderStatuses, getProgramType } from 'redux/actions/settings-actions';
import MyImage from 'components/Upload/MyImage';
import { Status } from '../../../constants/Status';
import ReorderList from '../Reorder/ReorderList';
import { Link } from 'react-router-dom';
import { getLatestCommentByCategoryTitle } from 'redux/actions/comment-actions';
import { getAgency } from 'redux/actions/agency-actions';
import { login } from 'redux/actions/front-end-action';
import OrderMoreButtonsList from './OrderMoreButtonsList';
import User from 'helper/User';
import AddItinerary from '../Itinerary/AddItinerary';

const queryString = require('query-string');

class OrderEditPage extends React.Component {
	constructor(props) {
		super(props);
		const { id } = this.props.match.params;
		const parsed = queryString.parse(window.location.search);
		this.state = {
			orderId: id,
			order_details: OC.ORDERDETAIL,
			shipping_details: OC.SHIPPINGDETAIL,
			pricing_details: OC.PRICINGDETAIL,
			production_details: this.props.production_details,
			stateParams: this.props.location.state,
			index: 0,
			tabsArray: [
				'details',
				'production',
				'appCards',
				'settings',
				'travelersHomeUsers',
				'documents',
				'reOrders'
			],
			defaultActiveTabName: parsed.orderDetailCode ? parsed.orderDetailCode : '',
			orderCancelComment: {},
			is_france: false,
			initial_country_France: false,
			ptma_initial_country_France: false,
			vsa_initial_country_France: false,
			vsa_is_france: false,
			ptma_is_france: false,
		}




	}

	componentDidUpdate(prevProps, nextProps) {
		if (this.props.updatedSetting !== prevProps.updatedSetting) {
			this.setState(prevState => ({
				...prevState, order_details: {
					...prevState.order_details, settings: {
						...prevState.order_details.settings, [this.props.updatedSetting.key]: this.props.updatedSetting
					}
				}
			}));
		}
		if ((this.props.order_details !== prevProps.order_details)) {
			this.setState({ order_details: this.props.order_details }, () => {
				if (this.props.order_details.agency.primary_address && this.props.order_details.agency.primary_address.state && this.props.order_details.agency.primary_address.state.title && this.props.order_details.agency.primary_address.state.title === 'North Carolina') {
					this.props.getSettingValueCall('tax', 'carolina')
				}
				// console.log(this.state.orderCancelComment);
				if (this.state.orderCancelComment !== null) {
					if (!('id' in this.state.orderCancelComment)) {
						if (this.props.order_details.status.title === Status.CANCELLED) {
							this.props.getLatestCommentByCategoryTitle('Cancel', this.state.orderId).then(
								res => {
									this.setState({
										orderCancelComment: res.data.comment
									})
								}
							)
						}
					}
				}
			});
		}
		if (this.props.shipping_details !== prevProps.shipping_details) {
			this.setState({ shipping_details: this.props.shipping_details });
		}
		if (this.props.pricing_details !== prevProps.pricing_details) {

			this.setState({ pricing_details: this.props.pricing_details });
		}
		if (this.props.production_details !== prevProps.production_details) {
			this.setState({ production_details: this.props.production_details });
		}
		// if (this.props.order_details.agency.primary_address && this.props.order_details.agency.primary_address.state && this.props.order_details.agency.primary_address.state.title && this.props.order_details.agency.primary_address.state.title === 'North Carolina') {
		//     this.props.getSettingValueCall('tax', 'carolina')}
		if ((this.props.settings !== prevProps.settings) && !this.props.loadingSettings) {
			if (this.props.settings.key === 'carolina_tax') {
				// const taxOT = { ...this.state.pricing_details };
				//             const order_totals = { ...taxOT.order_totals };
				//             console.log("order_totals carolina_tax", order_totals);
				//             var tempOT = {
				//                 code: 'tax',
				//                 value: this.props.settings.value,
				//                 id: this.props.pricing_details.order_totals.tax && this.props.pricing_details.order_totals.tax.id,
				//                 order_id: this.props.pricing_details.order_totals.tax.order_id
				//             };
				//             console.log("this.props.settings.value", this.props.settings.value);
				//             order_totals['tax'] = tempOT;
				//             this.setState({
				//                 pricing_details: {
				//                     ...taxOT,
				//                     order_totals: order_totals
				//                 }
				//             })
				const taxOT = { ...this.state.pricing_details.order_totals };
				var tempOT = {
					code: 'tax',
					value: this.props.settings.value,
				};
				taxOT['tax'] = tempOT;

				this.setState(prevState => ({
					...prevState, pricing_details: {
						...prevState.pricing_details, order_totals: taxOT
					}
				}));
			}
		}

		// if (this.props.shipping_details && this.props.shipping_details.pre_trip_material_address && this.props.shipping_details.pre_trip_material_address.country && this.props.shipping_details.pre_trip_material_address.country.title === 'United States')
		//    {
		//        this.setState({
		//            is_ptm_france: true
		//        })
		//    }
	}

	componentDidMount() {

		this.props.getOrderStatuses();
		this.props.getProgramTypeCall();
		this.props.getOrderCall(this.state.orderId);
		this.props.getAllowReorderCall(this.state.orderId);

		// if(this.state.order_details.agency_id){
		// 	this.props.getAgencyCall(this.state.order_details.agency_id);
		// // }
		// this.props.getAgencyCall(this.state.order_details.agency_id);

		window.scrollTo(0, 0);
		if (this.state.defaultActiveTabName) {
			// console.log('test', this.state.defaultActiveTabName);
			const index = this.state.tabsArray.findIndex(item => {
				return item === this.state.defaultActiveTabName
			});
			// console.log('index', index);
			if (index !== -1) {
				// console.log('index state one', index);
				this.setState({ index: index });
				// this.setState({
				//     index: index
				// }, ()=>{

				// });

				// console.log('index state two', this.state.index);
			}
		}
	}

	updateOrderSettings = (e) => {
		const value = e.target.value;
		const key = e.target.name
		this.props.updateOrderSettingsCall(this.state.orderId, value, key).then(
			res => {
				// this.props.getOrderCall(this.state.orderId)
			}
		);
	}

	updateSettingsInOrderDetail = (e) => {
		const value = e.target.value;
		const key = e.target.name
		this.props.updateOrderSettingsCallNew(this.state.orderId, value, key).then(
			res => {
				// this.props.getOrderCall(this.state.orderId)
			}
		);
	}


	updateOrderStatus = (orderId, status) => {
		this.props.updateOrderStatusByTitleCall(orderId, status).then(response => {
			this.props.getOrderCall(orderId);
		});
	}

	getChildState = (val1, val2, val3, val4, val5, val6) => {
		this.setState({
			is_france: val1,
			initial_country_France: val2,
			ptma_initial_country_France: val3,
			vsa_initial_country_France: val4,
			ptma_is_france: val5,
			vsa_is_france: val6,
		});

	}

	render() {

		const { orderId, order_details, shipping_details, pricing_details, production_details,
			stateParams,
			orderCancelComment,
			initial_country_France,
			is_france,
			vsa_initial_country_France,
			ptma_initial_country_France,
			ptma_is_france,
			vsa_is_france } = this.state;
		const { loadingOrder, programType, csr_steps, columns, editAgency, is_ttk, allow_reorder_days_diff } = this.props;
		const { is_5_years_old_order } = allow_reorder_days_diff;
		let link = returnDynamicURL(typeof order_details.agency.single_account === 'undefined' ||
			!order_details.agency.single_account ? null : order_details.agency.single_account.account.domain_slug,
			order_details.photo_admin_username, order_details.photo_admin_password);
		return (
			<div className="content">
				<Grid fluid>
					<Row>
						<Col md={12}>
							<Card
								content={
									<Grid fluid>
										{
											hasRole(['admin','gm admin', 'Remote Editor', 'Remote Editor/Producer', 'Remote Producer']) &&
											<Col md={12}>
												<table className={"table blank mt-lg mb-xlg " + (is_ttk ? 'ttkOrderStatusContainer' : 'orderStatusContainer')}>
													<thead>
														<tr>
															<th>Barcode</th>
															<th>Brand</th>
															<th>Logo</th>
															<th>Agency</th>
															<th>Group Name</th>
															<th>Production Status</th>



															{
																/*

																	<th>
																		On Hold
																		{
																			'on_hold' in order_details.settings
																				?
																				order_details.settings.on_hold.value === '1' &&
																					<OverlayTrigger overlay={
																						<Popover id="onHoldStatus">
																							This order was placed on hold on <strong>{getFormattedDate(order_details.settings.on_hold.updated_at)}</strong>

																							{  order_details.settings.on_hold.updated_by_user ? ` by ${order_details.settings.on_hold.updated_by_user.name}` : null }
																						</Popover>
																					}>
																						<span className="question_mark ml-xs">?</span>
																					</OverlayTrigger>
																				:
																				null
																		}
																	</th>
																*/
															}
															{/*<th>Approve</th>*/}
														</tr>
													</thead>

													<tbody>
														<tr>
															<td>
																<span className="barcodeContainer td_barcode">
																	{order_details.barcode}
																</span>
															</td>
															<td>
																<span className="barcodeContainer" style={{ textTransform: 'uppercase' }}>
																	{
																		order_details.agency && order_details.agency.accounts
																			?
																			(order_details.agency.accounts[0].domain_slug == 'MCI' || order_details.agency.accounts[0].domain_slug == 'ACT' || order_details.agency.accounts[0].domain_slug == '') ? 'GTV' : order_details.agency.accounts[0].domain_slug
																			:
																			""
																	}
																</span>
															</td>
															<td>
																<div className="agencyLogoContainer">
																	{
																		order_details.agency_id !== order_details.customer_slates_for_videos_id
																			?
																			order_details.customer_slates_for_videos.agency_logo !== undefined && order_details.customer_slates_for_videos.agency_logo !== null
																				?
																				<MyImage src={order_details.customer_slates_for_videos.agency_logo.file_path} alt="secondary logo" className="agencyLogo secondaryLogo" />
																				:
																				<MyImage src={s3StaticPath('img/placeholder.png')} className="agencyLogo secondaryLogo" alt="secondary agency logo placeholder" />
																			:
																			order_details.agency.agency_logo !== undefined && order_details.agency.agency_logo !== null
																				?
																				<MyImage src={order_details.agency.agency_logo.file_path} alt="primary logo" className="agencyLogo" />
																				:
																				<MyImage src={s3StaticPath('img/placeholder.png')} className="agencyLogo" alt="primary agency logo placeholder" />
																	}
																</div>
																{/*

																<div className="agencyLogoContainer ml-xs" style={{ maxWidth: '90px', minWidth: '90px' }}>
																	{
																		order_details.agency.secondary_logo !== undefined && order_details.agency.secondary_logo != null ?
																			<MyImage src={order_details.agency.secondary_logo.file_path} alt="logo" className="agencyLogo" />
																			:
																			<MyImage src={s3StaticPath('img/placeholder.png')} alt="logo" className="agencyLogo" />
																	}
																</div>
															*/}
															</td>
															<td>
																<span className="barcodeContainer">
																	{
																		order_details.agency_id !== order_details.customer_slates_for_videos_id
																			?
																			order_details.customer_slates_for_videos.name
																			:
																			order_details.agency.name
																	}
																</span>
															</td>
															<td >
																{order_details.group_name}
															</td>
															<td>
																{
																	!is_ttk
																		?
																		TF.productionStatus(order_details.settings, order_details.status, csr_steps, order_details.onHoldLastComment, orderCancelComment, order_details.order_dates, shipping_details)
																		:
																		TF.productionStatusTTK(order_details.settings, order_details.status, csr_steps, order_details.onHoldLastComment, orderCancelComment, order_details.order_dates, shipping_details)

																}
																{
																	order_details.is_fto ?
																		<span className="fto"><strong>&nbsp; FTO</strong></span>
																		:
																		''
																}
															</td>



															{
																/*
																	<td>
																		{
																			'on_hold' in order_details.settings
																				&&
																				<Col md={4} className="mb-lg">
																				{
																					order_details.settings.on_hold.value === '1'
																					?
																						<button value={order_details.settings.on_hold.value} name='on_hold' onClick={this.updateOrderSettings} className='customBtn btn btn-primary btn-default'>ORDER ON HOLD</button>
																					:
																						  <button value={order_details.settings.on_hold.value} name='on_hold' onClick={this.updateOrderSettings} className='customBtn btn btn-primary btn-default'>Place on Hold</button>
																				}
																				</Col>
																		}
																	</td>
																*/
															}
															{
																/*
																	<td>
																		{
																			order_details.status.title === 'Approved'
																				?
																				<span>Approved</span>
																				:
																				<Col md={4} className="mb-lg">
																					<div className='text-left'>
																						<button onClick={() => this.updateOrderStatus(order_details.id, 'Approved')} className='customBtn btn btn-primary btn-default'>Approve</button>
																					</div>
																				</Col>
																		}
																	</td>
																*/
															}
														</tr>
													</tbody>
												</table>
											</Col>

										}


										<Col md={12}>
											<div id="tabs" className="orderTabs ">
												<Tabs selectedIndex={this.state.index} onSelect={index => this.setState({ index: index })} >
													<TabList className="list-unstyled details_tabs">

														{
															hasRole(['admin','gm admin', 'Remote Editor', 'Remote Editor/Producer', 'Remote Producer']) &&
															<Tab id="details" style={{ fontSize: 17 }}>
																{/* <MyImage src={s3StaticPath('img/details_tab_icon.png')} alt="detail icon" /> */}
																<span>Details</span>
															</Tab>
														}
														{
															hasRole(['admin','gm admin', 'editor', 'producer', 'qa', 'Remote Editor', 'Remote Editor/Producer', 'Remote Producer']) &&
															<Tab id="production" style={{ fontSize: 17 }}>
																<div className="speech-bubble-container">
																	<span className="speech-bubble">{order_details.originalImages ? order_details.originalImages : 0}</span>
																</div>
																{/* <MyImage src={s3StaticPath('img/images_tab_icon.png')} alt="icon" /> */}
																<span>Production</span>
															</Tab>
														}
														{
															hasRole(['admin','gm admin', 'Remote Editor', 'Remote Editor/Producer', 'Remote Producer']) &&
															<Tab id="appCards" style={{ fontSize: 17 }} className={hasRole(['Remote Editor', 'Remote Editor/Producer', 'Remote Producer']) ? "tabDisabled" : ''} disabled={hasRole(['Remote Editor', 'Remote Editor/Producer', 'Remote Producer']) ? true : false}>
																{/* <MyImage src={s3StaticPath('img/images_tab_icon.png')} alt="icon" /> */}
																<span>App Cards</span>
															</Tab>
														}
														{
															hasRole(['admin','gm admin', 'Remote Editor', 'Remote Editor/Producer', 'Remote Producer']) &&
															<Tab id="settings" style={{ fontSize: 17 }} className={hasRole(['Remote Editor', 'Remote Editor/Producer', 'Remote Producer']) ? "tabDisabled" : ''} disabled={hasRole(['Remote Editor', 'Remote Editor/Producer', 'Remote Producer']) ? true : false}>
																{/* <MyImage src={s3StaticPath('img/settings_tab_icon.png')} alt="setting icon" /> */}
																<span>Order Settings</span>
															</Tab>
														}
														{
															hasRole(['admin','gm admin', 'Remote Editor', 'Remote Editor/Producer', 'Remote Producer']) &&
															<Tab id="travelersHomeUsers" style={{ fontSize: 14 }} className={hasRole(['Remote Editor', 'Remote Editor/Producer', 'Remote Producer']) ? "tabDisabled" : ''} disabled={hasRole(['Remote Editor', 'Remote Editor/Producer', 'Remote Producer']) ? true : false}>
																<div className="speech-bubble-container">
																	<span className="speech-bubble">{this.props.order_details ? this.props.order_details.going_on_trip_count + '/' : "0/"}{this.props.order_details ? this.props.order_details.following_from_home_count : "0"}
																	</span>
																</div>
																{/* <MyImage src={s3StaticPath('img/travelers_tab_icon.png')} alt="traveler icon" /> */}
																<span>Travelers/Home Users</span>
															</Tab>
														}
														{
															hasRole(['admin','gm admin', 'Remote Editor', 'Remote Editor/Producer', 'Remote Producer']) &&
															<Tab id="documents" style={{ fontSize: 17 }} className={hasRole(['Remote Editor', 'Remote Editor/Producer', 'Remote Producer']) ? "tabDisabled" : ''} disabled={hasRole(['Remote Editor', 'Remote Editor/Producer', 'Remote Producer']) ? true : false}>
																<div className="speech-bubble-container">
																	<span className="speech-bubble">{order_details.order_resources_count ? order_details.order_resources_count : 0}</span>
																</div>
																{/* <MyImage src={s3StaticPath('img/documents_tab_icon.png')} alt="documents icon" /> */}
																<span>Documents</span>
															</Tab>
														}
														{
															hasRole(['admin','gm admin', 'Remote Editor', 'Remote Editor/Producer', 'Remote Producer']) &&
															<Tab id="reOrders" style={{ fontSize: 17 }} className={hasRole(['Remote Editor', 'Remote Editor/Producer', 'Remote Producer']) ? "tabDisabled" : ''} disabled={hasRole(['Remote Editor', 'Remote Editor/Producer', 'Remote Producer']) ? true : false}>
																{/* <div className="speech-bubble-container">
																	<span className="speech-bubble">{order_details.order_resources_count ? order_details.order_resources_count : 0}</span>
																</div> */}
																{/* <MyImage src={s3StaticPath('img/documents_tab_icon.png')} alt="documents icon" /> */}
																<span>ReOrders</span>
															</Tab>
														}
														{
															hasRole(['admin','gm admin', 'Remote Editor', 'Remote Editor/Producer', 'Remote Producer']) &&
															<Tab id="details" style={{ fontSize: 17 }} className={hasRole(['Remote Editor', 'Remote Editor/Producer', 'Remote Producer']) ? "tabDisabled" : ''} disabled={hasRole(['Remote Editor', 'Remote Editor/Producer', 'Remote Producer']) ? true : false}>
																{/* <i className="fa fa-delicious" style={{opacity:0.8}}></i> */}
																<span>MoreButtons</span>
															</Tab>
														}
														{
															this.state.order_details.agency && this.state.order_details.agency.accounts &&
															!!+(this.state.order_details.agency.accounts[0].has_itinerary) &&
															<Tab id="itinerary" style={{ fontSize: 17 }} className={hasRole(['Remote Editor', 'Remote Editor/Producer', 'Remote Producer']) ? "tabDisabled" : ''} disabled={hasRole(['Remote Editor', 'Remote Editor/Producer', 'Remote Producer']) ? true : false}>
																{/* <i className="fa fa-delicious" style={{opacity:0.8}}></i> */}
																<span>Itinerary</span>
															</Tab>
														}
													</TabList>
													{
														hasRole(['admin','gm admin', 'Remote Editor', 'Remote Editor/Producer', 'Remote Producer', 'qa']) &&
														<TabPanel >

															<LoadingOverlay
																active={loadingOrder}
																spinner={<BounceLoader />}
															>
																<OrderDetailShowAndModal
																	orderId={orderId}
																	pricing_details={pricing_details}
																	order_details={order_details}
																	programType={programType}
																	getOrderCall={this.props.getOrderCall}
																	shipping_details={shipping_details}
																	is_france={is_france}
																	initial_country_France={initial_country_France}
																	vsa_initial_country_France={vsa_initial_country_France}
																	ptma_initial_country_France={ptma_initial_country_France}
																	ptma_is_france={ptma_is_france}
																	vsa_is_france={vsa_is_france}
																	is_ttk={is_ttk}

																/>
																{
																	(!shipping_details.camera_ship_status || 'id' in shipping_details.camera_ship_status) &&
																	<ShippingDetailShowAndModal
																		orderId={orderId}
																		production_details={production_details}
																		pricing_details={pricing_details}
																		shipping_details={shipping_details}
																		order_details={order_details}
																		agency_details={order_details.agency}
																		getOrderCall={this.props.getOrderCall}
																		childState={this.getChildState}
																		is_ttk={is_ttk}
																	/>
																}
																<PricingDetailShowAndModal
																	orderId={orderId}
																	state={shipping_details.pre_trip_material_address.state && shipping_details.pre_trip_material_address.state.title}
																	pricing_details={pricing_details}
																	production_details={production_details}
																	collect_tax={order_details.agency.settings ? order_details.agency.settings[0] : null}
																	agency={order_details.agency}
																	order_details={order_details}
																	shipping_details={shipping_details}
																	programType={programType}
																	stateParams={stateParams}
																	is_ttk={is_ttk}
																/>
																<OrderComments
																	formType={'edit'}
																	contactLog={this.props.location.state}
																	modelId={orderId}
																	order_details={order_details}
																	withUuid={false}
																	addExceptTab={['All']}
																	showOnly={['All', 'Admin', 'PreTripMaterialsShip', 'QC', 'Production', 'DVD_MozaixShip', 'TO_GL', 'ContactLog']}
																	showSteps={true}
																	updateOrderSettingsCall={this.updateSettingsInOrderDetail}
																/>
															</LoadingOverlay>
														</TabPanel>
													}
													{
														hasRole(['admin','gm admin', 'editor', 'producer', 'qa', 'Remote Editor', 'Remote Editor/Producer', 'Remote Producer']) &&
														<TabPanel>
															<Images is_ttk={is_ttk} orderId={orderId} shipping_details={shipping_details} order_details={order_details} production_details={production_details} />
														</TabPanel>
													}
													{
														hasRole(['admin','gm admin']) &&
														<TabPanel>
															<AppCard is_ttk={is_ttk} orders={orderId} base_url={order_details.base_url} order_details={order_details} />
														</TabPanel>
													}
													{
														hasRole(['admin','gm admin']) &&
														<TabPanel>
															<OrderSettings orderId={orderId} is_ttk={is_ttk} />
														</TabPanel>
													}
													{
														hasRole(['admin','gm admin']) &&
														<TabPanel>
															<Travelers orderId={orderId} />
														</TabPanel>
													}
													{
														hasRole(['admin','gm admin']) &&
														<TabPanel>
															<OrderDocument orderId={orderId} agency_id={order_details.agency_id} />
														</TabPanel>
													}
													{
														hasRole(['admin','gm admin']) &&
														<TabPanel>
															{
																('allow_online_reorder' in order_details.settings && 'cleared_date' in order_details.order_dates) &&
																<div className='text-right'>
																	<button onClick={this.updateOrderSettings} value={Number(order_details.settings.allow_online_reorder.value)} name='allow_online_reorder' className='customBtn btn btn-primary'>{Number(order_details.settings.allow_online_reorder.value) === 1 ? 'UnAllow' : 'Allow'} Online Reorder</button>
																</div>
															}
															{
																!is_5_years_old_order || (is_5_years_old_order && order_details.allow_reorder)
																	?
																	<div className='text-right mt-md'>
																		<Link target="_blank" className='customBtn btn btn-primary text-white' to={`/Admin/${orderId}/gtv-reorder-entry?comp=true`}>Enter Complimentary Reorder</Link>
																		<Link target="_blank" className='customBtn btn btn-primary text-white ml-md' to={`/Admin/${orderId}/gtv-reorder-entry`}>Enter Reorder Paid by Check</Link>
																		<a className='customBtn btn btn-primary text-white ml-md'
																			//  to='/keepsake'
																			href={`microsoft-edge:${link}`}
																		//   onClick={(e) => {
																		//     e.preventDefault();
																		//     this.props.loginCall(this.state.order_details.photo_admin_username, this.state.order_details.photo_admin_password, '/keep_sake')
																		// }}
																		>Enter Reorder Paid by Credit</a>
																	</div>
																	:
																	<div className='text-right mt-md text-danger mr-xlg'>
																		This order is 5 years old, reorder has disabled.
																	</div>
															}
															{/* {
                                                                is_5_years_old_order &&
                                                                <div className='text-right mt-md'>
                                                                  This order is 5 years old.
                                                                </div>
                                                            } */}
															<ReorderList orderId={orderId} inTab />

														</TabPanel>
													}
													{
														hasRole(['admin','gm admin']) &&
														<TabPanel>
															<div className="row">
																<OrderMoreButtonsList
																	order_id={this.state.orderId}
																	columns={columns}
																	agency_id={this.state.order_details.agency_id}
																	order_details={this.state.order_details}
																/>
															</div>
														</TabPanel>
													}
													{
														hasRole(['admin','gm admin']) && this.state.order_details.agency && this.state.order_details.agency.accounts &&
														!!+(this.state.order_details.agency.accounts[0].has_itinerary) &&
														<TabPanel>
															<div className="row">
																<AddItinerary
																	order_id={this.state.orderId || ''}
																	brand_id={this.state.order_details.agency.accounts[0].id || ''}
																	in_order={true}
																	agency_id={this.state.order_details.agency.id || ''}
																/>
															</div>
														</TabPanel>
													}
												</Tabs>
												{
													hasRole(['Customer Services']) &&
													<Col md={12}>
														<div id="tabs" className="orderTabs">
															<OrderComments
																contactLog={this.props.location.state}
																modelId={orderId}
																order_details={order_details}
																withUuid={false}
																showOnly={['ContactLog']}
																addExceptTab={[]}
																showSteps={true}
																updateOrderSettingsCall={this.updateSettingsInOrderDetail}
															/>
														</div>
													</Col>
												}
											</div>
										</Col>
									</Grid>
								}
							/>
						</Col>
					</Row>
				</Grid>
			</div>
		);
	};
}

function mapStateToProps(state) {
	const { editAgency } = state.agenciesReducer;
	const { loadingOrder, order_details, shipping_details, pricing_details, production_details, csr_steps, is_ttk, allow_reorder_days_diff } = state.ordersReducer;
	const { loadingSettings, settings, updatedSetting, programType } = state.settingsReducer;
	return {
		loadingOrder, order_details, shipping_details, pricing_details, production_details, csr_steps,
		loadingSettings, settings, updatedSetting, programType, editAgency, is_ttk, allow_reorder_days_diff
	};
}
const mapDispatchToProps = dispatch => {
	return ({
		getAgencyCall: (id) => {
			dispatch(getAgency(id))
		},
		getSettingValueCall: (key, stateName) => { dispatch(getSettingValue(key, stateName)) },
		getOrderStatuses: () => { dispatch(getOrderStatuses()) },
		updateOrderSettingsCall: (orderId, value, key) => { return dispatch(updateOrderSettings(orderId, value, key)) },
		updateOrderSettingsCallNew: (orderId, value, key) => { return dispatch(updateSettingsInOrderDetail(orderId, value, key)) },
		updateOrderStatusByTitleCall: (orderId, status) => { return dispatch(updateOrderStatusByTitle(orderId, status)) },
		getProgramTypeCall: () => { dispatch(getProgramType()) },
		getLatestCommentByCategoryTitle: (title, model_id) => dispatch(getLatestCommentByCategoryTitle(title, model_id)),
		loginCall: (email, password, intended) => { dispatch(login(email, password, intended)) },
		getOrderCall: (orderId) => { dispatch(getOrder(orderId)) },
		getMoreButtonsPaginationCall: (page, filter, sort, pageSize, orderType, agency_id) => { return dispatch(getMoreButtonsPagination(page, filter, sort, pageSize, orderType, agency_id)) },
		getAllowReorderCall: (orderId) => { return dispatch(getAllowReorder(orderId)) },
	});
};

const OrderEdit = connect(mapStateToProps, mapDispatchToProps)(OrderEditPage);
export default OrderEdit;


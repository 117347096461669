import React from "react";
import GenerateAppCard from "components/Admin/Order/GenerateAppCard";
import PrintOrderLabel from "components/Admin/Order/PrintOrderLabel";
import GeneratePreAwarenessFlyer from "components/Admin/Order/GeneratePreAwarenessFlyer";
import { s3StaticPath } from 'helper/helperFunctions';

import MyImage from 'components/Upload/MyImage';


class AppCard extends React.Component {
    render() {
        const { order_details, is_ttk } = this.props;
        return (
            <React.Fragment>
                <div className='mt-xlg' >
                    <div className='secHeading' >
                        App Cards
                    </div>

                    <div className="appCardActions">
                        {
                             !is_ttk &&
                            <div className='' >
                                <a href={`${this.props.base_url}/generateUploaderGuide/${this.props.orders}`} className='appCardBtn' target="_blank" rel="noopener noreferrer">
                                    <MyImage src={s3StaticPath('img/appCard_1.png')} alt="" />
                                    <span>Generate Uploader Guide</span>
                                </a>
                            </div>
                        }
                        {
                             !is_ttk &&
                            <div className='' >
                                <a className='appCardBtn' target="_blank" href={`${this.props.base_url}/generateTitleSheets/${this.props.orders}`} rel="noopener noreferrer">
                                    <MyImage src={s3StaticPath('img/appCard_2.png')} alt="" />
                                    <span>(Re) Generate Title Sheet</span>
                                </a>
                            </div>
                        }
                        {
                             !is_ttk &&
                            <div className='' >
                                <PrintOrderLabel base_url={this.props.base_url} btnIcon={<MyImage src={s3StaticPath('img/appCard_3.png')} alt="" />} className='appCardBtn' order_page={true} orders={this.props.orders} text='(RE) Generate Barcode Label' />
                            </div>
                        }
                        {
                             !is_ttk &&
                            <div className='' >
                                <GeneratePreAwarenessFlyer base_url={this.props.base_url} btnIcon={<MyImage src={s3StaticPath('img/appCard_6.png')} alt="" />} className='appCardBtn' orders={this.props.orders} />
                            </div>
                        }
                        {/* <div>
                            <button className='' >View existing barcode labels</button>
                        </div> */}
                        {
                             !is_ttk &&
                            <div className='' >
                                <a
                                    rel="noopener noreferrer"
                                    className="appCardBtn"
                                    target="_blank"
                                    href={`${this.props.base_url}/generateAppCard1UpNoQR/${this.props.orders}`} >
                                    <MyImage src={s3StaticPath('img/appCard_4.png')} alt="" />
                                    <span>SINGLE-Front and Back</span>
                                </a>
                            </div>
                        }
                        {/* <div className='my-20' >
                            <GenerateAppCard text='Generate APP Travelers Card (ALL)' orders={this.props.orders} />
                        </div> */}
                        {
                            "title_sheet" in order_details.orderResources && !is_ttk &&
                            <div className='' >
                                <a href={`${order_details.orderResources.title_sheet.file_path}`} className='appCardBtn' target="_blank" rel="noopener noreferrer">
                                    <MyImage src={s3StaticPath('img/appCard_1.png')} alt="" />
                                    <span>Title Sheet</span>
                                </a>
                            </div>

                        }
                        {
                            // !is_ttk &&
                            <div className='' >
                                <a
                                    rel="noopener noreferrer"
                                    className="appCardBtn"
                                    target="_blank"
                                    href={`${this.props.base_url}/generateAppCardOnlyBackQR/${this.props.orders}`} >
                                    <MyImage src={s3StaticPath('img/appCard_4.png')} alt="" />
                                    <span>4 Up Login Side w/QR Codes</span>
                                </a>
                            </div>
                        }
                        {
                            // !is_ttk &&
                            <div className='' >
                                <a
                                    rel="noopener noreferrer"
                                    className="appCardBtn"
                                    target="_blank"
                                    href={`${this.props.base_url}/generateAppCardOnlyBackNoQR/${this.props.orders}`} >
                                    <MyImage src={s3StaticPath('img/appCard_4.png')} alt="" />
                                    <span>SINGLE - 4 Up Login Side NO QR Code</span>
                                </a>
                            </div>
                        }
                        <div className='' >
                            <a
                                rel="noopener noreferrer"
                                className="appCardBtn"
                                target="_blank"
                                href={`${this.props.base_url}/generateAppCard1Up/${this.props.orders}`} >
                                <MyImage src={s3StaticPath('img/appCard_4.png')} alt="" />
                                {
                                // !is_ttk &&
                                <span>SINGLE-1 Up Login Side w/QR Codes</span>
                                }
                                {/* {
                                is_ttk &&
                                <span>Generate APP Travelers Card</span>
                                } */}
                            </a>
                        </div>
                        {
                            is_ttk &&
                            <div className='' >
                                <a
                                    rel="noopener noreferrer"
                                    className="appCardBtn"
                                    target="_blank"
                                    href={`${this.props.base_url}/printAppInfoSummaryTTK/${this.props.orders}`} >
                                    <MyImage src={s3StaticPath('img/appCard_2.png')} alt="" />
                                    <span>Travel TripKit Info Summary</span>
                                </a>
                            </div>
                        }
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
export default AppCard;
